import { Component, OnInit } from '@angular/core';
import { OttonomyService } from '../services/ottonomy.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '../../app/services/alert.service';

@Component({
  selector: 'app-fleet-media',
  templateUrl: './fleet-media.component.html',
  styleUrls: ['./fleet-media.component.scss']
})
export class FleetMediaComponent implements OnInit {

  loading = false;
  constructor(public ottonomyservice: OttonomyService,private formBuilder: FormBuilder,private route: ActivatedRoute,private router: Router, private alertService: AlertService) { }

  
  selectedValue: any = [];
  RobotListData = [];
  fileToUpload:File;
  adminDetail: any;
  videoId: any = '';
  adminId: any = '';
  robotId: any = '';
  videoName: any = '';

  submitVideoId = new FormControl('',Validators.required);

  public mediaVideoUrl
  public mediaList

  ngOnInit(): void {
    this.adminDetail = JSON.parse(sessionStorage.getItem('user'));
    this.getVideoListByAdminId();
    this.getRobotList();
    this.addVideoForm = this.formBuilder.group({
      videoid: ['', Validators.required],
      adminid: ['', Validators.required],
      robotid: ['', Validators.required]
    })
  }

  modalShow;
  openModal(value) {
    this.modalShow = value;
  }
  closeModal() {
    this.modalShow = '';
  }

  openUploadVideoModal(value, ) {
    this.modalShow = value;
  }

  handleFileInput(files) {
    if (files.target.files.length === 0) return ;

    this.fileToUpload = files.target.files[0];
  }

  /* post Upload Video */
  postUploadVideo() {
    this.loading = true;
    if(this.submitVideoId.invalid || !this.fileToUpload)return;
    const formData: FormData = new FormData();
    formData.append('videoName', this.fileToUpload, this.fileToUpload.name);
    formData.append('adminId', this.adminDetail.adminId);
    formData.append('videoId', this.submitVideoId.value);
    formData.append('fleetId', this.route.snapshot.params.id);

    var body = {
      "adminId" : this.adminDetail.adminId,
      "videoId" : this.adminDetail.videoId
    }
    this.ottonomyservice.uploadVideo(formData).then(s => {
      this.loading = false;
      this.closeModal();
      this.submitVideoId.reset();
      this.fileToUpload = null;
      this.submitted = false;
      this.getVideoListByAdminId();
    })
  }

  getVideoListByAdminId() {
    let body = {
      "fleetId" : this.route.snapshot.params.id
    }
    this.ottonomyservice.videoListByfleetIdId(body).then(s => {
      this.mediaList = s['data']
    })
  }

  openPlayModal(value, robotId,videoId,videoName) {
    this.mediaVideoUrl  = null;
    this.robotId = robotId;
    this.videoId = videoId;
    this.videoName = videoName;
    this.modalShow = value;
    var body = {
      robotId: robotId,
      videoId: videoId,
      videoName: videoName,
    };

    this.ottonomyservice.videoURLByVideoName(body).then(s => {
      if(s['videoListByRobotId'] != null) {
        this.mediaVideoUrl = s['videoListByRobotId'].URL
        //// console.log('URL :', this.mediaVideoUrl);
      }
    })
  }

  openDeleteModal(value, id, adminId) {
    this.modalShow = value;
    this.videoId = id;
    this.adminId = adminId;
  }

  // Delete User
  deleteMedia() {
    var body = {
      videoId: this.videoId,
      adminId: this.adminId,
    };
    this.ottonomyservice.DeleteMedia(body).then(s => {
      if(s['status'] == 200) {
        this.closeModal()
        this.getVideoListByAdminId();
      }
      this.alertService.error(s['message']);
    })
  }

  //Get Robot List Data
  getRobotList() {
    let body = {
      "fleetId" : this.route.snapshot.params.id
    }
    this.ottonomyservice.robotListByFleetId(body).then(s => {
      this.RobotListData = s['data']
    })
  }

  /* Assign Video To Robot */
  addVideoForm: FormGroup;
  submitted = false;
  get f() { return this.addVideoForm.controls }

  assignVideoId:any = '';
  openAssignVideoModel(value,videoId, video) {
    this.modalShow = value;
    this.assignVideoId = videoId;

    this.addVideoForm.patchValue({
      adminid:this.adminDetail.adminId,
      videoid:video.videoId,
      robotid:video.robotId
    })
  }

  assignVideo() {
    this.submitted = true;
    if(this.addVideoForm.invalid) {
      return;
    }
    var body = {
      adminId : this.adminDetail.adminId,
      videoId: this.assignVideoId,
      robotId: this.addVideoForm.get('robotid').value,
      // adminId: this.addVideoForm.get('adminid').value,
    }
    this.ottonomyservice.assignVideoToRobot(body).then(s => {
      if(s['status'] == 200) {
        this.submitted = false;
        this.addVideoForm.reset();
        this.closeModal();
        this.getVideoListByAdminId();
      }
      this.alertService.error(s['message']);
    })
  }
}

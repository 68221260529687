import { Component, OnInit, ViewChild, Input, OnDestroy} from '@angular/core';
import * as L from 'leaflet';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, BaseChartDirective, Label } from 'ng2-charts';
import { OttonomyService } from '../services/ottonomy.service';
import { cpuUsage } from 'process';
import { interval } from 'rxjs';
import { DatepickerOptions } from 'ng2-datepicker';
import { getYear } from 'date-fns';
import locale from 'date-fns/locale/en-US';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-graph',
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.scss']
})
export class GraphComponent implements OnInit, OnDestroy {

  constructor(public ottonomyservice: OttonomyService) { 
  }
  ngOnInit(): void {   

    setTimeout(() =>{
      this.getAvailList();
      this.interval = setInterval(()=>{

        this.orderCountPerHour();
      },2000)
    },1000)    
  } 

  ngOnDestroy(){
    clearInterval(this.interval);
  }

  interval:any;
  date = new FormControl(new Date());
  

  options: DatepickerOptions = {
    minYear: getYear(new Date()) - 30, // minimum available and selectable year
    maxYear: getYear(new Date()) + 30, // maximum available and selectable year
    placeholder: '', // placeholder in case date model is null | undefined, example: 'Please pick a date'
    format: 'yyyy-MM-dd', // date format to display in input
    formatTitle: 'LLLL yyyy',
    formatDays: 'EEEEE',
    firstCalendarDay: 0, // 0 - Sunday, 1 - Monday
    locale: locale, // date-fns locale
    position: 'top',
    inputClass: 'test', // custom input CSS class to be applied
    calendarClass: 'datepicker-default', // custom datepicker calendar CSS class to be applied
    scrollBarColor: '#dfe3e9', // in case you customize you theme, here you define scroll bar color
    // keyboardEvents: true // enable keyboard events
  };

  // Line Chart
  public lineChartData: ChartDataSets[] = [
    { data: [2.5, 1.75, 3.5, 4.5, 3.5, 6.3, 3], label: 'Series A' }
  ];
  public lineChartLabels: Label[] = ['SUN-10', 'MON-20', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
  public lineChartOptions: (ChartOptions) = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [{
        ticks: {   
          fontColor: "#fff",
          fontSize: 10,
          stepSize: 20,
          fontStyle: "bold",
          beginAtZero: true
        },
        gridLines: {
          zeroLineColor: '#2D375C',
          color: "rgba(231, 231, 231, 0.1)"
        },
      }],
      xAxes: [{
        ticks: {
          fontColor: "#fff",
          fontSize: 10,
          stepSize: 1,
          fontStyle: "bold",
          beginAtZero: true
        },
        gridLines: {
          display: false,
          color: "rgba(231, 231, 231, 0.1)"
        },
      }]
    }
  };
  public lineChartColors: Color[] = [
    {
      backgroundColor: 'rgba(148,159,177,0)',
      borderColor: '#31CEFF',
      pointBackgroundColor: '#31CEFF',
      pointBorderColor: '#fff',
      borderWidth: 2,
      pointRadius: 5,
      pointBorderWidth: 2,
      pointHoverRadius: 5,
      pointHoverBorderWidth: 2,
    }
  ];
  public lineChartLegend = false;
  public lineChartType: ChartType = 'line';

  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

  // events
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    //// console.log(event, active);
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    //// console.log(event, active);
  }

  public orderCountData

  @Input() fleetIdFromNavbar: any;

  ngOnChanges(fleetId) {
    this.orderCountPerHour();
    this.getAvailList();
  }

  orderCountPerHour() {
    if (this.fleetIdFromNavbar != undefined) {
      let newdd = this.date.value.getFullYear()+'-'+(this.date.value.getMonth() + 1)+'-'+this.date.value.getDate();
      var body = {
        "fleetId": this.fleetIdFromNavbar,
        "date" : newdd
      }
      this.ottonomyservice.orderCountPerHourData(body).then(s => {
        if (s['status'] == 200) {
          this.orderCountData = s['orderCountPerHourDate'];
          this.barChartData[0].data = this.orderCountData
          //// console.log(this.barChartData[0].data)
        }
      })
    }
  }

  /* Bar Chart */
  public barChartOptions: ChartOptions = {
    maintainAspectRatio: false,
    responsive: true,

    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      yAxes: [{
        ticks: {
          fontColor: "#fff",
          fontSize: 10,
          stepSize: 10,
          fontStyle: "bold",
          beginAtZero: true,
        },
        gridLines: {
          zeroLineColor: '#2D375C',
          color: "rgba(231, 231, 231, 0.1)",
        },
      }],
      xAxes: [{
        ticks: {
          fontColor: "#fff",
          fontSize: 10,
          stepSize: 10,
          fontStyle: "bold",
          beginAtZero: true,
        },
        gridLines: {
          display: false,
          color: "rgba(231, 231, 231, 0.1)"
        },
      }]
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };

  public barChartColors: Color[] = [
    {
      backgroundColor: '#31CEFF',
      borderColor: '#31CEFF',
      borderWidth: 2,
    }
  ];

  public barChartLabels: Label[] = ['1:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00', '24:00'];
  public barChartType: ChartType = 'bar';
  public barChartLegend = false;

  public barChartData: ChartDataSets[] = [
    { data: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1], maxBarThickness: 4, }
  ];

  //API for getting availablilty
  getAvailList() {
    if (this.fleetIdFromNavbar != undefined) {
      // console.log(this.fleetIdFromNavbar);
      var body = {
        "fleetId": this.fleetIdFromNavbar,
      }
      this.ottonomyservice.getFleetAvailabilityOverTime(body).then(s => {
        if (s['status'] == 200) {
          var RobotStatusData = s['data']['COUNT'];
          var number = s['data']['noOfRobots'];

          for(var i=0; i<7; i++){
            this.lineChartLabels[i] = Object.keys(RobotStatusData)[i];
            var temp = (RobotStatusData[this.lineChartLabels[i]] / number) * 100;
            this.lineChartData[0].data.unshift(temp);
          }

        }
      })
    }
  }
}

import { Component, OnInit,Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OttonomyService } from '../services/ottonomy.service';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  @Output() passFleetId = new EventEmitter<any>();
  @Output() passRobotId = new EventEmitter<any>();
  show = true;
  constructor(private router: Router,private route: ActivatedRoute, private ottonomyservice:OttonomyService) {
    this.routerUrl = router.url;
  }
  routerUrl: string;
  drop = false;
  userName = "";
  imageUrl: any;
  userRole = "";
  adminRole = "";
  roleValue = true;
  fleetList:any = [];
  robotList:any = [];
  robotIdList:any = [];
  userDetail:any;
  adminDetail:any;
  fleetIdValue:any;
  robotIdValue:any;
  robotDetailPage = false;
  selectedRobot:any;
  selectedFleet:any;
  messageFromRobot:string;

  ngOnInit(): void {
    this.userDetail = JSON.parse(sessionStorage.getItem('user'));
    this.userRole = this.userDetail.role[0];
    this.userName  = this.userDetail['display_name'];
    this.imageUrl  = this.userDetail['imageUrl'];
    
    if(this.userRole == "user") {
      this.roleValue = false;
    }
    if(this.userRole == "admin" || this.userRole == "onsite-admin") {
      this.roleValue = true;
    }
    this.getFleetList();
    if (this.router.url.indexOf('/robot-detail') > -1) {
      this.robotDetailPage = true;
      // this.getRobotStatus();
    }
    this.fleetIdValue = sessionStorage.getItem('fleetId')
    setTimeout(()=>{
      this.selectedFleet = sessionStorage.getItem('fleetId');
    },1000)

    setInterval(()=>{
      this.messageFromRobot = this.ottonomyservice.receiveMessage();
    },1000);

    this.selectedRobot = this.route.snapshot.params.id;
  }
  navigate(value){
    this.router.navigateByUrl(value);
  }
  logout() {
    sessionStorage.clear();
    this.router.navigateByUrl('/login');
  }

  // Fleet List
  getFleetList() {
    let body = {
      "emailId" : this.userDetail.emailId
    }
    if(this.userRole == "user" || this.userRole=="onsite-admin" ) {
      this.ottonomyservice.getFleetListEmail(body).then(s => {
        this.fleetList = s['fleet'];
        if(this.fleetList.length > 0) {
          if(sessionStorage.getItem('fleetId') == null) {
            this.fleetIdValue = this.fleetList[0].fleetId;
            sessionStorage.setItem('fleetId', this.fleetIdValue);
          }
          this.passFleetId.emit(this.fleetIdValue);
          this.getRobotList();
        }
      })
    }
    if(this.userRole == "admin"){
      this.ottonomyservice.getFleetListAdminEmail(body).then(s => {
        this.fleetList = s['fleet'];
        if(this.fleetList.length > 0){
          if(sessionStorage.getItem('fleetId') == null){
            this.fleetIdValue = this.fleetList[0].fleetId;
            sessionStorage.setItem('fleetId', this.fleetIdValue);
          }
          this.passFleetId.emit(this.fleetIdValue);
          this.getRobotList();
        }
      })
    }

  }

  selectFleet(event){
    this.fleetIdValue = event.target.value;
    sessionStorage.setItem('fleetId', this.fleetIdValue);
    this.passFleetId.emit(this.fleetIdValue);
  }

  // Robot List
  getRobotList(){
    let body = {
      "fleetId" : sessionStorage.getItem('fleetId')
    }
    this.ottonomyservice.getRobotsListByFleetId(body).then(s => {
      this.robotList = s['data'];
      this.robotList.map((value) => {
        this.robotIdList.push(value.robotId);
      })
    })
  }

  selectRobot(event){
    this.robotIdValue = event.target.value;
    this.passRobotId.emit(this.robotIdValue);
  }

  //Stop Fleet
  fleetStop = false;
  stopFleet(){
    let body = {
      "fleetId" : this.fleetIdValue
    }

    this.ottonomyservice.stopFleetStatus(body).then(s => {
      if(s['status'] == 200){
        this.fleetStop = true;
      }
    })
  }

  //Release fleet
  releaseFleet(){
    let body = {
      "fleetId" : this.fleetIdValue
    }

    this.ottonomyservice.releaseFleetStatus(body).then(s => {
      if(s['status'] == 200){
        this.fleetStop = false;
      }
    })
  }
}

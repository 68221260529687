<app-navbar (passFleetId)="fleetIdValue($event)"></app-navbar>
<ul class="status_container">
    <li>
        <p>active Robots <span>{{availableRobotLength}}</span></p>
        <h5>
            <!-- <span>{{availableRobotLength}}</span> -->
            <!-- <span class="green">
                <svg viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 6.53846L5.53846 1l5.53844 5.53846M5.57929 1.76923V13" stroke="#3DF78F" stroke-width="1.84615" stroke-linecap="round" stroke-linejoin="round"/></svg>
                10% from yesterday
            </span> -->
        </h5>
    </li>
    <li>
        <p>robots charging <span>{{chargingRobotLength}}</span></p>
        <!-- <h5>{{chargingRobotLength}}</h5> -->
    </li>
    <li>
        <p>orders completed today <span>{{todayOrdersValue}}</span></p>
        <h5>
            <!-- <span>{{todayOrdersValue}}</span> -->
            <!-- <span class="red">
                <svg viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 6.53846L5.53846 1l5.53844 5.53846M5.57929 1.76923V13" stroke="#FD4636" stroke-width="1.84615" stroke-linecap="round" stroke-linejoin="round"/></svg>
                5% from yesterday
            </span> -->
        </h5>
    </li>
    <li>
        <p>Total orders completed <span>{{totalOrdersValue}}</span></p>
        <!-- <h5>{{totalOrdersValue}}</h5> -->
    </li>
    <li>
        <p>Total distance covered (miles) <span>{{totalDistanceTravelled.toFixed(2)}}</span></p>
        <!-- <h5><span>{{totalDistanceTravelled.toFixed(2)}}</span></h5> -->
    </li>
</ul>
<div class="main_container">
    <!--Fleet Box-->
    <div class="left_container">
        <app-fleet [fleetIdFromNavbar]="fleetIdFromNavbar"></app-fleet>
        <app-order-detail [fleetIdFromNavbar]="fleetIdFromNavbar"></app-order-detail>
    </div>
    <div class="right_container">
        <app-map [fleetIdFromNavbar]="fleetIdFromNavbar"></app-map>
        <app-graph [fleetIdFromNavbar]="fleetIdFromNavbar"></app-graph>
    </div>
</div>

<app-navbar (passRobotId)="refreshVideo($event)"></app-navbar>
<ul class="status_container robot_status">
  <li>
    <div class="text_box">
      <h6
        [class.red]="malfunction"
        [class.grey]="testingStatus == 'true'"
        [class.orange]="obstacleFlag"
      >
        {{ RobotId }}
      </h6>
      <p class="active">{{ RobotStatus }}</p>
    </div>
    <div class="status_box">
      <svg
        class="box_status"
        viewBox="0 0 28 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M25.6559 6.73924L13.8996 13.6565 2.14061 6.7392l9.85829-5.72867c.0001-.00006.0002-.00012.0003-.00019 1.1739-.680269 2.6221-.680292 3.796-.00006l9.8607 5.72896zm0 0l-9.8603-5.72871 9.8603 5.72871zM14.0175 13.7258l-.0002-.0001c.0001.0001.0003.0002.0004.0003l-.0002-.0002z"
          fill="#575757"
          stroke="#656873"
        />
        <path
          [class.active]="Box1Status != 'EMPTY' && Box1Status != 'NULL'"
          d="M.500006 9.42351L12.3285 16.3233V29.258L1.85 22.9752l.00002-.0001-.00548-.0032C1.01368 22.488.501895 21.5996.500006 20.6381V9.42351z"
          fill="#00FF00"
          stroke="#EB9FAC"
        />
        <path
          [class.active]="Box2Status != 'EMPTY' && Box2Status != 'NULL'"
          d="M25.95 22.9686l-.0001-.0001-.0055.0033-10.4778 6.2829V16.3703l11.8285-6.94283V20.6377c-.0031.9605-.515 1.8476-1.3451 2.3309zm-10.597-6.5317c-.0009.0006-.0019.0011-.0028.0017h0l.0028-.0017z"
          fill="#00FF00"
          stroke="#EB9FAC"
        />
      </svg>
      <div class="battery_box">
        <svg viewBox="0 0 21 11" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect
            x="1"
            width="16.9689"
            height="9.5026"
            rx="1.9387"
            stroke="#5DFBA2"
            stroke-width="1.35"
            stroke-linecap="square"
          />
          <rect
            x="3.27771"
            y="2.33026"
            [attr.width]="0.12 * RobotBattery"
            height="4.84208"
            rx=".169689"
            fill="#5DFBA2"
            stroke="#5DFBA2"
            stroke-width="1.35751"
            stroke-linecap="square"
          />
          <path
            d="M20.034 3.16766v3.16725"
            stroke="#5DFBA2"
            stroke-width="1.35751"
            stroke-linecap="round"
          />
        </svg>
        <p>{{ RobotBattery | number }}%</p>
      </div>

      <svg
        class="cellular"
        viewBox="0 0 30 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          [class.hide]="ActiveSimRssi < 75"
          d="M28.5 22H25.5C24.6716 22 24 21.3284 24 20.5V1.5C24 0.671573 24.6716 0 25.5 0H28.5C29.3284 0 30 0.671573 30 1.5V20.5C30 21.3284 29.3284 22 28.5 22Z"
          fill="white"
        />
        <path
          [class.hide]="ActiveSimRssi < 50"
          d="M20.5 22H17.5C16.6716 22 16 21.3284 16 20.5V6.5C16 5.67157 16.6716 5 17.5 5H20.5C21.3284 5 22 5.67157 22 6.5V20.5C22 21.3284 21.3284 22 20.5 22Z"
          fill="white"
        />
        <path
          [class.hide]="ActiveSimRssi < 25"
          d="M12.5 22H9.5C8.67157 22 8 21.3284 8 20.5V10.5C8 9.67157 8.67157 9 9.5 9H12.5C13.3284 9 14 9.67157 14 10.5V20.5C14 21.3284 13.3284 22 12.5 22Z"
          fill="white"
        />
        <path
          [class.hide]="ActiveSimRssi == 0"
          d="M4.5 22H1.5C0.671573 22 0 21.3284 0 20.5V14.5C0 13.6716 0.671573 13 1.5 13H4.5C5.32843 13 6 13.6716 6 14.5V20.5C6 21.3284 5.32843 22 4.5 22Z"
          fill="white"
        />
      </svg>
      <div class="play_pause_button">
        <img
          src="../../assets/images/play-circle.svg"
          alt="Play Icon"
          class="pause"
          [class.active]="robotRunningState == 'UI_PAUSE'"
          (click)="robotPlay(RobotId)"
        />
        <img
          src="../../assets/images/pause.svg"
          alt="Pause Icon"
          class="play"
          [class.active]="robotRunningState != 'UI_PAUSE'"
          (click)="robotPause(RobotId)"
        />
      </div>
    </div>
  </li>
  <li>
    <p>Robot Controls</p>
    <div class="button_box">
      <button
        [class.open_door]="
          door1Status == 'OPEN Door 1' || door1Status == 'OPENING 1'
        "
        (click)="changeDoorStatus(1, RobotId, door1Status)"
        [class.close_door]="
          door1Status == 'CLOSE Door 1' || door1Status == 'CLOSING 1'
        "
        [class.error_door]="
          door1Status == 'CLEAR ERROR 1' || door1Status == 'RESETTING 1'
        "
        [disabled]="
          ((door1Status == 'OPENING 1' ||
            door1Status == 'CLOSING 1' ||
            door1Status == 'RESETTING 1' ||
            this.robotRunningState != 'PAYLOAD_STATE') &&
            door1Status != 'CLEAR ERROR 1') ||
          !roleValue
        "
        [class.grey_but]="!roleValue"
      >
        {{ door1Status }}
      </button>

      <button
        [class.open_door]="
          door2Status == 'OPEN Door 2' || door2Status == 'OPENING 2'
        "
        (click)="changeDoorStatus(2, RobotId, door2Status)"
        [class.close_door]="
          door2Status == 'CLOSE Door 2' || door2Status == 'CLOSING 2'
        "
        [class.error_door]="
          door2Status == 'CLEAR ERROR 2' || door2Status == 'RESETTING 2'
        "
        [disabled]="
          ((door2Status == 'OPENING 2' ||
            door2Status == 'CLOSING 2' ||
            door2Status == 'RESETTING 2' ||
            this.robotRunningState != 'PAYLOAD_STATE') &&
            door2Status != 'CLEAR ERROR 2') ||
          !roleValue
        "
        [class.grey_but]="!roleValue"
      >
        {{ door2Status }}
      </button>

      <!-- <button class="open_door" (click)="openDoor(1,RobotStatusData.robotStatus.robotId)" *ngIf="door1">Open Door 1</button>
            <button class="close_door" (click)="closeDoor(1,RobotStatusData.robotStatus.robotId)" *ngIf="!door1">Close Door 1</button>
            <button class="open_door" (click)="openDoor(2,RobotStatusData.robotStatus.robotId)" *ngIf="door2">Open Door 2</button>
            <button class="close_door" (click)="closeDoor(2,RobotStatusData.robotStatus.robotId)" *ngIf="!door2">Close Door 2</button> -->
    </div>
  </li>
  <li>
    <app-robot-malfunction-box *ngIf="malfunction"></app-robot-malfunction-box>
    <p>
      Current Order id<span>{{ currentRobotStatus }}</span>
    </p>
    <p>Speed(metre/sec) : &nbsp;{{ robotCurrentSpeed.toFixed(2) }}</p>
    <!-- <h5><span>{{currentRobotStatus}}</span></h5> -->
  </li>
  <li>
    <table>
      <tr>
        <td><p class="switch-text">Testing Mode:</p></td>
        <td>
          <label class="switch">
            <input
              type="checkbox"
              [attr.checked]="testingStatus == 'true' ? 'ACTIVE' : null"
              (click)="changeTestingStatus($event)"
            />
            <span class="slider round"></span>
          </label>
        </td>
      </tr>
      <tr>
        <td><p class="switch-text">Wander Mode:</p></td>
        <td>
          <label class="switch">
            <input
              type="checkbox"
              [attr.checked]="wanderStatus == 'true' ? 'ACTIVE' : null"
              (click)="changeWanderStatus($event)"
            />
            <span class="slider round"></span>
          </label>
        </td>
      </tr>
    </table>
    <!-- <h5><span>{{distanceCoveredTodayStatus}}</span></h5> -->
  </li>
  <li>
    <p *ngIf="RobotStatus != 'ERROR'">
      Total distance covered(miles)<span>{{
        distanceCoveredTodayStatus.toFixed(2)
      }}</span>
    </p>
    <p *ngIf="RobotStatus == 'ERROR'">
      Error<span style="font-size: 14px !important"
        >{{ errorMsg.substring(0, 42) }} ...</span
      >
    </p>
  </li>
  <!-- <li>    
        <p> TEST: {{startGamepadData}}</p>
    </li> -->
</ul>

<div class="main_container">
  <div class="left_container">
    <!-- Robot Map  -->
    <app-robot-map-box></app-robot-map-box>
    <!-- When it is in full view mode -- CPU Usage  -->
    <!-- <app-robot-chart-box *ngIf="fullView && errorOccured"></app-robot-chart-box> -->
    <!-- When there is no error -->
    <app-robot-chart-box
      *ngIf="
        fullView &&
        robotRunningState != 'TELE_OP_MOVING' &&
        robotRunningState != 'TELE_OP_OBSTACLE' &&
        robotRunningState != 'TELE_OP'
      "
    >
    </app-robot-chart-box>
    <!-- When Manual Mode is on  -->
    <app-robot-action-box
      *ngIf="
        fullView &&
        (robotRunningState == 'TELE_OP_MOVING' ||
          robotRunningState == 'TELE_OP_OBSTACLE' ||
          robotRunningState == 'TELE_OP')
      "
    >
    </app-robot-action-box>
    <div class="small_cam_view" *ngIf="!fullView">
      <h4 class="title">Left Camera</h4>
      <video class="remote-view2" autoplay playsinline controls></video>
    </div>
  </div>

  <div class="right_container" [class.small_view]="!fullView">
    <div class="big_cam_view">
      <div class="video_box">
        <video class="remote-view" autoplay playsinline controls></video>
        <!-- <div class="control_box">
                    <ngx-joystick></ngx-joystick>
                </div> -->
      </div>
      <div class="cam_buttons">
        <div class="left">
          <div class="buttons">
            <button (click)="changeView()">
              <img
                src="../../assets/images/full_screen.svg"
                alt=""
                *ngIf="!fullView"
              />
              <img
                src="../../assets/images/small_screen.svg"
                alt=""
                *ngIf="fullView"
              />
            </button>
            <!-- <button [class.active]="videoQuality == 'SD'" (click)="changeQuality('SD')" style="padding: 5px 20px;
                        width: auto;">SD</button>
                        <button [class.active]="videoQuality == 'HD'" (click)="changeQuality('HD')">HD</button> -->
          </div>
          <!-- <select [disabled]="viewerStatus == 'START'" class="form-control" (change)="selectCamera($event)" -->
          <select
            class="form-control"
            (change)="selectCamera($event)"
            [(ngModel)]="cameraType"
          >
            <option value="front">Front Camera</option>
            <option value="back">Back Camera</option>
          </select>
        </div>
        <div class="LS_RS" *ngIf="startGamepadData">
          <p>LS : &nbsp;{{ LS }} &nbsp;&nbsp;RS : &nbsp;{{ RS }}</p>
        </div>
        <div class="right" *ngIf="roleValue">
          <button (click)="confirmBox('CLEARCOMMANDS')">CLEAR COMMANDS</button>
          <button (click)="confirmBox('HOME')">SEND HOME</button>
          <button (click)="changeStreamStatus()">
            {{ viewerStatus }} VIEWER
          </button>
          <button (click)="resetDiagnostics()">RESET DIAGNOSTICS</button>
          <button (click)="confirmBox('MOTOR')">{{ motorStatus }}</button>
          <button (click)="confirmBox('SSH')">{{ sshStatus }} SSH</button>
          <button
            [class.active]="manualMode"
            (click)="openManualModal('AUTO')"
            [disabled]="
              !robotStatusTeleGroup.includes(robotRunningState) &&
              !robotStatusAutoGroup.includes(robotRunningState) &&
              !robotStatusDuoGroup.includes(robotRunningState)
            "
          >
            {{ robotRunningState }}
          </button>
          <button
            class="{{ startGamepadData ? 'btn_gamepad_active' : 'true' }}"
          >
            <img src="../../assets/images/joystick.png" alt="" />
          </button>
        </div>
      </div>
    </div>
    <div class="right_side_boxes">
      <!-- When Manual Mode is on  -->
      <app-robot-action-box
        *ngIf="
          robotRunningState == 'TELE_OP_MOVING' ||
          robotRunningState == 'TELE_OP_OBSTACLE' ||
          robotRunningState == 'TELE_OP'
        "
      >
      </app-robot-action-box>
      <app-robot-chart-box
        *ngIf="
          robotRunningState != 'TELE_OP_MOVING' &&
          robotRunningState != 'TELE_OP_OBSTACLE' &&
          robotRunningState != 'TELE_OP'
        "
      >
      </app-robot-chart-box>
      <div class="small_cam_view">
        <h4 class="title">Right Camera</h4>
        <video class="remote-view3" autoplay playsinline controls></video>
      </div>
    </div>
  </div>
</div>

<!-- Manual Mode show -->
<div
  class="custom_modal_container confirmation_modal"
  [class.show]="modalShow == 'AUTO'"
>
  <div class="backdrop" (click)="closeModal()"></div>
  <div class="custom_modal_box">
    <p>SWITCH TO</p>
    <div class="btn_container">
      <button
        class="add"
        (click)="
          robotModeChange(
            robotStatusTeleGroup.includes(robotRunningState)
              ? 'TELE_OP_MOVING'
              : 'AUTO_RUN_MOVING'
          )
        "
      >
        {{
          robotStatusTeleGroup.includes(robotRunningState)
            ? "TELE_OP_MOVING"
            : "AUTO_RUN_MOVING"
        }}
      </button>
      <button
        class="add"
        *ngIf="robotStatusDuoGroup.includes(robotRunningState)"
        (click)="robotModeChange('TELE_OP_MOVING')"
      >
        TELE_OP_MOVING
      </button>
      <button
        class="add"
        *ngIf="robotStatusPayLoadGroup.includes(robotRunningState)"
        (click)="robotModeChange('PAYLOAD_STATE')"
      >
        PAYLOAD_STATE
      </button>
      <button
        *ngIf="
          !robotStatusDuoGroup.includes(robotRunningState) &&
          !robotStatusPayLoadGroup.includes(robotRunningState)
        "
        class="cancel"
        (click)="closeModal()"
      >
        Cancel
      </button>
    </div>
    <div class="btn_container">
      <button
        *ngIf="
          robotStatusDuoGroup.includes(robotRunningState) ||
          robotStatusPayLoadGroup.includes(robotRunningState)
        "
        class="cancel"
        (click)="closeModal()"
      >
        Cancel
      </button>
    </div>
  </div>
</div>

<div
  class="custom_modal_container confirmation_modal"
  [class.show]="modalShow == 'CONFIRM'"
>
  <div class="backdrop" (click)="closeModal()"></div>
  <div class="custom_modal_box">
    <p>Do you want to {{ actionName }}?</p>
    <div class="btn_container">
      <button class="cancel" (click)="closeModal()">No</button>
      <button class="add" (click)="confirmFunc()">Yes</button>
    </div>
  </div>
</div>

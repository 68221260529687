import { OttonomyService } from './../services/ottonomy.service';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Console } from 'console';

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss']
})
export class OrderDetailComponent implements OnInit, OnDestroy {
  @Input() fleetIdFromNavbar: any[];
  public orderStatusData: any;
  constructor(public ottonomyservice: OttonomyService) { }

  interval:any;
  ngOnInit(): void {
    this.interval = setInterval(() => {

      this.getOrderStatus();
    }, 2000)
  }

  ngOnDestroy(){
    clearInterval(this.interval);
  }  

  getOrderStatus() {
    if (this.fleetIdFromNavbar != undefined) {
      var body = {
        fleetId: this.fleetIdFromNavbar,
        "pageNo": 1,
        "size": "10",
      }
      this.ottonomyservice.getOrdersListByFleetIdPagination(body).then(s => {
        // console.log(s);
        this.orderStatusData = s['data']['order'];
        //console.log(this.orderStatusData);
      })

    }
  }

}

import { Component, OnInit } from '@angular/core';
import { OttonomyService } from '../services/ottonomy.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-admin-fleet-robot',
  templateUrl: './admin-fleet-robot.component.html',
  styleUrls: ['./admin-fleet-robot.component.scss']
})
export class AdminFleetRobotComponent implements OnInit {

  constructor(public ottonomyservice: OttonomyService,private formBuilder: FormBuilder,private route: ActivatedRoute,private router: Router) {
    router.events.subscribe(val => {
      this.getRobotStatus();
    });
  }

  userDetail;
  userRole:any;
  roleValue;

  ngOnInit(): void {
    this.getRobotStatus();
    this.addRobotForm = this.formBuilder.group({
      id: ['', Validators.required],
      endpoint: ['', Validators.required],
    })
  }
  modalShow;
  openModal(value) {
    this.modalShow = value;
  }

  closeModal(){
    this.modalShow = '';
  }

  //Get Robots List
  public RobotsList
  getRobotStatus(){
    var body = {
      "fleetId" : this.route.snapshot.params.id
    }
    this.ottonomyservice.getRobotsListByFleetId(body).then(s => {
      this.RobotsList = s['data']
    })
  }

  //Add Robot
  addRobotForm: FormGroup;
  submitted = false;
  get f() { return this.addRobotForm.controls }

  AddRobot(){
    this.submitted = true;
    if(this.addRobotForm.invalid){
      return;
    }

    var body = {
      robotId: this.addRobotForm.get('id').value,
      robotEndpoint: this.addRobotForm.get('endpoint').value,
    }

    this.ottonomyservice.AddRobot(body).then(s => {
      if(s['status'] == 200){
        this.submitted = false;
        this.addRobotForm.reset();
        this.closeModal();
        this.getRobotStatus();
      }else{
        return;
      }
    })
  }

  //Edit Robot
  get e() { return this.addRobotForm.controls }
  editFleetId:any = '';
  openEditModel(value,robotId,robotEndpoint) {
    this.modalShow = value;
    this.addRobotForm = this.formBuilder.group({
      id: [robotId, Validators.required],
      endpoint: [robotEndpoint, Validators.required],
    });
  }

  EditRobot(){
    this.submitted = true;
    if(this.addRobotForm.invalid){
      return;
    }

    var body = {
      robotId: this.addRobotForm.get('id').value,
      robotEndpoint: this.addRobotForm.get('endpoint').value,
    }

    this.ottonomyservice.EditRobot(body).then(s => {
      if(s['status'] == 200){
        this.submitted = false;
        this.addRobotForm.reset();
        this.closeModal();
        this.getRobotStatus();
      }else{
        return;
      }
    })
  }

  // Delete User
  robotID:any = '';
  openDeleteModal(value,id) {
    this.modalShow = value;
    this.robotID = id;
  }
  deleteRobot(){
    var body = {
      robotId: this.robotID,
    };
    this.ottonomyservice.DeleteRobot(body).then(s => {
      if(s['status'] == 200){
        this.closeModal()
        this.getRobotStatus();
      }else{
        return;
      }
    })
  }

}

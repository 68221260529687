<div class="order_detail_container">
    <h4 class="title">Last 10 Orders</h4>
    <div class="order_list_container">
        <div class="order_list_box" *ngFor="let order of orderStatusData;let i = index;">
            <div class="detial_box">
                <h6>
                    <span>Order ID: {{order.orderId}}</span>
                </h6>
                <div class="robot">{{order.robotId}}</div>
                <p class="date">{{order.createdAt | slice:0:10}}&nbsp;&nbsp;{{order.createdAt | slice:11:19}} EST</p>
                <p class="date">CABIN:&nbsp;&nbsp;{{order.boxId}}</p>
                <div class="checks_list_box">
                    <ul class="checks_list">
                        <li [class.active]="order.statusCode == 1"></li>
                        <li [class.active]="order.statusCode == 2"></li>
                        <li [class.active]="order.statusCode == 3"></li>
                        <li [class.active]="order.statusCode == 4"></li>
                        <li [class.active]="order.statusCode == 5"></li>
                        <li [class.active]="order.statusCode == 6"></li>
                        <li [class.active]="order.statusCode == 7"></li>
                        <li [class.active]="order.statusCode == 8"></li>
                        <li [class.active]="order.statusCode == 9"></li>
                    </ul>
                    <hr>
                </div>
                <h5>{{order.orderStatus}}</h5>
            </div>
            <div class="timeline_box">
                <div class="checkpoint_detail">
                    <div class="icon_box active">
                        <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M9 0c4.9683.00548469 8.9945 4.03171 9 9 0 4.9625-4.0375 9-9 9-4.96829-.0055-8.99451447-4.0317-9-9 0-4.96255 4.03788-9 9-9zm-.34615 8.65385H2.37981L13.5913 13.5865 8.65385 2.37981V8.65385z" fill="#3DF78F"/>
                        </svg>
                    </div>
                    <div class="line"></div>
                    <div class="details">
                        <h4>{{order.pickupId}}</h4>
                        <!-- <p>10:30 AM</p> -->
                    </div>
                </div>
                <div class="checkpoint_detail">
                    <div class="icon_box">
                        <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M9 0c4.9683.00548469 8.9945 4.03171 9 9 0 4.9625-4.0375 9-9 9-4.96829-.0055-8.99451447-4.0317-9-9 0-4.96255 4.03788-9 9-9zm-.34615 8.65385H2.37981L13.5913 13.5865 8.65385 2.37981V8.65385z" fill="#3DF78F"/>
                        </svg>
                    </div>
                    <div class="line"></div>
                    <div class="details">
                        <h4>{{order.dropId}}</h4>
                        <!-- <p>10:46 AM</p> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
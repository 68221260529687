<div class="robot_chart_box">
    <div class="heading">
        <select class="form-control" #chartSelect (change)='chartChange(chartSelect.value)'>
            <option value="CPU">CPU Usage</option>
            <option value="RAM">RAM Consumption</option>
        </select>
    </div>
    <div class="chart_container" *ngIf="chartValue == 'CPU'">
        <h4 class="title">CPU Usage ( 8 CPU's)</h4>
        <div class="chart_box">
            <canvas baseChart
                    [datasets]="lineChartData"
                    [labels]="lineChartLabels"
                    [options]="lineChartOptions"
                    [colors]="lineChartColors"
                    [legend]="lineChartLegend"
                    [chartType]="lineChartType"
                    (chartHover)="chartHovered($event)"
                    (chartClick)="chartClicked($event)"></canvas>
        </div>
    </div>

    <div class="chart_container" *ngIf="chartValue == 'RAM'">
        <h4 class="title">RAM Consumption</h4>
        <div class="chart_box">
            <canvas baseChart
            [colors]="barChartColors"
            [datasets]="barChartData"
            [labels]="barChartLabels"
            [options]="barChartOptions"
            [legend]="barChartLegend"
            [chartType]="barChartType">
        </canvas>
        </div>
    </div>
</div>
import { Component, HostListener, OnInit, OnDestroy } from '@angular/core';
import { OttonomyService } from '../services/ottonomy.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GamepadService } from 'ngx-gamepad';
import { AlertService } from '../../app/services/alert.service';

declare var $: any;
declare var AWS: any;
declare var KVSWebRTC: any;

@Component({
  selector: 'app-robot-detail',
  templateUrl: './robot-detail.component.html',
  styleUrls: ['./robot-detail.component.scss']
})

export class RobotDetailComponent implements OnInit, OnDestroy {
  public RobotData: any = [];
  viewerFlag = false;
  startGamepadData: any;                    // Flag for keeping tap on the status of the controller
  RobotStatusData: any;
  RobotId: any;
  RobotStatus: any;
  Box1Status: any;
  Box2Status: any;
  RobotBattery: any;
  ActiveSimRssi: any;
  Door1Status: any;
  Door2Status: any;
  DistanceTravelled: any;
  robotRunningState: string;
  LS: any;
  RS: any;
  currentTime: any;
  errorOccured: boolean = false;
  manualMode: boolean = false;
  fleetID: string;
  robotCurrentSpeed: number = 0.00;
  motorStatus: string;
  sshStatus: string = "OPEN";
  door1Status: string = "";
  door2Status: string = "";
  errorMsg: string = "";
  malfunction: boolean = false;

  interval:any;
  gamepadInterval:any;

  userDetail;
  userRole:any;
  roleValue:boolean;
  obstacleCounter:number = 0;
  obstacleFlag:boolean = false;
  viewerStatus:string = "START";
  butType: string;
  actionName: string;
  wanderStatus: string;
  testingStatus : string;

  constructor(private router: Router, private route: ActivatedRoute, public ottonomyservice: OttonomyService, private gamepad: GamepadService, private alertService: AlertService) {
    router.events.subscribe(val => {
      this.getRobotStatus();
    });
    route.params.subscribe(val => {
      try {
        // this.startStream("FRONT");
        // this.viewerBegin();
      } catch {
        // console.log("Request Already Sent");
      }

    });
  }

  robotStatusDuoGroup = [
    "LOW_BATTERY",
    "TELE_OP",
    "AUTO_RUN",
    "PAYLOAD_STATE", 
    "AUTO_SYSTEM_FAULT"
  ];

  robotStatusAutoGroup = [
    "TELE_OP_MOVING",
    "TELE_OP_OBSTACLE",
  ]

  robotStatusTeleGroup = [
    "AUTO_RUN_MOVING",
    "AUTO_OBSTACLE",
  ]

  robotStatusPayLoadGroup = [
    "TELE_OP_MOVING",
    "AUTO_RUN_MOVING",
    "AUTO_OBSTACLE",
    "TELE_OP_OBSTACLE",
    "TELE_OP",
    "AUTO_RUN"
  ]

  // Robot Status By Robot ID
  getRobotStatus() {
    var body = {
      "robotId": this.route.snapshot.params.id,
    }
    //console.log('-> body :', body);
    this.ottonomyservice.getRobotDataByRobotId(body).then(s => {
      if (s['status'] == 200) {
        this.RobotStatusData = s['data'];
        this.malfunction = this.RobotStatusData['robotStatus']['malfunction'];        
        this.RobotId = this.RobotStatusData.robotStatus.robotId;
        this.RobotStatus = this.RobotStatusData.robotDetailStatus.robotStatus;
        this.Box1Status = this.RobotStatusData.robotDetailStatus.box1Status;
        this.Box2Status = this.RobotStatusData.robotDetailStatus.box2Status;
        this.RobotBattery = this.RobotStatusData.robotStatus.robotBattery;
        this.ActiveSimRssi = this.RobotStatusData.robotStatus.activeSimRssi;
        this.DistanceTravelled = this.RobotStatusData.robotStatus.distanceTravelled;
        this.robotRunningState = this.RobotStatusData.robotStatus.robotRunningState;
        this.robotCurrentSpeed = parseFloat(this.RobotStatusData.robotStatus.currentSpeed);
        this.errorMsg = this.RobotStatusData.robotStatus.statusMessage;
        this.wanderStatus = this.RobotStatusData.robotStatus.wanderMode;
        this.testingStatus = this.RobotStatusData.robotStatus.testingMode;
        console.log(this.testingStatus);

        if(this.robotRunningState == "AUTO_OBSTACLE"){
          this.obstacleCounter += 1;
          if(this.obstacleCounter >= 5){
            this.obstacleFlag = true;
          }
        } 
        else if(this.obstacleCounter >= 5 && this.robotRunningState != "AUTO_OBSTACLE"){
          this.obstacleFlag = false;
          this.obstacleCounter += 0;
        }


        if (this.robotRunningState == "AUTO_SYSTEM_FAULT" || this.robotRunningState == "MANUAL") {
          this.motorStatus = "ENGAGE_MOTORS"
        } else {
          this.motorStatus = "DISENGAGE_MOTORS"
        }
        // FleetID is going to be same throughout the program,
        // So only update it at the starting as fleetID will have null value then
        if (!this.fleetID) {
          this.fleetID = this.RobotStatusData.robotDetailStatus.fleetId;
        }

        //---------------------------------------------------------------------------------------------
        //Logic for opening and closing door
        if (this.RobotStatusData.robotStatus.door1Status == "ERROR") {
          this.door1Status = "CLEAR ERROR 1"
        }
        else {
          if (this.door1Status == "OPENING 1" && this.RobotStatusData.robotStatus.door1Status == "OPEN") {
            this.door1Status = 'CLOSE Door 1';
          }
          else if (this.door1Status == "CLOSING 1" && this.RobotStatusData.robotStatus.door1Status == "CLOSE") {
            this.door1Status = 'OPEN Door 1';
          }
          else if (this.door1Status == "") {
            this.door1Status = this.RobotStatusData.robotStatus.door1Status == "OPEN" ? 'CLOSE Door 1' : 'OPEN Door 1';
          }
          else if (this.door1Status == "OPEN Door 1" && this.RobotStatusData.robotStatus.door1Status == "OPEN") {
            this.door1Status = 'CLOSE Door 1';
          }
          else if (this.door1Status == "CLOSE Door 1" && this.RobotStatusData.robotStatus.door1Status == "CLOSE") {
            this.door1Status = 'OPEN Door 1';
          }
          else if (this.door1Status == "CLEAR ERROR 1" && this.RobotStatusData.robotStatus.door1Status == "OPEN") {
            this.door1Status = 'CLOSE Door 1';
          }
          else if (this.door1Status == "CLEAR ERROR 1" && this.RobotStatusData.robotStatus.door1Status == "CLOSE") {
            this.door1Status = 'OPEN Door 1';
          }
          
        }

        if (this.RobotStatusData.robotStatus.door2Status == "ERROR"){
          this.door2Status = "CLEAR ERROR 2"; 
        } 
        else {
          if (this.door2Status == "OPENING 2" && this.RobotStatusData.robotStatus.door2Status == "OPEN") {
            this.door2Status = 'CLOSE Door 2';
          }
          else if (this.door2Status == "CLOSING 2" && this.RobotStatusData.robotStatus.door2Status == "CLOSE") {
            this.door2Status = 'OPEN Door 2';
          }
          else if (this.door2Status == "") {
            this.door2Status = this.RobotStatusData.robotStatus.door2Status == "OPEN" ? 'CLOSE Door 2' : 'OPEN Door 2';
          }
          else if (this.door2Status == "CLOSE Door 2" && this.RobotStatusData.robotStatus.door2Status == "CLOSE") {
            this.door2Status = 'OPEN Door 2';
          }
          else if (this.door2Status == "OPEN Door 2" && this.RobotStatusData.robotStatus.door2Status == "OPEN") {
            this.door2Status = 'CLOSE Door 2';
          }
          else if(this.door2Status == "CLEAR ERROR 2" && this.RobotStatusData.robotStatus.door2Status == "OPEN"){
            this.door2Status = 'CLOSE Door 2';
          }
          else if(this.door2Status == "CLEAR ERROR 2" && this.RobotStatusData.robotStatus.door2Status == "CLOSE"){
            this.door2Status = 'OPEN Door 2';
          }

        }
        //---------------------------------------------------------------------------------------------------------
      }
    })
  }
  // To set initial Position of Play/Pause Button
  setPlayPause() {
    if (this.RobotStatusData.robotStatus.statusMessage == "RUN") {
      this.pause == this.RobotId;
    } else {
      this.pause == this.RobotId;
    }
  }


  // Function to check if the fleet in which robot is assigned is available or not
  checkAvailability() {
    if (this.fleetID != null) {
      var body = {
        'fleetId': this.fleetID
      }
      this.ottonomyservice.checkFleetAvailability(body).then(s => {
      })
    }
  }


  currentRobotStatus: any;
  getCurrentOrderByRobotId() {
    var body = {
      "robotId": this.route.snapshot.params.id,
    }
    this.ottonomyservice.currentOrderByRobotId(body).then(s => {
      if (s['status'] == 200) {
        this.currentRobotStatus = s['currentOrder'];
      }
      // else{
      //   this.currentRobotStatus = '&nbsp;';
      // }
    })
  }

  distanceCoveredTodayStatus: number = 0;
  getDistanceCoveredToday() {
    var body = {
      "robotId": this.route.snapshot.params.id,
    }
    this.ottonomyservice.distanceCoveredToday(body).then(s => {
      if (s['status'] == 200) {
        this.distanceCoveredTodayStatus = s['totalDistanceTravelled'];
      }
      this.alertService.error(s['message']);
    })
  }

  ngOnChanges() {
    this.ngOnInit();
  }

  playPuase: any;
  play: any = '';
  pause: any = '';

  robotPlay(id) {
    var body = {
      'robotId': id
    }
    this.ottonomyservice.playRobot(body).then(s => {
      if (s['status'] == 200) {
        this.pause = id;
        this.play = id;
      }
      this.alertService.error(s['message']);
    })
  }
  robotPause(id) {
    var body = {
      'robotId': id
    }
    this.ottonomyservice.pauseRobot(body).then(s => {
      if (s['status'] == 200) {
        this.pause = ' ';
        this.play = ' ';
      }
      this.alertService.error(s['message']);
    })
  }


  changeDoorStatus(value, id, status) {
    var body = {
      'robotId': id,
      "compartment": 'CABIN' + value
    }


    if (status.split(" ")[0] == "OPEN") {
      this.ottonomyservice.openDoorByRobotId(body).then(s => {
        if (s['status'] == 200) {
          if (value == 1) {
            this.door1Status = "OPENING 1";
          } else {
            this.door2Status = "OPENING 2";
          }
        }
      })
    }
    else if (status.split(" ")[0] == "CLOSE"){
      this.ottonomyservice.closeDoorByRobotId(body).then(s => {
        if (s['status'] == 200) {
          if (value == 1) {
            this.door1Status = "CLOSING 1";
          } else {
            this.door2Status = "CLOSING 2";
          }
        }
      })
    }
    else{
      this.ottonomyservice.resetCabinError(body).then(s => {
        if (s['status'] == 200) {
          if (value == 1) {
            this.door1Status = "RESETTING 1";
          } else {
            this.door2Status = "RESETTING 2";
          }
        }
      })
    }
  }

  closeDoor(value, id) {
    var body = {
      'robotId': id,
      "compartment": 'CABIN' + value
    }

  }

  videoQuality = "HD";
  changeQuality(value) {
    this.videoQuality = value;
  }
  fullView = true;
  changeView() {
    this.fullView = !this.fullView;
    if (!this.fullView) {
      this.startStream('ALL')
    }
    if (this.fullView) {
      this.startStream('FRONT')
    }
  }

  refreshVideo(robotIdFromNav) {
    this.stopViewer();
    this.router.navigate(['/robot-detail', robotIdFromNav]);
  }

  cameraType = "front"
  selectCamera(event) {
    this.cameraType = event.target.value;
    if (this.cameraType == "front") {
      this.stopViewer();
      this.viewerBegin();
    }
    if (this.cameraType == "back") {
      this.stopViewer();
      this.viewerBegin();
    }
  }

  modalShow;
  openModal(value) {
    this.modalShow = value;
  }
  closeModal() {
    this.modalShow = '';
    this.butType = "";
    this.actionName = "";
  }

  openManualModal(value) {
    this.modalShow = value;
  }

  // TO reset diagnostic interupt
  resetDiagnostics() {
    var body = {
      'robotId': this.route.snapshot.params.id,
      "robotMode": "RESET_DIAGNOSTICS"
    }
    this.ottonomyservice.robotMode(body).then(s => {
      if (s['status'] == 200) {
      }
      this.alertService.error(s['message']);
    })
  }

  // To Engage/Disengage motors interupt
  motorsStatusChange() {
    var body = {
      'robotId': this.route.snapshot.params.id,
      "robotMode": this.motorStatus
    }
    this.ottonomyservice.robotMode(body).then(s => {
      if (s['status'] == 200) {
        if (this.robotRunningState == "AUTO_SYSTEM_FAULT" || this.robotRunningState == "MANUAL") {
          this.motorStatus = "ENGAGE_MOTORS"
        } else {
          this.motorStatus = "DISENGAGE_MOTORS"
        }
      }
      this.alertService.error(s['message']);
    })
  }
  clearCommands() {
    var body = {
      'robotId': this.route.snapshot.params.id,
      "robotMode": "CLEAR_COMMANDS"
    }
    this.ottonomyservice.robotMode(body).then(s => {
      if (s['status'] == 200) {
        this.alertService.success(s['message']);
      }
      this.alertService.error(s['message']);
    })
  }
  // manualMode = false;
  modeValue = ''
  robotModeChange(newMode) {
    // if(this.manualMode == true){
    //   this.modeValue = this.modalShow
    // }
    var body = {
      'robotId': this.route.snapshot.params.id,
      "robotMode": newMode
      //"robotMode": this.modalShow
    }

    this.ottonomyservice.robotMode(body).then(s => {
      if (s['status'] == 200) {
        this.closeModal()
        this.manualMode = !this.manualMode;
      }
      this.alertService.error(s['message']);
    })

  }

  clearMalfunction(){
    var clearbody = {
      'robotId': this.route.snapshot.params.id,
    }
    this.ottonomyservice.clearRobotMalfunction(clearbody).then(s => {
      if (s['status'] == 200) {
      }
    })
  }

  startStream(value) {
    var body = {
      'robotId': this.route.snapshot.params.id,
      "stream": value
    }

    this.ottonomyservice.startVideoStream(body).then(s => {
      // console.log('-> S :', s);
      if (s['status'] == 200) {
        this.viewerFlag = true;
        if (value == 'FRONT') {
          //this.stopViewer();
          //this.stopViewer2();
          //this.stopViewer3();
          this.viewerBegin();
        }
        if (value == 'ALL') {
          //this.stopViewer();
          this.viewerBegin();
          this.viewerBegin2();
          this.viewerBegin3();
        }
      }
    })
  }


  changeStreamStatus(){
    if(this.viewerStatus == "START"){
      this.startStream("FRONT");
      this.viewerStatus = "STOP";
    }else{
      this.stopViewer();
      this.stopViewer2();
      this.stopViewer3();
      this.viewerStatus = "START";
    }
  }
  /*  ng@HostListener('document:keydown', ['$event'])
   handleKeyboardEvent(event: KeyboardEvent) {
     // console.log('=>', event.keyCode);
     this.sendViewerMessage(event.keyCode);
     this.sendViewerMessage2(event.keyCode);
     this.sendViewerMessage3(event.keyCode);
   }*/
  ngOnDestroy(): void {
    this.ottonomyservice.sendMessage("");
    this.startGamepadData = false;
    this.stopViewer();
    this.stopViewer2();
    this.stopViewer3();
    if(this.interval){
      clearInterval(this.interval);
    }
    clearInterval(this.gamepadInterval)  
  }
  ngOnInit(): void {
    this.userDetail = JSON.parse(sessionStorage.getItem('user'));
    this.userRole = this.userDetail.role[0];
    if(this.userRole == "user"){
      this.roleValue = false;

    }
    if(this.userRole == "admin" || this.userRole == "onsite-admin"){
      this.roleValue = true;
    }
    console.log(this.userRole);
    this.kinesisCredentialDetails();
    this.getDistanceCoveredToday();
    this.getRobotStatus();
    this.interval = setInterval(() => {

      // Periodically calls checkavailablity function so that 
      // it can update the status of the fleet in the database
      this.checkAvailability();
      this.getRobotStatus();
      this.getCurrentOrderByRobotId();
      //this.getDistanceCoveredToday();
      //this.setPlayPause();
    }, 2000)
    $(".loader").addClass('d-none');
    $('#main').removeClass('d-none');
    //this.stopViewer();
    //this.stopViewer2();
    //this.stopViewer3();
    // this.startStream('FRONT')
    //setTimeout(() => {
    // this.viewerBegin();
    //this.viewerBegin2();
    // this.viewerBegin3();
    // },2000)
    this.listenToGamepad();
    this.gamepadInterval = setInterval(() => {

      if (this.startGamepadData == true) {
        const myGamepad = navigator.getGamepads()[0]; // use the first gamepad
        this.RS = (-1 * myGamepad.axes[2] / 3).toFixed(2);
        this.LS = (-1 * myGamepad.axes[1] / 3).toFixed(2);
        var now = new Date();
        this.currentTime = now.getTime();                            // Taking current time in a global variable
        // // console.log('LS ' + this.LS + ' RS ' + this.RS + ' ' + String(this.currentTime));
        this.sendViewerMessage(this.LS + ' ' + this.RS + ' ' + String(this.currentTime));        //Sending the message to the Robot via Kinesis
      }
    }, 20) // print axes 50 times per second 20
  }

  public kinesisCredential
  secretAccessKey = '';
  accessKeyId = '';
  clientId = '';
  region = '';

  kinesisCredentialDetails() {
    var body = {
      'robotId': this.route.snapshot.params.id
    }
    this.ottonomyservice.credentialDetailsByRobotId(body).then(s => {
      this.kinesisCredential = s['data'];
      this.secretAccessKey = '';
      this.accessKeyId = '';
      this.clientId = '';
      this.region = '';
      if (s['data'] != null) {
        this.secretAccessKey = this.kinesisCredential.secretAccessKey;
        this.accessKeyId = this.kinesisCredential.accessKeyId;
        this.clientId = this.kinesisCredential.clientId;
        this.region = this.kinesisCredential.region;
      }
    })
  };

  public ROLE = null; // Possible values: 'master', 'viewer', null

  private listenToGamepad() {
    this.gamepad.connect().subscribe(() => {
      this.gamepad.after('button0').subscribe(() => {
        var now = new Date();
        this.sendViewerMessage('buttonA' + ' ' + String(now.getTime()));
        // console.log('buttonA' + ' ' + String(now.getTime()));
      });

      this.gamepad.after('button1').subscribe(() => {
        var now = new Date();
        this.sendViewerMessage('buttonB' + ' ' + String(now.getTime()));
        // console.log('buttonB' + ' ' + String(now.getTime()));
      });

      this.gamepad.after('button2').subscribe(() => {
        var now = new Date();
        this.sendViewerMessage('buttonX' + ' ' + String(now.getTime()));
        // console.log('buttonX' + ' ' + String(now.getTime()));
      });

      this.gamepad.after('button3').subscribe(() => {
        var now = new Date();
        this.sendViewerMessage('buttonY' + ' ' + String(now.getTime()));
        // console.log('buttonY' + ' ' + String(now.getTime()));
      });

      this.gamepad.after('button4').subscribe(() => {
        var now = new Date();
        this.sendViewerMessage('buttonLF' + ' ' + String(now.getTime()));
        // console.log('buttonLF' + ' ' + String(now.getTime()));
      });

      this.gamepad.after('button5').subscribe(() => {
        var now = new Date();
        this.sendViewerMessage('buttonRF' + ' ' + String(now.getTime()));
        // console.log('buttonRF' + ' ' + String(now.getTime()));
      });

      this.gamepad.after('button6').subscribe(() => {
        var now = new Date();
        this.sendViewerMessage('buttonLL' + ' ' + String(now.getTime()));
        // console.log('buttonLL' + ' ' + String(now.getTime()));
      });

      this.gamepad.after('button7').subscribe(() => {
        var now = new Date();
        this.sendViewerMessage('buttonRL' + ' ' + String(now.getTime()));
        // console.log('buttonRL' + ' ' + String(now.getTime()));
      });
      this.gamepad.after('button12').subscribe(() => {
        var now = new Date();
        this.sendViewerMessage('buttonup' + ' ' + String(now.getTime()));
        // console.log('buttonup' + ' ' + String(now.getTime()));
      });

      this.gamepad.after('button13').subscribe(() => {
        var now = new Date();
        this.sendViewerMessage('buttondown' + ' ' + String(now.getTime()));
        // console.log('buttondown' + ' ' + String(now.getTime()));
      });

      this.gamepad.after('button14').subscribe(() => {
        var now = new Date();
        this.sendViewerMessage('buttonleft' + ' ' + String(now.getTime()));
        // console.log('buttonleft' + ' ' + String(now.getTime()));
      });

      this.gamepad.after('button15').subscribe(() => {
        var now = new Date();
        this.sendViewerMessage('buttonright' + ' ' + String(now.getTime()));
        // console.log('buttonright' + ' ' + String(now.getTime()));
      });
      this.gamepad.after('start').subscribe(() => {
        var now = new Date();
        this.sendViewerMessage('start' + ' ' + String(now.getTime()));
        this.startGamepadData = true;
        // console.log('start' + ' ' + String(now.getTime()));
      });

      this.gamepad.after('select').subscribe(() => {
        var now = new Date();
        this.startGamepadData = false;
        this.sendViewerMessage('back' + ' ' + String(now.getTime()));
        // console.log('back' + ' ' + String(now.getTime()));
      });
    });
  }

  getFormValues() {
    return {
      "accessKeyId": this.accessKeyId,
      "channelName": this.route.snapshot.params.id + "_" + this.cameraType,
      "clientId": this.clientId,
      "endpoint": null,
      "forceTURN": false,
      "fullscreen": false,
      "natTraversalDisabled": false,
      "openDataChannel": true,
      "region": this.region,
      "secretAccessKey": this.secretAccessKey,
      "sendAudio": false,
      "sendVideo": false,
      "sessionToken": null,
      "useTrickleICE": true,
      "widescreen": true
    };
  }
  getFormValues2() {
    return {
      "accessKeyId": this.accessKeyId,
      "channelName": this.route.snapshot.params.id + "_left",
      "clientId": this.clientId,
      "endpoint": null,
      "forceTURN": false,
      "fullscreen": false,
      "natTraversalDisabled": false,
      "openDataChannel": true,
      "region": "ap-south-1",
      "secretAccessKey": this.secretAccessKey,
      "sendAudio": false,
      "sendVideo": false,
      "sessionToken": null,
      "useTrickleICE": true,
      "widescreen": true
    };
  }
  getFormValues3() {
    return {
      "accessKeyId": this.accessKeyId,
      "channelName": this.route.snapshot.params.id + "_right",
      "clientId": this.clientId,
      "endpoint": null,
      "forceTURN": false,
      "fullscreen": false,
      "natTraversalDisabled": false,
      "openDataChannel": true,
      "region": "ap-south-1",
      "secretAccessKey": this.secretAccessKey,
      "sendAudio": false,
      "sendVideo": false,
      "sessionToken": null,
      "useTrickleICE": true,
      "widescreen": true
    };
  }

  toggleDataChannelElements() {
    if (this.getFormValues().openDataChannel) {
      $('.datachannel').removeClass('d-none');
    } else {
      $('.datachannel').addClass('d-none');
    }
  }

  toggleDataChannelElements2() {
    if (this.getFormValues2().openDataChannel) {
      $('.datachannel').removeClass('d-none');
    } else {
      $('.datachannel').addClass('d-none');
    }
  }

  toggleDataChannelElements3() {
    if (this.getFormValues3().openDataChannel) {
      $('.datachannel').removeClass('d-none');
    } else {
      $('.datachannel').addClass('d-none');
    }
  }



  onStop() {
    if (!this.ROLE) {
      return;
    }

    if (this.ROLE === 'viewer') {
      this.stopViewer();
      $('#viewer').addClass('d-none');
    }

    $('#form').removeClass('d-none');
    this.ROLE = null;
  }



  viewerBegin() {
    if (this.viewerFlag) {
      this.viewerFlag = false;
      this.ROLE = 'viewer';
      $('#form').addClass('d-none');
      $('#viewer').removeClass('d-none');


      const remoteView = $('.remote-view')[0];
      const formValues = this.getFormValues();


      this.toggleDataChannelElements();

      //this.startViewer( remoteView, formValues, this.onStatsReport, event => {
      this.startViewer(remoteView, formValues, event => {
      });
      //this.startGamepadData = true;
    } else {
      // console.log("Already Begin");
    }
  }

  viewerBegin2() {
    this.ROLE = 'viewer';
    $('#form').addClass('d-none');
    $('#viewer2').removeClass('d-none');


    const remoteView2 = $('.remote-view2')[0];
    const formValues2 = this.getFormValues2();


    this.toggleDataChannelElements2();

    //this.startViewer( remoteView, formValues, this.onStatsReport, event => {
    this.startViewer2(remoteView2, formValues2, event => {
    });
  }


  viewerBegin3() {
    this.ROLE = 'viewer';
    $('#form').addClass('d-none');
    $('#viewer3').removeClass('d-none');


    const remoteView3 = $('.remote-view3')[0];
    const formValues3 = this.getFormValues3();


    this.toggleDataChannelElements3();

    //this.startViewer( remoteView, formValues, this.onStatsReport, event => {
    this.startViewer3(remoteView3, formValues3, event => {
    });
  }

  async createSignalingChannel(formValues) {
    // Create KVS client
    const kinesisVideoClient = new AWS.KinesisVideo({
      region: formValues.region,
      accessKeyId: formValues.accessKeyId,
      secretAccessKey: formValues.secretAccessKey,
      sessionToken: formValues.sessionToken,
      endpoint: formValues.endpoint,
    });

    // Get signaling channel ARN
    await kinesisVideoClient
      .createSignalingChannel({
        ChannelName: formValues.channelName,
      }).promise();

    // Get signaling channel ARN
    const describeSignalingChannelResponse = await kinesisVideoClient
      .describeSignalingChannel({
        ChannelName: formValues.channelName,
      })
      .promise();
    const channelARN = describeSignalingChannelResponse.ChannelInfo.ChannelARN;
    // // console.log('[CREATE_SIGNALING_CHANNEL] Channel ARN: ', channelARN);
  }



  async createSignalingChannel2(formValues2) {
    // Create KVS client
    const kinesisVideoClient = new AWS.KinesisVideo({
      region: formValues2.region,
      accessKeyId: formValues2.accessKeyId,
      secretAccessKey: formValues2.secretAccessKey,
      sessionToken: formValues2.sessionToken,
      endpoint: formValues2.endpoint,
    });

    // Get signaling channel ARN
    await kinesisVideoClient
      .createSignalingChannel2({
        ChannelName: formValues2.channelName,
      }).promise();

    // Get signaling channel ARN
    const describeSignalingChannelResponse = await kinesisVideoClient
      .describeSignalingChannel({
        ChannelName: formValues2.channelName,
      })
      .promise();
    const channelARN = describeSignalingChannelResponse.ChannelInfo.ChannelARN;
    // // console.log('[CREATE_SIGNALING_CHANNEL] Channel ARN: ', channelARN);
  }




  async createSignalingChannel3(formValues3) {
    // Create KVS client
    const kinesisVideoClient = new AWS.KinesisVideo({
      region: formValues3.region,
      accessKeyId: formValues3.accessKeyId,
      secretAccessKey: formValues3.secretAccessKey,
      sessionToken: formValues3.sessionToken,
      endpoint: formValues3.endpoint,
    });

    // Get signaling channel ARN
    await kinesisVideoClient
      .createSignalingChannel3({
        ChannelName: formValues3.channelName,
      }).promise();

    // Get signaling channel ARN
    const describeSignalingChannelResponse = await kinesisVideoClient
      .describeSignalingChannel({
        ChannelName: formValues3.channelName,
      })
      .promise();
    const channelARN = describeSignalingChannelResponse.ChannelInfo.ChannelARN;
    console.log('[CREATE_SIGNALING_CHANNEL] Channel ARN: ', channelARN);
  }










  /*This file demonstrates the process of starting WebRTC streaming using a KVS Signaling Channel */
  public viewer: any = {};

  //async startViewer( remoteView, formValues, onStatsReport, onRemoteDataMessage) {
  async startViewer(remoteView, formValues, onRemoteDataMessage) {
    console.log('===> Call startViewer');


    this.viewer.remoteView = remoteView;


    // Create KVS client
    const kinesisVideoClient = new AWS.KinesisVideo({
      region: formValues.region,
      accessKeyId: formValues.accessKeyId,
      secretAccessKey: formValues.secretAccessKey,
      sessionToken: formValues.sessionToken,
      endpoint: formValues.endpoint,
      correctClockSkew: true,
    });

    // Get signaling channel ARN
    const describeSignalingChannelResponse = await kinesisVideoClient
      .describeSignalingChannel({
        ChannelName: formValues.channelName,
      })
      .promise();
    const channelARN = describeSignalingChannelResponse.ChannelInfo.ChannelARN;
    console.log('[VIEWER] Channel ARN: ', channelARN);

    // Get signaling channel endpoints
    const getSignalingChannelEndpointResponse = await kinesisVideoClient
      .getSignalingChannelEndpoint({
        ChannelARN: channelARN,
        SingleMasterChannelEndpointConfiguration: {
          Protocols: ['WSS', 'HTTPS'],
          Role: KVSWebRTC.Role.VIEWER,
        },
      })
      .promise();
    const endpointsByProtocol = getSignalingChannelEndpointResponse.ResourceEndpointList.reduce((endpoints, endpoint) => {
      endpoints[endpoint.Protocol] = endpoint.ResourceEndpoint;
      return endpoints;
    }, {});
    console.log('[VIEWER] Endpoints: ', endpointsByProtocol);

    const kinesisVideoSignalingChannelsClient = new AWS.KinesisVideoSignalingChannels({
      region: formValues.region,
      accessKeyId: formValues.accessKeyId,
      secretAccessKey: formValues.secretAccessKey,
      sessionToken: formValues.sessionToken,
      endpoint: endpointsByProtocol.HTTPS,
      correctClockSkew: true,
    });

    // Get ICE server configuration
    const getIceServerConfigResponse = await kinesisVideoSignalingChannelsClient
      .getIceServerConfig({
        ChannelARN: channelARN,
      })
      .promise();
    const iceServers = [];
    var iceTransportPolicy;

    if (!formValues.natTraversalDisabled && !formValues.forceTURN) {
      iceServers.push({ urls: `stun:stun.kinesisvideo.${formValues.region}.amazonaws.com:443` });
    }
    if (!formValues.natTraversalDisabled) {
      getIceServerConfigResponse.IceServerList.forEach(iceServer =>
        iceServers.push({
          urls: iceServer.Uris,
          username: iceServer.Username,
          credential: iceServer.Password,
        }),
      );
    }
    console.log('[VIEWER] ICE servers: ', iceServers);

    // Create Signaling Client
    this.viewer.signalingClient = new KVSWebRTC.SignalingClient({
      channelARN,
      channelEndpoint: endpointsByProtocol.WSS,
      clientId: formValues.clientId,
      role: KVSWebRTC.Role.VIEWER,
      region: formValues.region,
      credentials: {
        accessKeyId: formValues.accessKeyId,
        secretAccessKey: formValues.secretAccessKey,
        sessionToken: formValues.sessionToken,
      },
      systemClockOffset: kinesisVideoClient.config.systemClockOffset,
    });

    const resolution = formValues.widescreen ? { width: { ideal: 1280 }, height: { ideal: 720 } } : { width: { ideal: 640 }, height: { ideal: 480 } };
    const constraints = {
      video: formValues.sendVideo ? resolution : false,
      audio: formValues.sendAudio,
    };
    iceTransportPolicy = 'all'
    const configuration = {
      iceServers,
      iceTransportPolicy: iceTransportPolicy,
    };
    this.viewer.peerConnection = new RTCPeerConnection(configuration);
    if (formValues.openDataChannel) {
      this.viewer.dataChannel = this.viewer.peerConnection.createDataChannel('kvsDataChannel');
      this.viewer.peerConnection.ondatachannel = event => {
        event.channel.onmessage = onRemoteDataMessage;
      };
    }

    // Poll for connection stats
    //this.viewer.peerConnectionStatsInterval = setInterval(() => this.viewer.peerConnection.getStats().then(onStatsReport), 1000);

    this.viewer.signalingClient.on('open', async () => {
      console.log('[VIEWER] Connected to signaling service');

      // Get a stream from the webcam, add it to the peer connection, and display it in the local view.
      // If no video/audio needed, no need to request for the sources.
      // Otherwise, the browser will throw an error saying that either video or audio has to be enabled.
      if (formValues.sendVideo || formValues.sendAudio) {
        try {
          this.viewer.localStream = await navigator.mediaDevices.getUserMedia(constraints);
          this.viewer.localStream.getTracks().forEach(track => this.viewer.peerConnection.addTrack(track, this.viewer.localStream));
        } catch (e) {
          console.error('[this.viewer] Could not find webcam');
          return;
        }
      }

      // Create an SDP offer to send to the master
      console.log('[VIEWER] Creating SDP offer');
      await this.viewer.peerConnection.setLocalDescription(
        await this.viewer.peerConnection.createOffer({
          offerToReceiveAudio: true,
          offerToReceiveVideo: true,
        }),
      );

      // When trickle ICE is enabled, send the offer now and then send ICE candidates as they are generated. Otherwise wait on the ICE candidates.
      if (formValues.useTrickleICE) {
        console.log('[VIEWER] Sending SDP offer');
        this.viewer.signalingClient.sendSdpOffer(this.viewer.peerConnection.localDescription);
      }
      console.log('[this.viewer] Generating ICE candidates');
    });

    this.viewer.signalingClient.on('sdpAnswer', async answer => {
      // Add the SDP answer to the peer connection
      console.log('[this.viewer] Received SDP answer');
      await this.viewer.peerConnection.setRemoteDescription(answer);
    });

    this.viewer.signalingClient.on('iceCandidate', candidate => {
      // Add the ICE candidate received from the MASTER to the peer connection
      console.log('[this.viewer] Received ICE candidate');
      this.viewer.peerConnection.addIceCandidate(candidate);
    });

    this.viewer.signalingClient.on('close', () => {
      // // console.log('[this.viewer] Disconnected from signaling channel');
    });

    this.viewer.signalingClient.on('error', error => {
      console.error('[this.viewer] Signaling client error: ', error);
    });

    // Send any ICE candidates to the other peer
    this.viewer.peerConnection.addEventListener('icecandidate', ({ candidate }) => {
      if (candidate) {
        console.log('[this.viewer] Generated ICE candidate');

        // When trickle ICE is enabled, send the ICE candidates as they are generated.
        if (formValues.useTrickleICE) {
          // // console.log('[this.viewer] Sending ICE candidate');
          this.viewer.signalingClient.sendIceCandidate(candidate);
        }
      } else {
        console.log('[this.viewer] All ICE candidates have been generated');

        // When trickle ICE is disabled, send the offer now that all the ICE candidates have ben generated.
        if (!formValues.useTrickleICE) {
          // // console.log('[this.viewer] Sending SDP offer');
          this.viewer.signalingClient.sendSdpOffer(this.viewer.peerConnection.localDescription);
        }
      }
    });

    // As remote tracks are received, add them to the remote view
    this.viewer.peerConnection.addEventListener('track', event => {
      console.log('[this.viewer] Received remote track');
      if (remoteView.srcObject) {
        return;
      }
      this.viewer.remoteStream = event.streams[0];
      remoteView.srcObject = this.viewer.remoteStream;
    });

    console.log('[this.viewer] Starting this.viewer connection');
    this.viewer.signalingClient.open();
  }






  stopViewer() {
    if (!this.viewerFlag) {
      console.log('[this.viewer] Stopping this.viewer connection');
      if (this.viewer.signalingClient) {
        this.viewer.signalingClient.close();
        this.viewer.signalingClient = null;
      }

      if (this.viewer.peerConnection) {
        this.viewer.peerConnection.close();
        this.viewer.peerConnection = null;
      }

      if (this.viewer.localStream) {
        this.viewer.localStream.getTracks().forEach(track => track.stop());
        this.viewer.localStream = null;
      }

      if (this.viewer.remoteStream) {
        this.viewer.remoteStream.getTracks().forEach(track => track.stop());
        this.viewer.remoteStream = null;
      }

      if (this.viewer.peerConnectionStatsInterval) {
        clearInterval(this.viewer.peerConnectionStatsInterval);
        this.viewer.peerConnectionStatsInterval = null;
      }
      if (this.viewer.remoteView) {
        this.viewer.remoteView.srcObject = null;
      }

      if (this.viewer.dataChannel) {
        this.viewer.dataChannel = null;
      }
      this.viewerFlag = true;
      this.startGamepadData = false;
    }
    else {
      // console.log("Already Stopped");
    }

  }




  sendViewerMessage(message) {
    if (this.viewer.dataChannel) {
      try {
        console.log(this.viewer);
        this.viewer.dataChannel.send(message);
      } catch (e) {
        console.error('[this.viewer] Send DataChannel: ', e.toString());
      }
    }
  }







  /*This file demonstrates the process of starting WebRTC streaming using a KVS Signaling Channel */
  public viewer2: any = {};

  //async startViewer( remoteView, formValues, onStatsReport, onRemoteDataMessage) {
  async startViewer2(remoteView2, formValues2, onRemoteDataMessage) {
    console.log('===> Call startViewer');


    this.viewer2.remoteView2 = remoteView2;


    // Create KVS client
    const kinesisVideoClient = new AWS.KinesisVideo({
      region: formValues2.region,
      accessKeyId: formValues2.accessKeyId,
      secretAccessKey: formValues2.secretAccessKey,
      sessionToken: formValues2.sessionToken,
      endpoint: formValues2.endpoint,
      correctClockSkew: true,
    });

    // Get signaling channel ARN
    const describeSignalingChannelResponse = await kinesisVideoClient
      .describeSignalingChannel({
        ChannelName: formValues2.channelName,
      })
      .promise();
    const channelARN = describeSignalingChannelResponse.ChannelInfo.ChannelARN;
    console.log('[VIEWER] Channel ARN: ', channelARN);

    // Get signaling channel endpoints
    const getSignalingChannelEndpointResponse = await kinesisVideoClient
      .getSignalingChannelEndpoint({
        ChannelARN: channelARN,
        SingleMasterChannelEndpointConfiguration: {
          Protocols: ['WSS', 'HTTPS'],
          Role: KVSWebRTC.Role.VIEWER,
        },
      })
      .promise();
    const endpointsByProtocol = getSignalingChannelEndpointResponse.ResourceEndpointList.reduce((endpoints, endpoint) => {
      endpoints[endpoint.Protocol] = endpoint.ResourceEndpoint;
      return endpoints;
    }, {});
    console.log('[VIEWER] Endpoints: ', endpointsByProtocol);

    const kinesisVideoSignalingChannelsClient = new AWS.KinesisVideoSignalingChannels({
      region: formValues2.region,
      accessKeyId: formValues2.accessKeyId,
      secretAccessKey: formValues2.secretAccessKey,
      sessionToken: formValues2.sessionToken,
      endpoint: endpointsByProtocol.HTTPS,
      correctClockSkew: true,
    });

    // Get ICE server configuration
    const getIceServerConfigResponse = await kinesisVideoSignalingChannelsClient
      .getIceServerConfig({
        ChannelARN: channelARN,
      })
      .promise();
    const iceServers = [];
    var iceTransportPolicy;

    if (!formValues2.natTraversalDisabled && !formValues2.forceTURN) {
      iceServers.push({ urls: `stun:stun.kinesisvideo.${formValues2.region}.amazonaws.com:443` });
    }
    if (!formValues2.natTraversalDisabled) {
      getIceServerConfigResponse.IceServerList.forEach(iceServer =>
        iceServers.push({
          urls: iceServer.Uris,
          username: iceServer.Username,
          credential: iceServer.Password,
        }),
      );
    }
    console.log('[VIEWER2] ICE servers: ', iceServers);

    // Create Signaling Client
    this.viewer2.signalingClient2 = new KVSWebRTC.SignalingClient({
      channelARN,
      channelEndpoint: endpointsByProtocol.WSS,
      clientId: formValues2.clientId,
      role: KVSWebRTC.Role.VIEWER,
      region: formValues2.region,
      credentials: {
        accessKeyId: formValues2.accessKeyId,
        secretAccessKey: formValues2.secretAccessKey,
        sessionToken: formValues2.sessionToken,
      },
      systemClockOffset: kinesisVideoClient.config.systemClockOffset,
    });

    const resolution = formValues2.widescreen ? { width: { ideal: 1280 }, height: { ideal: 720 } } : { width: { ideal: 640 }, height: { ideal: 480 } };
    const constraints = {
      video: formValues2.sendVideo ? resolution : false,
      audio: formValues2.sendAudio,
    };
    iceTransportPolicy = 'all'
    const configuration = {
      iceServers,
      iceTransportPolicy: iceTransportPolicy,
    };
    this.viewer2.peerConnection2 = new RTCPeerConnection(configuration);
    if (formValues2.openDataChannel) {
      this.viewer2.dataChannel = this.viewer2.peerConnection2.createDataChannel('kvsDataChannel');
      this.viewer2.peerConnection2.ondatachannel = event => {
        event.channel.onmessage = onRemoteDataMessage;
      };
    }

    // Poll for connection stats
    //this.viewer.peerConnectionStatsInterval = setInterval(() => this.viewer.peerConnection.getStats().then(onStatsReport), 1000);

    this.viewer2.signalingClient2.on('open', async () => {
      console.log('[VIEWER2] Connected to signaling service');

      // Get a stream from the webcam, add it to the peer connection, and display it in the local view.
      // If no video/audio needed, no need to request for the sources.
      // Otherwise, the browser will throw an error saying that either video or audio has to be enabled.
      if (formValues2.sendVideo || formValues2.sendAudio) {
        try {
          this.viewer2.localStream = await navigator.mediaDevices.getUserMedia(constraints);
          this.viewer2.localStream.getTracks().forEach(track => this.viewer2.peerConnection2.addTrack(track, this.viewer2.localStream));
        } catch (e) {
          console.error('[this.viewer2] Could not find webcam');
          return;
        }
      }

      // Create an SDP offer to send to the master
      console.log('[VIEWER2] Creating SDP offer');
      await this.viewer2.peerConnection2.setLocalDescription(
        await this.viewer2.peerConnection2.createOffer({
          offerToReceiveAudio: true,
          offerToReceiveVideo: true,
        }),
      );

      // When trickle ICE is enabled, send the offer now and then send ICE candidates as they are generated. Otherwise wait on the ICE candidates.
      if (formValues2.useTrickleICE) {
        console.log('[VIEWER2] Sending SDP offer');
        this.viewer2.signalingClient2.sendSdpOffer(this.viewer2.peerConnection2.localDescription);
      }
      console.log('[this.viewer] Generating ICE candidates');
    });

    this.viewer2.signalingClient2.on('sdpAnswer', async answer => {
      // Add the SDP answer to the peer connection
      console.log('[this.viewer] Received SDP answer');
      await this.viewer2.peerConnection2.setRemoteDescription(answer);
    });

    this.viewer2.signalingClient2.on('iceCandidate', candidate => {
      // Add the ICE candidate received from the MASTER to the peer connection
      console.log('[this.viewer2] Received ICE candidate');
      this.viewer2.peerConnection2.addIceCandidate(candidate);
    });

    this.viewer2.signalingClient2.on('close', () => {
      // // console.log('[this.viewer] Disconnected from signaling channel');
    });

    this.viewer2.signalingClient2.on('error', error => {
      console.error('[this.viewer] Signaling client error: ', error);
    });

    // Send any ICE candidates to the other peer
    this.viewer2.peerConnection2.addEventListener('icecandidate', ({ candidate }) => {
      if (candidate) {
        console.log('[this.viewer] Generated ICE candidate');

        // When trickle ICE is enabled, send the ICE candidates as they are generated.
        if (formValues2.useTrickleICE) {
          //  // console.log('[this.viewer] Sending ICE candidate');
          this.viewer2.signalingClient2.sendIceCandidate(candidate);
        }
      } else {
        console.log('[this.viewer2] All ICE candidates have been generated');

        // When trickle ICE is disabled, send the offer now that all the ICE candidates have ben generated.
        if (!formValues2.useTrickleICE) {
          //  // console.log('[this.viewer2] Sending SDP offer');
          this.viewer2.signalingClient2.sendSdpOffer(this.viewer2.peerConnection2.localDescription);
        }
      }
    });

    // As remote tracks are received, add them to the remote view
    this.viewer2.peerConnection2.addEventListener('track', event => {
      console.log('[this.viewer] Received remote track');
      if (remoteView2.srcObject) {
        return;
      }
      this.viewer2.remoteStream2 = event.streams[0];
      remoteView2.srcObject = this.viewer2.remoteStream2;
    });

    console.log('[this.viewer] Starting this.viewer connection');
    this.viewer2.signalingClient2.open();
  }






  stopViewer2() {
    console.log('[this.viewer2] Stopping this.viewer connection');
    if (this.viewer2.signalingClient2) {
      this.viewer2.signalingClient2.close();
      this.viewer2.signalingClient2 = null;
    }

    if (this.viewer2.peerConnection2) {
      this.viewer2.peerConnection2.close();
      this.viewer2.peerConnection2 = null;
    }

    if (this.viewer2.localStream) {
      this.viewer2.localStream.getTracks().forEach(track => track.stop());
      this.viewer2.localStream = null;
    }

    if (this.viewer2.remoteStream2) {
      this.viewer2.remoteStream2.getTracks().forEach(track => track.stop());
      this.viewer2.remoteStream2 = null;
    }

    if (this.viewer2.peerConnectionStatsInterval) {
      clearInterval(this.viewer2.peerConnectionStatsInterval);
      this.viewer2.peerConnectionStatsInterval = null;
    }
    if (this.viewer2.remoteView2) {
      this.viewer2.remoteView2.srcObject = null;
    }

    if (this.viewer2.dataChannel) {
      this.viewer2.dataChannel = null;
    }
  }




  sendViewerMessage2(message) {
    if (this.viewer2.dataChannel) {
      try {

        this.viewer2.dataChannel.send(message);
      } catch (e) {
        console.error('[this.viewer] Send DataChannel: ', e.toString());
      }
    }
  }



  /*This file demonstrates the process of starting WebRTC streaming using a KVS Signaling Channel */
  public viewer3: any = {};

  //async startViewer( remoteView, formValues, onStatsReport, onRemoteDataMessage) {
  async startViewer3(remoteView3, formValues3, onRemoteDataMessage) {
    console.log('===> Call startViewer');


    this.viewer3.remoteView3 = remoteView3;


    // Create KVS client
    const kinesisVideoClient = new AWS.KinesisVideo({
      region: formValues3.region,
      accessKeyId: formValues3.accessKeyId,
      secretAccessKey: formValues3.secretAccessKey,
      sessionToken: formValues3.sessionToken,
      endpoint: formValues3.endpoint,
      correctClockSkew: true,
    });

    // Get signaling channel ARN
    const describeSignalingChannelResponse = await kinesisVideoClient
      .describeSignalingChannel({
        ChannelName: formValues3.channelName,
      })
      .promise();
    const channelARN = describeSignalingChannelResponse.ChannelInfo.ChannelARN;
    console.log('[VIEWER] Channel ARN: ', channelARN);

    // Get signaling channel endpoints
    const getSignalingChannelEndpointResponse = await kinesisVideoClient
      .getSignalingChannelEndpoint({
        ChannelARN: channelARN,
        SingleMasterChannelEndpointConfiguration: {
          Protocols: ['WSS', 'HTTPS'],
          Role: KVSWebRTC.Role.VIEWER,
        },
      })
      .promise();
    const endpointsByProtocol = getSignalingChannelEndpointResponse.ResourceEndpointList.reduce((endpoints, endpoint) => {
      endpoints[endpoint.Protocol] = endpoint.ResourceEndpoint;
      return endpoints;
    }, {});
    console.log('[VIEWER] Endpoints: ', endpointsByProtocol);

    const kinesisVideoSignalingChannelsClient = new AWS.KinesisVideoSignalingChannels({
      region: formValues3.region,
      accessKeyId: formValues3.accessKeyId,
      secretAccessKey: formValues3.secretAccessKey,
      sessionToken: formValues3.sessionToken,
      endpoint: endpointsByProtocol.HTTPS,
      correctClockSkew: true,
    });

    // Get ICE server configuration
    const getIceServerConfigResponse = await kinesisVideoSignalingChannelsClient
      .getIceServerConfig({
        ChannelARN: channelARN,
      })
      .promise();
    const iceServers = [];
    var iceTransportPolicy;

    if (!formValues3.natTraversalDisabled && !formValues3.forceTURN) {
      iceServers.push({ urls: `stun:stun.kinesisvideo.${formValues3.region}.amazonaws.com:443` });
    }
    if (!formValues3.natTraversalDisabled) {
      getIceServerConfigResponse.IceServerList.forEach(iceServer =>
        iceServers.push({
          urls: iceServer.Uris,
          username: iceServer.Username,
          credential: iceServer.Password,
        }),
      );
    }
    // // console.log('[VIEWER3] ICE servers: ', iceServers);

    // Create Signaling Client
    this.viewer3.signalingClient3 = new KVSWebRTC.SignalingClient({
      channelARN,
      channelEndpoint: endpointsByProtocol.WSS,
      clientId: formValues3.clientId,
      role: KVSWebRTC.Role.VIEWER,
      region: formValues3.region,
      credentials: {
        accessKeyId: formValues3.accessKeyId,
        secretAccessKey: formValues3.secretAccessKey,
        sessionToken: formValues3.sessionToken,
      },
      systemClockOffset: kinesisVideoClient.config.systemClockOffset,
    });

    const resolution = formValues3.widescreen ? { width: { ideal: 1280 }, height: { ideal: 720 } } : { width: { ideal: 640 }, height: { ideal: 480 } };
    const constraints = {
      video: formValues3.sendVideo ? resolution : false,
      audio: formValues3.sendAudio,
    };
    iceTransportPolicy = 'all'
    const configuration = {
      iceServers,
      iceTransportPolicy: iceTransportPolicy,
    };
    this.viewer3.peerConnection3 = new RTCPeerConnection(configuration);
    if (formValues3.openDataChannel) {
      this.viewer3.dataChannel = this.viewer3.peerConnection3.createDataChannel('kvsDataChannel');
      this.viewer3.peerConnection3.ondatachannel = event => {
        event.channel.onmessage = onRemoteDataMessage;
      };
    }

    // Poll for connection stats
    //this.viewer.peerConnectionStatsInterval = setInterval(() => this.viewer.peerConnection.getStats().then(onStatsReport), 1000);

    this.viewer3.signalingClient3.on('open', async () => {
      console.log('[VIEWER3] Connected to signaling service');

      // Get a stream from the webcam, add it to the peer connection, and display it in the local view.
      // If no video/audio needed, no need to request for the sources.
      // Otherwise, the browser will throw an error saying that either video or audio has to be enabled.
      if (formValues3.sendVideo || formValues3.sendAudio) {
        try {
          this.viewer3.localStream = await navigator.mediaDevices.getUserMedia(constraints);
          this.viewer3.localStream.getTracks().forEach(track => this.viewer3.peerConnection3.addTrack(track, this.viewer3.localStream));
        } catch (e) {
          console.error('[this.viewer3] Could not find webcam');
          return;
        }
      }

      // Create an SDP offer to send to the master
      console.log('[VIEWER3] Creating SDP offer');
      await this.viewer3.peerConnection3.setLocalDescription(
        await this.viewer3.peerConnection3.createOffer({
          offerToReceiveAudio: true,
          offerToReceiveVideo: true,
        }),
      );

      // When trickle ICE is enabled, send the offer now and then send ICE candidates as they are generated. Otherwise wait on the ICE candidates.
      if (formValues3.useTrickleICE) {
        /// // console.log('[VIEWER3] Sending SDP offer');
        this.viewer3.signalingClient3.sendSdpOffer(this.viewer3.peerConnection3.localDescription);
      }
      // // console.log('[this.viewer] Generating ICE candidates');
    });

    this.viewer3.signalingClient3.on('sdpAnswer', async answer => {
      // Add the SDP answer to the peer connection
      // // console.log('[this.viewer] Received SDP answer');
      await this.viewer3.peerConnection3.setRemoteDescription(answer);
    });

    this.viewer3.signalingClient3.on('iceCandidate', candidate => {
      // Add the ICE candidate received from the MASTER to the peer connection
      //  // console.log('[this.viewer3] Received ICE candidate');
      this.viewer3.peerConnection3.addIceCandidate(candidate);
    });

    this.viewer3.signalingClient3.on('close', () => {
      // // console.log('[this.viewer] Disconnected from signaling channel');
    });

    this.viewer3.signalingClient3.on('error', error => {
      console.error('[this.viewer] Signaling client error: ', error);
    });

    // Send any ICE candidates to the other peer
    this.viewer3.peerConnection3.addEventListener('icecandidate', ({ candidate }) => {
      if (candidate) {
        console.log('[this.viewer] Generated ICE candidate');

        // When trickle ICE is enabled, send the ICE candidates as they are generated.
        if (formValues3.useTrickleICE) {
          // // console.log('[this.viewer] Sending ICE candidate');
          this.viewer3.signalingClient3.sendIceCandidate(candidate);
        }
      } else {
        // // console.log('[this.viewer3] All ICE candidates have been generated');

        // When trickle ICE is disabled, send the offer now that all the ICE candidates have ben generated.
        if (!formValues3.useTrickleICE) {
          //  // console.log('[this.viewer3] Sending SDP offer');
          this.viewer3.signalingClient3.sendSdpOffer(this.viewer3.peerConnection3.localDescription);
        }
      }
    });

    // As remote tracks are received, add them to the remote view
    this.viewer3.peerConnection3.addEventListener('track', event => {
      // // console.log('[this.viewer] Received remote track');
      if (remoteView3.srcObject) {
        return;
      }
      this.viewer3.remoteStream3 = event.streams[0];
      remoteView3.srcObject = this.viewer3.remoteStream3;
    });

    console.log('[this.viewer] Starting this.viewer connection');
    this.viewer3.signalingClient3.open();
  }




  stopViewer3() {
    // // console.log('[this.viewer3] Stopping this.viewer connection');
    if (this.viewer3.signalingClient3) {
      this.viewer3.signalingClient3.close();
      this.viewer3.signalingClient3 = null;
    }

    if (this.viewer3.peerConnection3) {
      this.viewer3.peerConnection3.close();
      this.viewer3.peerConnection3 = null;
    }

    if (this.viewer3.localStream) {
      this.viewer3.localStream.getTracks().forEach(track => track.stop());
      this.viewer3.localStream = null;
    }

    if (this.viewer3.remoteStream3) {
      this.viewer3.remoteStream3.getTracks().forEach(track => track.stop());
      this.viewer3.remoteStream3 = null;
    }

    if (this.viewer3.peerConnectionStatsInterval) {
      clearInterval(this.viewer3.peerConnectionStatsInterval);
      this.viewer3.peerConnectionStatsInterval = null;
    }
    if (this.viewer3.remoteView3) {
      this.viewer3.remoteView3.srcObject = null;
    }

    if (this.viewer3.dataChannel) {
      this.viewer3.dataChannel = null;
    }
  }




  sendViewerMessage3(message) {
    if (this.viewer3.dataChannel) {
      try {

        this.viewer3.dataChannel.send(message);
      } catch (e) {
        console.error('[this.viewer] Send DataChannel: ', e.toString());
      }
    }
  }

  setSSH() {
    var body = {
      "robotId": this.RobotId,
      "sessionStatus": this.sshStatus
    }
    this.ottonomyservice.setSshSessionByRobotId(body).then(s => {
      if (s["status"] == 200) {
        if (this.sshStatus == "CLOSE") {
          this.ottonomyservice.sendMessage("SSH Session Closed");
          setTimeout(() => {
            this.ottonomyservice.sendMessage("");
          }, 3000);
        }
        else {
          var endpoint = "ROBOT SSH ENDPOINT: " + s["robotSshEndpoint"];
          this.ottonomyservice.sendMessage(endpoint);
        }
        this.sshStatus = (this.sshStatus == "OPEN" ? "CLOSE" : "OPEN");
      }
      this.alertService.error(s['message']);
    })

  }

  sendHome(){
    var body = {
      "robotId" : this.RobotId
    }
    this.ottonomyservice.sendHome(body).then(s =>{
    })
    
  }
  

  confirmFunc(){
    if(this.butType == "HOME") this.sendHome();
    else if(this.butType == "MOTOR") this.motorsStatusChange();
    else if(this.butType == "SSH") this.setSSH();
    else if(this.butType == "CLEARCOMMANDS") this.clearCommands();
    this.closeModal();
  }

  confirmBox(butType){
    if(butType == "HOME") this.actionName = "SEND ROBOT HOME";
    else if(butType == "MOTOR") this.actionName = this.motorStatus.split("_")[0] + " THE " + this.motorStatus.split("_")[1];
    else if(butType == "SSH") this.actionName = this.sshStatus + " SSH";
    else if(butType == "CLEARCOMMANDS") this.actionName = "CLEAR COMMANDS";
    this.modalShow = "CONFIRM";
    this.butType = butType;
  }

  changeWanderStatus(e){
    this.wanderStatus = this.wanderStatus == "true" ? "false"  : "true";
    var body = {
      "robotId" : this.RobotId,
      "wanderStatus" : this.wanderStatus
    }
    this.ottonomyservice.changeWanderStatus(body).then( s=> {
      this.alertService.error(s['message']);
    });
  }
  
  changeTestingStatus(e){
    this.testingStatus = this.testingStatus == "true" ? "false"  : "true";
    console.log(this.testingStatus);
    var body = {
      "robotId" : this.RobotId
    }
    this.ottonomyservice.changeTestingStatus(body).then(s => {
      console.log(s);
      this.alertService.error(s['message']);
    })
  }



}

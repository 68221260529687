import { Component, OnInit, OnDestroy } from '@angular/core';
import { OttonomyService } from '../services/ottonomy.service';

declare var OneSignal: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit, OnDestroy {
  fleetIdFromNavbar : any;
  interval:any;
  userDetail;
  userRole:string;
  playerId : any;
  userId: any;
  constructor(public ottonomyservice: OttonomyService) { }

  ngOnInit(): void {
    this.userDetail = JSON.parse(sessionStorage.getItem('user'));
    this.userRole = this.userDetail.role[0];
    this.userId = this.userDetail.userId;
    let that = this;
    OneSignal.push(function() {
      OneSignal.init({
        appId: "8603c348-1c81-4e31-9778-52244c6bf3dd",
      });
      OneSignal.sendTag("role", that.userRole); 
    });

    OneSignal.push(function() {
      OneSignal.getUserId(function(userId) {
        that.playerId = userId;
        that.saveWebIdentifier();
      });
    });

    this.interval = setInterval(() => {
      this.getRobotStatus();
      this.getTotalOrders();
      this.getTodayOrders();
      this.getTotalDistance();
    },3000)
  }

  ngOnDestroy(){
    clearInterval(this.interval);
  }

  fleetIdValue($event) { 
    this.fleetIdFromNavbar = $event; 
    this.getRobotStatus();
    this.getTotalOrders();
    this.getTodayOrders();
    this.getTotalDistance();
    this.availableRobotList = []
    this.chargingRobotList = []
  }


  RobotStatusData;
  availableRobotList;
  chargingRobotList;
  availableRobotLength = 0;
  chargingRobotLength = 0;

  saveWebIdentifier() {    
    var body = {
      "userId": this.userId,
      "webappIdentifier" : this.playerId
    }    
    this.ottonomyservice.saveWebIdentifier(body).then(s => {
      // Saving player-id of web in DB
    })
  }

  getRobotStatus() {
    if(this.fleetIdFromNavbar != undefined){
      var body = {
        "fleetId": this.fleetIdFromNavbar,
      }
      
      this.ottonomyservice.getRobotData(body).then(s => {
        if(s['status'] == 200){
          this.RobotStatusData = s['data'];
          this.chargingRobotList = [];
          this.availableRobotList = [];
          this.availableRobotLength = 0;
          this.chargingRobotLength = 0;
          this.RobotStatusData.map((value) => {
            if(value[0].robotStatus == "AVAILABLE"){
              this.availableRobotList.push(value);
              this.availableRobotLength = this.availableRobotList.length;
            }
            if(value[0].robotStatus == "CHARGING"){
              this.chargingRobotList.push(value);
              this.chargingRobotLength = this.chargingRobotList.length;
            }
          })
        }
      })
    }
  }

  totalOrdersValue = 0;
  getTotalOrders(){
    if(this.fleetIdFromNavbar != undefined){
      var body = {
        "fleetId": this.fleetIdFromNavbar,
      }
      this.ottonomyservice.totalOrders(body).then(s => {
          if(s['status'] == 200){
            this.totalOrdersValue = s['totalOrdersCompleted']
          }
      })
    }
  }

  todayOrdersValue = 0;
  getTodayOrders(){
    if(this.fleetIdFromNavbar != undefined){
      var body = {
        "fleetId": this.fleetIdFromNavbar,
      }
      this.ottonomyservice.ordersCompletedToday(body).then(s => {
          if(s['status'] == 200){
            this.todayOrdersValue = s['ordersCompletedToday']
          }
      })
    }
  }

  totalDistanceTravelled = 0;
  getTotalDistance() {
    if(this.fleetIdFromNavbar != undefined){
    var body = {
        "fleetId": this.fleetIdFromNavbar,
      }
      this.ottonomyservice.totalDistanceTravelled(body).then(s => {
          if(s['status'] == 200){
            this.totalDistanceTravelled = s['totalDistanceTravelled']
          }
      })
    }
  }

}

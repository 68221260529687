<div class="title_box">
    <h3>Media <input type="text" name="search" placeholder="Search Videos"></h3>
    <button class="upload_btn">Add Videos<input type="file"></button>
</div>

<div class="table-responsive">
    <table class="table table-bordered">
        <thead>
            <tr>
                <th>S.No.</th>
                <th>Video Name</th>
                <th>Video Size</th>
                <th>Assigned To</th>
                <th>Actions</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>1.</td>
                <td>Video1.mp4</td>
                <td>100MB</td>
                <td>ROBOT-148, ROBOT-150<span class="number">+4</span></td>
                <td>
                    <div class="icon_box">
                        <button (click)="openModal('videoAssign')">Assign</button>
                        <img src="../../assets/images/play.svg" alt="Play Video" class="img-fluid">
                        <img src="../../assets/images/delete.svg" alt="Delete" class="img-fluid" (click)="openModal('delete')">
                    </div>
                </td>
            </tr>
            <tr>
                <td>2.</td>
                <td>Video2.mp4</td>
                <td>146MB</td>
                <td>ROBOT-146</td>
                <td>
                    <div class="icon_box">
                        <button (click)="openModal('videoAssign')">Assign</button>
                        <img src="../../assets/images/play.svg" alt="Play Video" class="img-fluid">
                        <img src="../../assets/images/delete.svg" alt="Delete" class="img-fluid" (click)="openModal('delete')">
                    </div>
                </td>
            </tr>
            <tr>
                <td>3.</td>
                <td>Video3.mp4</td>
                <td>250MB</td>
                <td>ROBOT-147</td>
                <td>
                    <div class="icon_box">
                        <button (click)="openModal('videoAssign')">Assign</button>
                        <img src="../../assets/images/play.svg" alt="Play Video" class="img-fluid">
                        <img src="../../assets/images/delete.svg" alt="Delete" class="img-fluid" (click)="openModal('delete')">
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<div class="custom_modal_container video_assign_modal" [class.show]="modalShow == 'videoAssign'">
    <div class="backdrop" (click)="closeModal()"></div>
    <div class="custom_modal_box">
        <h3>Video assigned to</h3>
        <div class="table-responsive">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th>S.No.</th>
                        <th>Robot ID</th>
                        <th>Screen</th>
                        <th>Loop</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>1.</td>
                        <td>ROBOT-148</td>
                        <td>Front</td>
                        <td>Infinite</td>
                        <td>
                            <div class="icon_box">
                                <img src="../../assets/images/edit.svg" alt="Edit" class="img-fluid" (click)="openModal('robotAssign')">
                                <img src="../../assets/images/delete.svg" alt="Delete" class="img-fluid" (click)="openModal('delete')">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>2.</td>
                        <td>ROBOT-149</td>
                        <td>Front, Left, Right</td>
                        <td>Infinite</td>
                        <td>
                            <div class="icon_box">
                                <img src="../../assets/images/edit.svg" alt="Edit" class="img-fluid" (click)="openModal('robotAssign')">
                                <img src="../../assets/images/delete.svg" alt="Delete" class="img-fluid" (click)="openModal('delete')">
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>3.</td>
                        <td>ROBOT-150</td>
                        <td>Front, Left, Right</td>
                        <td>10</td>
                        <td>
                            <div class="icon_box">
                                <img src="../../assets/images/edit.svg" alt="Edit" class="img-fluid" (click)="openModal('robotAssign')">
                                <img src="../../assets/images/delete.svg" alt="Delete" class="img-fluid" (click)="openModal('delete')">
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<div class="custom_modal_container video_assign_modal" [class.show]="modalShow == 'robotAssign'">
    <div class="backdrop" (click)="closeModal()"></div>
    <div class="custom_modal_box">
        <h3>Assigned Robot</h3>
        <div class="table-responsive pb-50">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th>
                            <label class="custom-checkbox">
                                <input type="checkbox" checked="checked">
                                <span class="checkmark"></span>
                            </label>
                        </th>
                        <th>S.No.</th>
                        <th>Robot ID</th>
                        <th>Front Screen</th>
                        <th>Left Screen</th>
                        <th class="text-left">Right Screen</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <label class="custom-checkbox">
                                <input type="checkbox" checked="checked">
                                <span class="checkmark"></span>
                            </label>
                        </td>
                        <td>1.</td>
                        <td>ROBOT-148</td>
                        <td>
                            <label class="custom-checkbox">
                                <input type="checkbox" checked="checked">
                                <span class="checkmark"></span>
                            </label>
                        </td>
                        <td>
                            <label class="custom-checkbox">
                                <input type="checkbox" checked="checked">
                                <span class="checkmark"></span>
                            </label>
                        </td>
                        <td>
                            <label class="custom-checkbox">
                                <input type="checkbox" checked="checked">
                                <span class="checkmark"></span>
                            </label>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label class="custom-checkbox">
                                <input type="checkbox" checked="checked">
                                <span class="checkmark"></span>
                            </label>
                        </td>
                        <td>2.</td>
                        <td>ROBOT-149</td>
                        <td>
                            <label class="custom-checkbox">
                                <input type="checkbox" checked="checked">
                                <span class="checkmark"></span>
                            </label>
                        </td>
                        <td>
                            <label class="custom-checkbox">
                                <input type="checkbox" checked="checked">
                                <span class="checkmark"></span>
                            </label>
                        </td>
                        <td>
                            <label class="custom-checkbox">
                                <input type="checkbox" checked="checked">
                                <span class="checkmark"></span>
                            </label>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <label class="custom-checkbox">
                                <input type="checkbox" checked="checked">
                                <span class="checkmark"></span>
                            </label>
                        </td>
                        <td>3.</td>
                        <td>ROBOT-150</td>
                        <td>
                            <label class="custom-checkbox">
                                <input type="checkbox" checked="checked">
                                <span class="checkmark"></span>
                            </label>
                        </td>
                        <td>
                            <label class="custom-checkbox">
                                <input type="checkbox" checked="checked">
                                <span class="checkmark"></span>
                            </label>
                        </td>
                        <td>
                            <label class="custom-checkbox">
                                <input type="checkbox" checked="checked">
                                <span class="checkmark"></span>
                            </label>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="btn_container">
            <button class="cancel" (click)="closeModal()">Cancel</button>
            <button class="add">Assign</button>
        </div>
    </div>
</div>

<div class="custom_modal_container confirmation_modal" [class.show]="modalShow == 'delete'">
    <div class="backdrop" (click)="closeModal()"></div>
    <div class="custom_modal_box">
        <p>Are you sure?</p>
        <div class="btn_container">
            <button class="cancel" (click)="closeModal()">Cancel</button>
            <button class="add">Delete</button>
        </div>
    </div>
</div>
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OttonomyService } from '../services/ottonomy.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  passwordValue = true;
  loginForm: FormGroup;
  // otpForm: FormGroup;
  forgotForm: FormGroup;
  submitted = false;
  public emailData
  public passwordData
  // public OTP
  forgotTitle = true;
  errorMsg = false;

  constructor(private router: Router, public ottonomyservice: OttonomyService,private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      emailData: ['', [Validators.required, Validators.email]],
      passwordData: ['', Validators.required]
    });
    // this.otpForm = this.formBuilder.group({
    //   OTP: ['', [Validators.required,Validators.minLength(6)]]
    // });
    this.forgotForm = this.formBuilder.group({
      forgotEmail: ['', [Validators.required, Validators.email]]
    });
  }

  get f() { return this.loginForm.controls; }
  // get o() { return this.otpForm.controls; }
  get fr() { return this.forgotForm.controls; }

  login_box = true;
  // auth_box = false;
  forgot_box = false;
  login() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.emailData = this.loginForm.get('emailData').value;
    var body = {
      emailId: this.loginForm.get('emailData').value, 
      password: this.loginForm.get('passwordData').value, 
    };
    this.ottonomyservice.login(body).then(s => {
      if(s['status'] == 200) {
        //// console.log('session :', JSON.stringify(s['data']))
        sessionStorage.setItem('token', s['data'].token);
        sessionStorage.setItem('user', JSON.stringify(s['data']));
        this.submitted = false;
        this.loginForm.reset();
        // this.login_box = false;
        // this.auth_box = true;
        this.router.navigateByUrl('/dashboard');
      }else{
        // this.errorMsg = true;
        alert('fail')
        return;
      }
    })
  }

  // otp() {
  //   this.submitted = true;
  //   if (this.otpForm.invalid) {
  //       return;
  //   }
  //   var body = {
  //     emailId: this.emailData, 
  //     otp: this.otpForm.get('OTP').value, 
  //   };
  //   this.ottonomyservice.otpVerify(body).then(s => {
  //     if(s['status'] == 200){
  //       this.submitted = false;
  //       this.otpForm.reset();
  //       this.router.navigateByUrl('/dashboard');
  //     }else{
  //       // alert("fail")
  //       return;
  //     }
  //   })
  // }

  forgotBox(){
    this.login_box = false;
    this.forgot_box = true;
  }
  backForgot(){
    this.login_box = true;
    this.forgot_box = false;
  }

  forgot() {
    this.submitted = true;
    if (this.forgotForm.invalid) {
      return;
    }
    var body = {
      emailId: this.forgotForm.get('forgotEmail').value, 
    };
    this.ottonomyservice.forgotPassword(body).then(s => {
      if(s['status'] == 200) {
        this.login_box = true;
        this.submitted = false;
        this.forgotForm.reset();
        this.forgot_box = false;
      }
      else {
        // alert("fail")
        return;
      }
    })
  }
}

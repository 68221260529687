<app-admin-navbar></app-admin-navbar>

<div class="title_box">
    <h3>Users</h3>
    <button (click)="openModal('addUser')">Add user</button>
</div>

<div class="table-responsive">
    <table class="table table-bordered">
        <thead>
            <tr>
                <th>S.No.</th>
                <th>Role</th>
                <th>Name</th>
                <th>Email Address</th>
                <th>Pin</th>
                <th>Contact Number</th>
                <th colspan="2">Actions</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let user of UsersList;let i = index">
                <td>{{i+1}}</td>
                <td>{{user.role[0]}}</td>
                <td>{{user.firstName}}</td>
                <td>{{user.emailId}}</td>
                <td>{{user.pin}}</td>
                <td>{{user.contactNumber}}</td>
                <td class="img">
                    <img src="../../assets/images/edit.svg" alt="Edit" class="img-fluid" (click)="openEditModel('editUser',user.userId,user.userName,user.firstName,user.lastName,user.emailId,user.password,user.contactNumber,user.fleetId,user.otp,user.role,user.imageUrl,user.pin)">
                </td>
                <td class="img">
                    <img src="../../assets/images/delete.svg" alt="Delete" class="img-fluid" (click)="openDeleteModal('delete',user.userId)">
                </td>
            </tr>            
        </tbody>
    </table>
</div>

<!-- Add User -->
<div class="custom_modal_container" [class.show]="modalShow == 'addUser'">
    <div class="backdrop" (click)="closeModal()"></div>
    <div class="custom_modal_box">
        <h3>Add User</h3>
        <form [formGroup]="addUserForm" (ngSubmit)="AddUser()">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="uname">User Name</label>
                        <input type="text" name="" placeholder="User Name" formControlName="uname" [ngClass]="{ 'is-invalid' : submitted && f.uname.errors }">
                        <div class="invalid-feedback" *ngIf="submitted && f.uname.errors">
                            <div *ngIf="f.uname.errors.required">Please enter your user name.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="fname">First Name</label>
                        <input type="text" name="" placeholder="First Name" formControlName="fname" [ngClass]="{ 'is-invalid' : submitted && f.fname.errors }">
                        <div class="invalid-feedback" *ngIf="submitted && f.fname.errors">
                            <div *ngIf="f.fname.errors.required">Please enter your first name.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="lname">Last Name</label>
                        <input type="text" name="" placeholder="Last Name" formControlName="lname" [ngClass]="{ 'is-invalid' : submitted && f.lname.errors }">
                        <div class="invalid-feedback" *ngIf="submitted && f.lname.errors">
                            <div *ngIf="f.lname.errors.required">Please enter your last name.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="email">Email Address</label>
                        <input type="email" name="" placeholder="Email Address" formControlName="email" [ngClass]="{ 'is-invalid' : submitted && f.email.errors }">
                        <div class="invalid-feedback" *ngIf="submitted && f.email.errors">
                            <div *ngIf="f.email.errors.required">Please enter your email id.</div>
                            <div *ngIf="f.email.errors.email">Please enter your valid email id.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group password">
                        <label for="pwd">Password</label>
                        <input [type]="passwordValue ? 'password' : 'text'" name="" placeholder="Password" formControlName="pwd" [ngClass]="{ 'is-invalid' : submitted && f.pwd.errors }">

                        <svg (click)="passwordValue = !passwordValue" *ngIf="passwordValue" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"/><circle cx="12" cy="12" r="3"/></svg>

                        <svg (click)="passwordValue = !passwordValue" *ngIf="!passwordValue" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye-off"><path d="M17.94 17.94A10.07 10.07 0 0112 20c-7 0-11-8-11-8a18.45 18.45 0 015.06-5.94M9.9 4.24A9.12 9.12 0 0112 4c7 0 11 8 11 8a18.5 18.5 0 01-2.16 3.19m-6.72-1.07a3 3 0 11-4.24-4.24M1 1l22 22"/></svg>
                        
                        <div class="invalid-feedback" *ngIf="submitted && f.pwd.errors">
                            <div *ngIf="f.pwd.errors.required">Please enter your password.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="role">Role</label>
                        <select name="" placeholder="Role" formControlName="role" [ngClass]="{ 'is-invalid' : submitted && f.role.errors }">
                            <option value="user">User</option>
                            <option value="developer">Developer</option>
                            <option value="onsite-admin">On-site Admin</option>
                        </select>
                        <div *ngIf="submitted && f.role.errors" class="invalid-feedback">
                            <div *ngIf="f.role.errors.required">Please enter role.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="role">Fleet Name</label>
                        <ng-select multiple="true" formControlName="fleet">
                            <ng-option *ngFor="let fleet of FleetStatusData" [value]="fleet._id">{{fleet.fleetName}}</ng-option>
                        </ng-select>
                        <div *ngIf="submitted && f.fleet.errors" class="invalid-feedback">
                            <div *ngIf="f.fleet.errors.required">Please select fleet.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="number">Contact Number</label>
                        <input type="tel" name="" id="number" placeholder="Contact Number" formControlName="number" [ngClass]="{ 'is-invalid' : submitted && f.number.errors }">
                        <div class="invalid-feedback" *ngIf="submitted && f.number.errors">
                            <div *ngIf="f.number.errors.required">Please enter your mobile number.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="number">Pin</label>
                        <input type="text" name="pin" id="pin" placeholder="Pin" formControlName="pin" [ngClass]="{ 'is-invalid' : submitted && f.pin.errors }">
                        <div class="invalid-feedback" *ngIf="submitted && f.pin.errors">
                            <div *ngIf="f.pin.errors.required">Please enter your pin.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        
                        <!-- <label for="image">Upload Image</label>
                        <div class="upload_box">
                            <button type="button">
                                <input type="file" name="" placeholder="Upload Image" formControlName="logo" [ngClass]="{ 'is-invalid' : submitted && f.logo.errors }" (change)="uploadProfileImage($event)">
                            </button>
                        </div> -->
                        <div *ngIf="submitted && f.logo.errors" class="invalid-feedback">
                            <div *ngIf="f.logo.errors.required">Please upload Image.</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="btn_container">
                <button class="cancel" type="button" (click)="closeModal()">Cancel</button>
                <button class="add" type="submit">Add</button>
            </div>
        </form>
    </div>
</div>

<!-- Edit User-->
<div class="custom_modal_container" [class.show]="modalShow == 'editUser'">
    <div class="backdrop" (click)="closeModal()"></div>
    <div class="custom_modal_box">
        <h3>Edit User</h3>
        <form [formGroup]="addUserForm" (ngSubmit)="EditUser()">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="uname">User Name</label>
                        <input type="text" name="" placeholder="User Name" formControlName="uname" [ngClass]="{ 'is-invalid' : submitted && f.uname.errors }">
                        <div class="invalid-feedback" *ngIf="submitted && f.uname.errors">
                            <div *ngIf="f.uname.errors.required">Please enter your user name.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="fname">First Name</label>
                        <input type="text" name="" placeholder="First Name" formControlName="fname" [ngClass]="{ 'is-invalid' : submitted && f.fname.errors }">
                        <div class="invalid-feedback" *ngIf="submitted && f.fname.errors">
                            <div *ngIf="f.fname.errors.required">Please enter your first name.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="lname">Last Name</label>
                        <input type="text" name="" placeholder="Last Name" formControlName="lname" [ngClass]="{ 'is-invalid' : submitted && f.lname.errors }">
                        <div class="invalid-feedback" *ngIf="submitted && f.lname.errors">
                            <div *ngIf="f.lname.errors.required">Please enter your last name.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="email">Email Address</label>
                        <input type="email" name="" placeholder="Email Address" formControlName="email" [ngClass]="{ 'is-invalid' : submitted && f.email.errors }">
                        <div class="invalid-feedback" *ngIf="submitted && f.email.errors">
                            <div *ngIf="f.email.errors.required">Please enter your email id.</div>
                            <div *ngIf="f.email.errors.email">Please enter your valid email id.</div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-md-6">
                    <div class="form-group password">
                        <label for="pwd">Password</label>
                        <input [type]="passwordValue ? 'password' : 'text'" name="" placeholder="Password" formControlName="pin" [ngClass]="{ 'is-invalid' : submitted && f.pin.errors }">
                        <svg (click)="passwordValue = !passwordValue" *ngIf="passwordValue" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"/><circle cx="12" cy="12" r="3"/></svg>

                        <svg (click)="passwordValue = !passwordValue" *ngIf="!passwordValue" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye-off"><path d="M17.94 17.94A10.07 10.07 0 0112 20c-7 0-11-8-11-8a18.45 18.45 0 015.06-5.94M9.9 4.24A9.12 9.12 0 0112 4c7 0 11 8 11 8a18.5 18.5 0 01-2.16 3.19m-6.72-1.07a3 3 0 11-4.24-4.24M1 1l22 22"/></svg>
                        <div class="invalid-feedback" *ngIf="submitted && f.pin.errors">
                            <div *ngIf="f.pwd.errors.required">Please enter your password.</div>
                        </div>
                    </div>
                </div> -->
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="role">Role</label>
                        <select name="" placeholder="Role" formControlName="role" [ngClass]="{ 'is-invalid' : submitted && f.role.errors }">
                            <option value="user">User</option>
                            <option value="developer">Developer</option>
                            <option value="onsite-admin">On-site Admin</option>
                        </select>
                        <div *ngIf="submitted && f.role.errors" class="invalid-feedback">
                            <div *ngIf="f.role.errors.required">Please enter role.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="role">Fleet Name</label>
                        <ng-select multiple="true" formControlName="fleet">
                            <ng-option *ngFor="let fleet of FleetStatusData" [value]="fleet._id">{{fleet.fleetName}}</ng-option>
                        </ng-select>
                        <div *ngIf="submitted && f.fleet.errors" class="invalid-feedback">
                            <div *ngIf="f.fleet.errors.required">Please select fleet.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="number">Contact Number</label>
                        <input type="tel" name="" id="number" placeholder="Contact Number" formControlName="number" [ngClass]="{ 'is-invalid' : submitted && f.number.errors }">
                        <div class="invalid-feedback" *ngIf="submitted && f.number.errors">
                            <div *ngIf="f.number.errors.required">Please enter your mobile number.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="pin">Pin</label>
                        <input type="pin" name="" placeholder="Pin" formControlName="pin" [ngClass]="{ 'is-invalid' : submitted && f.pin.errors }">
                        <div class="invalid-feedback" *ngIf="submitted && f.pin.errors">
                            <div *ngIf="f.pin.errors.required">Please enter your pin.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <!-- <label for="logo">Upload Logo</label>
                        <input type="text" name="" formControlName="logo" placeholder="Upload Logo" [ngClass]="{ 'is-invalid': submitted && f.logo.errors }">
                        <div *ngIf="submitted && f.logo.errors" class="invalid-feedback">
                            <div *ngIf="f.logo.errors.required">Please enter logo name.</div>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="btn_container">
                <button class="cancel" type="button" (click)="closeModal()">Cancel</button>
                <button class="add" type="submit">Save</button>
            </div>
        </form>
    </div>
</div>

<!-- Delete User-->
<div class="custom_modal_container confirmation_modal" [class.show]="modalShow == 'delete'">
    <div class="backdrop" (click)="closeModal()"></div>
    <div class="custom_modal_box">
        <p>Are you sure?</p>
        <div class="btn_container">
            <button class="cancel" (click)="closeModal()">Cancel</button>
            <button class="add" (click)="deleteUser()">Delete</button>
        </div>
    </div>
</div>
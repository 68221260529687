import { Component, OnInit, ViewChild, OnChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OttonomyService } from '../services/ottonomy.service';
import { AlertService } from '../../app/services/alert.service';
@Component({
  selector: 'app-robot-action-box',
  templateUrl: './robot-action-box.component.html',
  styleUrls: ['./robot-action-box.component.scss']
})
export class RobotActionBoxComponent implements OnInit, OnChanges {

  constructor(private route: ActivatedRoute,public ottonomyservice: OttonomyService, private alertService: AlertService) { }
  currentTime;
  setTime;
  currentActionId : string;
  modalShow : string;
  currentOrderId : string;
  disableBut : string;
  butClicked : boolean = false;

  // for storing commands
  commandList : any = [];
  // to show begin and cancel button at start
  showAction: string = 'begin';

  ngOnInit(): void {
    this.getCommandListRobot();
  }

  // Get command list
  getCommandListRobot() {
    var body = {
      "robotId": this.route.snapshot.params.id,
    }
    this.ottonomyservice.getCommandListRobot(body).then(s => {      
      if(s['status'] == 200){        
        this.commandList = s['robotCommandList'];
        if(this.commandList[0]["actionStatus"] == "0"){
          this.showAction = "begin";
        } 
        else if(this.commandList[0]["actionStatus"] == "1"){
          this.showAction = "finish";
        }


      }
    })
  }

  ngOnChanges(){
     this.getCommandListRobot();
  }

  // Finish Actions by teleoperator : status : 3
  finishAction() {
    this.butClicked = true;
    var body = {
      "robotId": this.route.snapshot.params.id,
      "orderId": this.currentOrderId,
      "actionId": this.currentActionId,
      "actionStatus": "3"
  }
    this.ottonomyservice.completeAction(body).then(s => {
      if(s['status'] == 200){
        this.showAction = 'begin';
        this.getCommandListRobot();
      }
      this.modalShow = '';
      this.butClicked = false;
      this.alertService.error(s['message']);
    })
  }

  // Cancel Actions by teleoperator : status : 
  cancelAction() {
    this.butClicked = true;
    var body = {
      "robotId": this.route.snapshot.params.id,
      "orderId": this.currentOrderId,
      "actionId": this.currentActionId,
      "actionStatus": "2"
    } 
    this.ottonomyservice.completeAction(body).then(s => {
      if(s['status'] == 200){
        this.showAction = 'begin';
        this.getCommandListRobot();
      }
      this.modalShow = '';
      this.butClicked = false;
      this.alertService.error(s['message']);
    })
  }

  // Begin Actions by teleoperator : status : 
  beginAction() {
    this.butClicked = true;
    var body = {
      "robotId": this.route.snapshot.params.id,
      "orderId": this.currentOrderId,
      "actionId": this.currentActionId,
      "actionStatus": "1"
    } 
    this.ottonomyservice.completeAction(body).then(s => {
      if(s['status'] == 200){
        this.showAction = 'finish';
        this.getCommandListRobot();
      }
      this.modalShow = '';
      this.butClicked = false;
      this.alertService.error(s['message']);
    })
  }

  // To close a modal
  closeModal(){
    this.modalShow = "";
  }

  // To open the modal whenever an action is performed by the user
  showPrompt(actionId, orderId, action){
    this.currentActionId = actionId;
    this.currentOrderId = orderId;
    this.modalShow = action; 
  }


}

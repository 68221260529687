import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin-fleet',
  templateUrl: './admin-fleet.component.html',
  styleUrls: ['./admin-fleet.component.scss']
})
export class AdminFleetComponent implements OnInit {
  fleetIdFromNavbar : any;
  pageValueFromNavbar =  'robots';
  constructor(private _router: Router) {
    // this.pageShow = _router.url; 
  }
  
  ngOnInit(): void {
  }

  fleetIdValue($event) {
    this.fleetIdFromNavbar = $event; 
  }
  
  pageValue($event) {
    this.pageValueFromNavbar = $event;
  }
}

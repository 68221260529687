

<div class="action_box">
    <div class="robot_action_alert" >
        <div class="alert-image" *ngIf="commandList.length == 0">
            No Actions to perform
        </div> 

        <div class="alert-image" *ngIf="commandList.length > 0">
         {{ commandList[0]['actionType'] }} 
        </div>       
        
        <br>

        <div class="alert-text" *ngIf="commandList.length > 0">           
           <span>
            Order Id : {{ commandList[0]['orderId'] }} <br>
            <span *ngIf="commandList[0]['locationType'] == 'PICKUP'">
                Pickup Location : {{ commandList[0]['locationName'] }}<br>
            </span>
            <span *ngIf="commandList[0]['locationType'] == 'DROPOFF'">
                Drop Location : {{ commandList[0]['locationName'] }}<br>
            </span>            
            Compartment : {{ commandList[0]['boxId'] }} <br>
           </span> 
            <br>
            <span *ngIf="showAction == 'begin'">
                <button type="button" class="btn btn-success begin_action" (click)="showPrompt(commandList[0]['actionId'], commandList[0]['orderId'], 'begin')">BEGIN ACTION</button>
                <button type="button" class="btn btn-danger cancel_action" (click)="showPrompt(commandList[0]['actionId'], commandList[0]['orderId'], 'cancel')">CANCEL ACTION</button>
            </span>
            <span *ngIf="showAction == 'finish'">
                <button type="button" class="btn btn-success finish_action" (click)="showPrompt(commandList[0]['actionId'], commandList[0]['orderId'], 'finish')">FINISH ACTION</button>
                <button type="button" class="btn btn-danger cancel_action" (click)="showPrompt(commandList[0]['actionId'], commandList[0]['orderId'], 'cancel')">CANCEL ACTION</button>
            </span>
            
        </div>
    </div>
</div>

<div class="custom_modal_container confirmation_modal" [class.show]="modalShow == 'cancel'">
    <div class="backdrop" (click)="closeModal()"></div>
    <div class="custom_modal_box">
        <p>Are you sure, you want to cancel the action {{currentActionId}}?</p>
        <div class="btn_container">
            <button class="cancel" (click)="closeModal()">No</button>
            <button class="add" (click)="cancelAction()" [disabled]="butClicked">Yes</button>
        </div>
    </div>
</div>

<div class="custom_modal_container confirmation_modal" [class.show]="modalShow == 'begin'">
    <div class="backdrop" (click)="closeModal()"></div>
    <div class="custom_modal_box">
        <p>Are you sure, you want to begin the action {{currentActionId}}?</p>
        <div class="btn_container">
            <button class="cancel" (click)="closeModal()">No</button>
            <button class="add" (click)="beginAction()" [disabled]="butClicked">Yes</button>
        </div>
    </div>
</div>

<div class="custom_modal_container confirmation_modal" [class.show]="modalShow == 'finish'">
    <div class="backdrop" (click)="closeModal()"></div>
    <div class="custom_modal_box">
        <p>Are you sure, you want to finish the action {{currentActionId}}?</p>
        <div class="btn_container">
            <button class="cancel" (click)="closeModal()">No</button>
            <button class="add" (click)="finishAction()" [disabled]="butClicked">Yes</button>
        </div>
    </div>
</div>
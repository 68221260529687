import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import * as L from 'leaflet';
import 'leaflet-rotatedmarker';
import { OttonomyService } from '../services/ottonomy.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Console } from 'console';
@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, OnDestroy {
  constructor(private router: Router, public ottonomyservice: OttonomyService) {
    router.events.subscribe(val => {

    });
  }

  interval:any;
  RobotStatusData: any;
  map: any;
  bounds: any;
  image: any;
  xValue: any;
  yValue: any;
  robotIcon: any;
  position: any;
  mark: any;
  markers = [];
  layerGroup: any;
  url;
  floorplan;
  annotation;
  noGoZone;

  overlay;
  robotStatusLength;
  mWidth;
  mHeight;

  floor: any;
  noGoZoneImgs: any;
  annotationImgs: any;

  imageValue;
  noGoZoneImg;
  floorplanImg;
  annotationImg;

  //selected :any;
  ngOnInit(): void {

    this.map = L.map('map', {
      minZoom: 1,
      maxZoom: 20,
      center: [0, 0],
      zoom: 4,
      crs: L.CRS.Simple,
    });

    setTimeout(() => {
      this.getRobotStatus();
      this.getMapStatus();
      this.locationListByFleetId();
    }, 2000)

    this.interval = setInterval(() => {

      this.getRobotStatus();
      if (this.RobotStatusData != undefined) {
        this.robotStatusLength = this.RobotStatusData.length;
        this.markers = [];
        if (this.robotStatusLength > 0) {
          if (this.layerGroup == undefined) {
            this.mapInit();
          } else {
            this.layerGroup.clearLayers();
            this.mapInit()
          }
        }
      }
    }, 3000)
  }

  ngOnDestroy(){
    clearInterval(this.interval);
  }

  zoomIn() {
    this.map.setZoom(this.map.getZoom() + 1)
  }
  zoomOut() {
    this.map.setZoom(this.map.getZoom() - 1)
  }

  floorplanShow() {
    if (this.floor == true) {
      this.floor = false
    }
    else {
      this.floor = true
    }
    this.loadMap(this.baseMapImageUrl, this.floorplanImageUrl, this.annotationImageUrl, this.noGoZoneImageUrl);
  }
  annotationShow() {
    if (this.annotationImgs == true) {
      this.annotationImgs = false
    }
    else {
      this.annotationImgs = true
    }
    this.loadMap(this.baseMapImageUrl, this.floorplanImageUrl, this.annotationImageUrl, this.noGoZoneImageUrl);
  }
  noGoZoneShow() {
    if (this.noGoZoneImgs == true) {
      this.noGoZoneImgs = false
    }
    else {
      this.noGoZoneImgs = true
    }
    this.loadMap(this.baseMapImageUrl, this.floorplanImageUrl, this.annotationImageUrl, this.noGoZoneImageUrl);
  }

  @Input() fleetIdFromNavbar: any[];

  getRobotStatus() {
    if (this.fleetIdFromNavbar != undefined) {
      var body = {
        "fleetId": this.fleetIdFromNavbar,
      }
      this.ottonomyservice.getRobotData(body).then(s => {
        if (s['status'] == 200) {
          this.RobotStatusData = s['data'];
        }
      })
    }
  }

  public locationList

  locationListByFleetId() {
    if (this.fleetIdFromNavbar != undefined) {
      var body = {
        "fleetId": this.fleetIdFromNavbar,
      }
      this.ottonomyservice.getLocationList(body).then(s => {
        if (s['status'] == 200) {
          this.locationList = s['data']
          // Uncomment it to enable location tile
          this.addIcons();

          //// console.log('Location :', this.locationList)
          //for(var j = 0; j<this.locationList; j++) { }

        }
      })
    }
  }




  loadMap(baseMap, floorplan, annotation, noGoZone) {

    // this.map = L.map('map', {
    //   minZoom: 1,
    //   maxZoom: 6,
    //   center: [0, 0],
    //   zoom: 4,
    //   crs: L.CRS.Simple,
    // });

    var w = this.mWidth,
      h = this.mHeight;
    var southWest = this.map.unproject([0, h], this.map.getMaxZoom());
    var northEast = this.map.unproject([w, 0], this.map.getMaxZoom());
    this.bounds = new L.LatLngBounds(southWest, northEast);
    this.imageValue = L.imageOverlay(baseMap, this.bounds);
    this.imageValue.addTo(this.map)

    if (this.floor == true) {
      this.floorplanImg = L.imageOverlay(floorplan, this.bounds);
      this.floorplanImg.addTo(this.map)
    }
    if (this.annotationImgs == true) {
      this.annotationImg = L.imageOverlay(annotation, this.bounds);
      this.annotationImg.addTo(this.map)
    }
    if (this.noGoZoneImgs == true) {
      this.noGoZoneImg = L.imageOverlay(noGoZone, this.bounds);
      this.noGoZoneImg.addTo(this.map)
    }
    this.map.setMaxBounds(this.bounds);

  }

  mapInit() {
    for (var i = 0; i < this.robotStatusLength; i++) {
      if (this.RobotStatusData[i][0].robotStatus === 'AVAILABLE' || this.RobotStatusData[i][0].robotStatus != 'AVAILABLE') {
        this.xValue = this.RobotStatusData[i][0].positionX;
        this.yValue = this.RobotStatusData[i][0].positionY;
        //// console.log('X :', this.xValue, 'Y', this.yValue);  

        let battery = 0.125 * this.RobotStatusData[i][0].robotBattery;
        let internetHide4, internetHide3, internetHide2, internetHide1;
        if (this.RobotStatusData[i][0].activeSimRssi < 75) {
          internetHide4 = "hide";
        }
        if (this.RobotStatusData[i][0].activeSimRssi < 50) {
          internetHide3 = "hide";
        }
        if (this.RobotStatusData[i][0].activeSimRssi < 25) {
          internetHide2 = "hide";
        }
        if (this.RobotStatusData[i][0].activeSimRssi == 0) {
          internetHide1 = "hide";
        }
        const detailString = '<h6 class="title">Robot - '
          + this.RobotStatusData[i][0].robotId
          + '</h6><div class="status_box"><div class="battery_box"> <svg viewBox="0 0 21 11" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect x="1" width="16.9689" height="9.5026" rx="1.9387" stroke="#5DFBA2" stroke-width="1.35" stroke-linecap="square"/> <rect x="3.27771" y="2.33026" width="'
          + battery
          + '" height="4.84208" rx=".169689" fill="#5DFBA2" stroke="#5DFBA2" stroke-width="1.35751" stroke-linecap="square"/> <path d="M20.034 3.16766v3.16725" stroke="#5DFBA2" stroke-width="1.35751" stroke-linecap="round"/> </svg> <p>'
          + this.RobotStatusData[i][0].robotBattery
          + '%</p></div><svg class="cellular" viewBox="0 0 30 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path class="'
          + internetHide4
          + '" d="M28.5 22H25.5C24.6716 22 24 21.3284 24 20.5V1.5C24 0.671573 24.6716 0 25.5 0H28.5C29.3284 0 30 0.671573 30 1.5V20.5C30 21.3284 29.3284 22 28.5 22Z" fill="white"/><path class="'
          + internetHide3
          + '" d="M20.5 22H17.5C16.6716 22 16 21.3284 16 20.5V6.5C16 5.67157 16.6716 5 17.5 5H20.5C21.3284 5 22 5.67157 22 6.5V20.5C22 21.3284 21.3284 22 20.5 22Z" fill="white"/><path class="'
          + internetHide2
          + '" d="M12.5 22H9.5C8.67157 22 8 21.3284 8 20.5V10.5C8 9.67157 8.67157 9 9.5 9H12.5C13.3284 9 14 9.67157 14 10.5V20.5C14 21.3284 13.3284 22 12.5 22Z" fill="white"/><path class="'
          + internetHide1
          + '" d="M4.5 22H1.5C0.671573 22 0 21.3284 0 20.5V14.5C0 13.6716 0.671573 13 1.5 13H4.5C5.32843 13 6 13.6716 6 14.5V20.5C6 21.3284 5.32843 22 4.5 22Z" fill="white"/></svg> </div><p>Action ID -'
          + this.RobotStatusData[i][0].actionId
          + '</p><a href="/#/robot-detail/' + this.RobotStatusData[i][0].robotId + ' "><button><img src="../../assets/images/camera.png">Live Feed</button></a>'

        if(i%2 == 0){
          this.robotIcon = L.icon({
            iconUrl: '../../assets/images/Robot-Track.png',
            iconSize: [40, 40],
            popupAnchor: [0, -10]
        });
        }else{
          this.robotIcon = L.icon({
            iconUrl: '../../assets/images/Robot-Track1.png',
            iconSize: [40, 40],
            popupAnchor: [0, -10]
        });
        }


        //const detailString = '<h6 class="title">Robot - '+this.RobotStatusData[i][0].robotId+'</h6><div class="status_box"><div class="battery_box"> <svg viewBox="0 0 21 11" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect x="1" width="16.9689" height="9.5026" rx="1.9387" stroke="#5DFBA2" stroke-width="1.35" stroke-linecap="square"/> <rect x="3.27771" y="2.33026" width="'+battery+'" height="4.84208" rx=".169689" fill="#5DFBA2" stroke="#5DFBA2" stroke-width="1.35751" stroke-linecap="square"/> <path d="M20.034 3.16766v3.16725" stroke="#5DFBA2" stroke-width="1.35751" stroke-linecap="round"/> </svg> <p>'+this.RobotStatusData[i][0].robotBattery+'%</p></div><svg class="cellular" viewBox="0 0 30 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path class="'+internetHide4+'" d="M28.5 22H25.5C24.6716 22 24 21.3284 24 20.5V1.5C24 0.671573 24.6716 0 25.5 0H28.5C29.3284 0 30 0.671573 30 1.5V20.5C30 21.3284 29.3284 22 28.5 22Z" fill="white"/><path class="'+internetHide3+'" d="M20.5 22H17.5C16.6716 22 16 21.3284 16 20.5V6.5C16 5.67157 16.6716 5 17.5 5H20.5C21.3284 5 22 5.67157 22 6.5V20.5C22 21.3284 21.3284 22 20.5 22Z" fill="white"/><path class="'+internetHide2+'" d="M12.5 22H9.5C8.67157 22 8 21.3284 8 20.5V10.5C8 9.67157 8.67157 9 9.5 9H12.5C13.3284 9 14 9.67157 14 10.5V20.5C14 21.3284 13.3284 22 12.5 22Z" fill="white"/><path class="'+internetHide1+'" d="M4.5 22H1.5C0.671573 22 0 21.3284 0 20.5V14.5C0 13.6716 0.671573 13 1.5 13H4.5C5.32843 13 6 13.6716 6 14.5V20.5C6 21.3284 5.32843 22 4.5 22Z" fill="white"/></svg> </div><p>Action ID - '+this.RobotStatusData[i][0].actionId+'</p><button><img src="../../assets/images/camera.png">Live Feed</button>'
        let detailPopup = new L.Popup({ autoClose: false, closeOnClick: false, autoPan: false }).setContent(detailString).setLatLng(this.map.unproject([this.xValue, this.yValue]));
        this.layerGroup = L.layerGroup(this.markers).addTo(this.map);
        this.mark = L.marker(this.map.unproject([this.xValue, this.yValue], this.map.getMaxZoom()), { icon: this.robotIcon, }).addTo(this.layerGroup).bindPopup(detailPopup);
        //console.log(this.layerGroup)
        this.mark.setRotationAngle(this.RobotStatusData[i][0].positionYaw).setRotationOrigin('center');
        this.markers.push(this.mark)
      }
    }
  }

  ngOnChanges(fleetId) {
    this.markers = [];
    this.getMapStatus();
    this.locationListByFleetId();
  }


  // Function for adding location to the UI
  addIcons() {
    var locationLength = this.locationList.length;
    for (var i = 0; i < locationLength; i++) {
      var icon = this.locationList[i].locationType.toUpperCase() == "PICKUP" ? "pickup.png" : (this.locationList[i].locationType.toUpperCase() == "DROPOFF" ? "dropoff.png" : "home.png");
      var activeState = this.locationList[i].status == "ACTIVE" ? "active" : "unactive";
      var iconSrc = "../../assets/images/" + activeState + "/" + icon;
      var mapIcon = L.icon({
        iconUrl: iconSrc,
        iconSize: [40, 40]
      });
      var layerGroup = L.layerGroup(this.markers).addTo(this.map);
      var corX = this.locationList[i].positionX;
      var corY = this.locationList[i].positionY;
      var name = this.locationList[i].locationName;

      var mark = L.marker(this.map.unproject([corX, corY], this.map.getMaxZoom()), { icon: mapIcon, }).addTo(layerGroup).bindPopup(name);
      this.markers.push(mark)
    }
  }
  navigate() {
    // console.log('--clicl --')
  }

  public MapsList
  baseMapImageUrl = '';
  floorplanImageUrl = '';
  annotationImageUrl = '';
  noGoZoneImageUrl = '';
  mapListLength;

  getMapStatus() {
    try {
      this.map.remove()
      this.map = L.map('map', {
        minZoom: 1,
        maxZoom: 6,
        center: [0, 0],
        zoom: 4,
        crs: L.CRS.Simple,
      });
    }
    catch (e) {
      // console.log('inside catch')
    }

    if (this.fleetIdFromNavbar != undefined) {
      var body = {
        "fleetId": this.fleetIdFromNavbar
      }
      this.ottonomyservice.getMapsList(body).then(s => {
        this.MapsList = s['data'];
        this.baseMapImageUrl = '';
        this.floorplanImageUrl = '';
        this.annotationImageUrl = '';
        this.noGoZoneImageUrl = '';
        if (s['data'] != null) {
          this.baseMapImageUrl = this.MapsList.baseMapImageUrl;
          this.floorplanImageUrl = this.MapsList.floorplanImageUrl;
          this.annotationImageUrl = this.MapsList.annotationImage;
          this.noGoZoneImageUrl = this.MapsList.noGoZoneImageUrl;

          this.url = this.baseMapImageUrl;
          this.floorplan = this.floorplanImageUrl;
          this.annotation = this.annotationImageUrl;
          this.noGoZone = this.noGoZoneImageUrl;

          this.mWidth = this.MapsList.mapWidth;
          this.mHeight = this.MapsList.mapHeight;
          this.loadMap(this.baseMapImageUrl, this.floorplanImageUrl, this.annotationImageUrl, this.noGoZoneImageUrl);
          //this.loadMap();
        }
      })
    }
  }
}

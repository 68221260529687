import { Component, OnInit} from '@angular/core';
import { OttonomyService } from '../services/ottonomy.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertService } from '../../app/services/alert.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})

export class AdminComponent implements OnInit {
  addFleetForm: FormGroup;
  submitted = false;
  modalShow;
  userDetail;
  userRole:any;
  roleValue;
  
  constructor(public ottonomyservice: OttonomyService,private formBuilder: FormBuilder,private router: Router, private alertService: AlertService) {}

  adminDetail: any;
  todayDate: Date = new Date();

  openModal(value) {
    this.modalShow = value;
  }

  fleetId:any = '';
  openDeleteModal(value,id) {
    this.modalShow = value;
    this.fleetId = id;
  }
  
  editFleetId:any = '';
  timezone:any = '';
  openEditModal(value,fleetId,fleetName,location,adminName,adminEmail,adminContactNumber,timezone) {
    this.modalShow = value;
    this.editFleetId = fleetId;
    this.timezone = timezone
    this.addFleetForm = this.formBuilder.group({
      fname: [fleetName, Validators.required],
      location: [location, Validators.required],
      name: [adminName, Validators.required],
      email: [adminEmail, [Validators.required, Validators.email]],
      number: [adminContactNumber, [Validators.required, Validators.minLength(10)]],
      todayDate: [timezone, Validators.required]
      //logo: [imageLogoUrl]
    });
  }

  closeModal(){
    this.modalShow = '';
    this.addFleetForm.reset();
  }


  ngOnInit(): void {
    this.userDetail = JSON.parse(sessionStorage.getItem('user'));
    this.userRole = this.userDetail.role[0];
    this.getFleetStatus();
    this.addFleetForm = this.formBuilder.group({
      fname: ['', Validators.required],
      location: ['', Validators.required],
      timezone: [this.todayDate, Validators.required]
      //logo: ['']
    });
  }

  get f() { return this.addFleetForm.controls; }

  
  // Upload location images
  /*
  location
  locationFileName = '';
  locationFileUploadUrl = '';

  uploadFleetMedia(event) {
    if (event.target.files.length > 0) {
      this.location = event.target.files[0];
      this.locationFileName = this.location.name;
    }
    const formData = new FormData();
    formData.append("imageLogoUrl", this.location);
    this.ottonomyservice.uploadFleetMediaImages(formData).then(s => {
      if(s['status'] == 200){
        this.locationFileUploadUrl = s['data'];
      }else{
        return;
      }
    })
  }
  */

  // Add Fleet
  AddFleet() {
    this.submitted = true;
    // if (this.addFleetForm.invalid) {
    //   return;
    // }
    var body = {
      //imageLogoUrl: this.locationFileUploadUrl,
      fleetName: this.addFleetForm.get('fname').value,
      location: this.addFleetForm.get('location').value,
      adminName: this.userDetail.display_name,
      adminEmail: this.userDetail.emailId,
      adminContactNumber: this.userDetail.contactNumber,
      timezone: this.todayDate,
    };
    
    this.ottonomyservice.AddFleet(body).then(s => {
      if(s['status'] == 200){
        this.submitted = false;
        this.addFleetForm.reset();
        this.closeModal()
        this.getFleetStatus();
      }else{
        return;
      }
    })
  };

  // Edit Fleet
  EditFleet() {
    this.submitted = true;
    // if (this.addFleetForm.invalid) {
    //   return;
    // }

    var body = {
      fleetId: this.editFleetId,
      fleetName: this.addFleetForm.get('fname').value,
      location: this.addFleetForm.get('location').value,
      adminName: this.userDetail.display_name,
      adminEmail: this.userDetail.emailId,
      adminContactNumber: this.userDetail.contactNumber,
      timezone: this.timezone,
      //imageLogoUrl: this.addFleetForm.get('logo').value
    };
    // console.log(body);
    
    this.ottonomyservice.editFleet(body).then(s => {
      if(s['status'] == 200){
        this.submitted = false;
        this.addFleetForm.reset();
        // alert(s['message']);
        this.closeModal()
        this.getFleetStatus();
      }else{
        // alert("fail")
        return;
      }
    })
  };

  // DeleteFleet
  deleteFleet(){
    var body = {
      fleetId: this.fleetId,
    };
    this.ottonomyservice.DeleteFleet(body).then(s => {
      if(s['status'] == 200){
        this.closeModal()
        this.getFleetStatus();
      }else{
        return;
      }
    })
  }

  //Get Fleet Data
  FleetStatusData = [];
  
  getFleetStatus() {
    var body = {
      "emailId": this.userDetail.emailId
    }
    if(this.userRole == "onsite-admin"){
      this.ottonomyservice.getFleetListEmail(body).then(s => {
        this.FleetStatusData = s['fleet'];
      })
    }
    if(this.userRole == "admin"){
      this.ottonomyservice.getFleetListByAdminEmailData(body).then(s => {
        this.FleetStatusData = s['fleet']
      })
    }
  }

  fleetDetail(id){
    console.log(id);
    this.router.navigate(['/fleet-detail',id]);
  }

  changeOrderingStatus(no){
    console.log(this.FleetStatusData[no].orderingStatus);
    let nextStatus = this.FleetStatusData[no].orderingStatus == "ACTIVE" ? "INACTIVE" : "ACTIVE"; 
    let body = {
      "fleetId": this.FleetStatusData[no].fleetId,
      "status" : nextStatus
    }
    console.log(body);
    this.ottonomyservice.setOrderingStatus(body).then((s)=>{
      this.getFleetStatus();
      this.alertService.error(s['message']);
      //console.log(s);
    })
  }


}

<div class="nav_container desktop">
    <div class="left_box">
        <h3 class="logo" (click)="navigate('/dashboard')">Ottonomy.IO</h3>

        <!-- Fleet List  -->
        <div class="fleet_dropdown" *ngIf="routerUrl == '/dashboard'">
            <img src="../../assets/images/map.svg" alt="map-icon">
            <h6 *ngIf="fleetList.length == 1">{{fleetList[0].fleetName}}</h6>
            <select *ngIf="fleetList.length > 1" [(ngModel)]='selectedFleet' (change)="selectFleet($event)">
                <option *ngFor="let fleet of fleetList" [value]="fleet.fleetId">{{fleet.fleetName}}</option>
            </select>
        </div>

        <!-- Robot List  -->
        <div class="fleet_dropdown robot-detail-dropdown" *ngIf="robotDetailPage">
            <img src="../../assets/images/logo_icon.svg" alt="logo-icon">
            <h6 *ngIf="robotIdList.length == 1">{{robotIdList[0]}}</h6>
            <select (change)="selectRobot($event)" [(ngModel)]='selectedRobot' *ngIf="robotIdList.length > 1">
                <option *ngFor="let robot of robotIdList" [value]="robot">{{robot}}</option>
            </select>
        </div>
        <p (click)="navigate('/admin')" *ngIf="roleValue">Fleet Admin</p>
        <div class="alert_box">&nbsp;{{messageFromRobot}}</div>
        <!-- <p (click)="navigate('/media')" *ngIf="roleValue">Media Manager</p> -->
        <!-- <p *ngIf="roleValue">Media Manager</p> -->
    </div>
    <div class="right_box">
        <img src="../../assets/images/stop.svg" alt="Stop-icon" class="stop" [class.active]="!fleetStop" (click)="stopFleet()">
        <img src="../../assets/images/stop.svg" alt="Stop-icon" class="release" [class.active]="fleetStop" (click)="releaseFleet()">
        <!-- <div class="notification_box">
            <img src="../../assets/images/notifications.svg" alt="Notification-Icon">
            <div class="dot"></div>
        </div> -->
        <div class="profile_box">

            <!-- <div class="profile_img"><img class="userProfileImg" [src]="imageUrl"></div> -->

            <div class="name_dropdown" (click)="drop = !drop" [class.active]="drop">
                <p>{{userName}}</p>
                <img src="../../assets/images/down-arrow.svg" alt="down-arrow">
            </div>
            <ul class="dropdown" *ngIf="drop">
               <li (click)="logout()">Logout</li>
            </ul>
         </div>
    </div>
</div>

<div class="nav_container mobile">
    <div class="left_box">
        <h3 class="logo" (click)="navigate('/dashboard')"><img src="../../assets/images/logo-icon.png" /></h3>

        <!-- Fleet List  -->
        <div class="fleet_dropdown" *ngIf="routerUrl == '/dashboard'">
            <!-- <h6 *ngIf="fleetList.length == 1">{{fleetList[0].fleetName}}</h6> -->
            <select *ngIf="fleetList.length > 1" [(ngModel)]='selectedFleet' (change)="selectFleet($event)">
                <option *ngFor="let fleet of fleetList" [value]="fleet.fleetId">{{fleet.fleetName}}</option>
            </select>
        </div>

        <!-- Robot List  -->
        <div class="fleet_dropdown robot-detail-dropdown" *ngIf="robotDetailPage">
            <!-- <img src="../../assets/images/logo_icon.svg" alt="logo-icon"> -->
            <h6 *ngIf="robotIdList.length == 1">{{robotIdList[0]}}</h6>
            <select (change)="selectRobot($event)" [(ngModel)]='selectedRobot' *ngIf="robotIdList.length > 1">
                <option *ngFor="let robot of robotIdList" [value]="robot">{{robot}}</option>
            </select>
        </div>
       <!--  <p (click)="navigate('/admin')" *ngIf="roleValue">Fleet Admin</p>
        <div class="alert_box">&nbsp;{{messageFromRobot}}</div> -->
        <!-- <p (click)="navigate('/media')" *ngIf="roleValue">Media Manager</p> -->
        <!-- <p *ngIf="roleValue">Media Manager</p> -->
    </div>
    <div class="right_box">
       <!--  <img src="../../assets/images/stop.svg" alt="Stop-icon" class="stop" [class.active]="!fleetStop" (click)="stopFleet()">
        <img src="../../assets/images/stop.svg" alt="Stop-icon" class="release" [class.active]="fleetStop" (click)="releaseFleet()"> -->
        <!-- <div class="notification_box">
            <img src="../../assets/images/notifications.svg" alt="Notification-Icon">
            <div class="dot"></div>
        </div> -->
        <div class="profile_box">

            <!-- <div class="profile_img"><img class="userProfileImg" [src]="imageUrl"></div> -->

            <div class="name_dropdown" (click)="drop = !drop" [class.active]="drop">
                <p>{{userName}}</p>
                <img src="../../assets/images/down-arrow.svg" alt="down-arrow">
            </div>
            <ul class="dropdown" *ngIf="drop">
               <li (click)="logout()">Logout</li>
            </ul>
         </div>
    </div>
</div>

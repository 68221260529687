<div class="map_container">
    <div class="map_box" style="transform:rotate(4.5deg);">
        <div class="map" id="map" ></div>
    </div>
    <div class="map_buttons">
        <div class="left">
            <button class="{{floor?'btn_map_active':''}}"><img src="../../assets/images/map-outline.svg" alt="" (click)="floorplanShow()"></button>
            <button class="{{annotationImgs?'btn_map_active':''}}"><img src="../../assets/images/man.svg" alt="" (click) = "annotationShow()"></button>
            <button class="{{noGoZoneImgs?'btn_map_active':''}}"><img src="../../assets/images/layers.svg" alt="" (click) = "noGoZoneShow()"></button>
        </div>
        <div class="right">
            <!-- <button><img src="../../assets/images/grid.svg" alt=""></button> -->
            <button><img src="../../assets/images/zoom-in.svg" alt="" (click)="zoomIn()"></button>
            <button><img src="../../assets/images/zoom-out.svg" alt="" (click)="zoomOut()"></button>
        </div>
    </div>
</div>

<div class="chart_container">
  <h4 class="title">Robot Availability Over Time(%)</h4>
  <div class="chart_box">
      <canvas baseChart
              [datasets]="lineChartData"
              [labels]="lineChartLabels"
              [options]="lineChartOptions"
              [colors]="lineChartColors"
              [legend]="lineChartLegend"
              [chartType]="lineChartType"
              (chartHover)="chartHovered($event)"
              (chartClick)="chartClicked($event)"></canvas>
  </div>
</div>

<!-- <graph> -->

<div class="chart_container">
    <!-- <div class="col-md-12">
      <div class="col-md-4">
        <h4 class="title">Orders Per Hour(Count)</h4>
      </div>
      <div class="col-md-4">
        <ngx-datepicker [(ngModel)]="date"></ngx-datepicker>
      </div>
    </div> -->

    <h4 class="title">Orders Per Hour(Count) 
      <!-- <span class="date-picker-style">
        <mat-form-field appearance="fill">
          <input matInput [matDatepicker]="picker"  [formControl]="date">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        
      </span> -->
    </h4>
    
    
    <div class="chart_box">
        <canvas baseChart
        [colors]="barChartColors"
        [datasets]="barChartData"
        [labels]="barChartLabels"
        [options]="barChartOptions"
        [legend]="barChartLegend"
        [chartType]="barChartType">
      </canvas>
    </div>
</div>


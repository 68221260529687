import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, BaseChartDirective, Label } from 'ng2-charts';
import { ActivatedRoute } from '@angular/router';
import { OttonomyService } from '../services/ottonomy.service';
import { clearInterval } from 'timers';
@Component({
  selector: 'app-robot-chart-box',
  templateUrl: './robot-chart-box.component.html',
  styleUrls: ['./robot-chart-box.component.scss']
})
export class RobotChartBoxComponent implements OnInit, OnDestroy {

  constructor(private route: ActivatedRoute,public ottonomyservice: OttonomyService) { }
  currentTime;
  setTime;
  interval;
  ngOnInit(): void {
    // // console.log(this.lineChartData[0].data)
    this.getRobotStatus();
    this.interval = setInterval(() => {

      this.getRobotStatus();
    },5000)


    // var date = new Date();
    // for(let i = 0;i<10;i++){
    //   this.lineChartLabels.push(date.getHours() + ':' + date.getMinutes());
    //   date.setMinutes ( date.getMinutes() + 1);
    // }
    // // // console.log(this.lineChartLabels);
    // setInterval(() => {
    //   var date = new Date();
    //   for(let i = 0;i<10;i++){
    //     this.lineChartLabels.push(date.getHours() + ':' + date.getMinutes());
    //     date.setMinutes ( date.getMinutes() + 1);
    //   }
    // },600000)
  }

  ngOnDestroy(){
    //clearInterval(this.interval);
  }

  chartValue = "CPU";
  chartChange(value){
    this.chartValue = value;
  }

  // Line Chart
  public lineChartData: ChartDataSets[] = [
    { data: [0,0,0,0,0,0,0,0,0,0], label: 'CPU 1' },
    { data: [0,0,0,0,0,0,0,0,0,0], label: 'CPU 2' },
    { data: [0,0,0,0,0,0,0,0,0,0], label: 'CPU 3' },
    { data: [0,0,0,0,0,0,0,0,0,0], label: 'CPU 4' },
    // { data: [0,0,0,0,0,0,0,0,0,0], label: 'CPU 5' },
    // { data: [0,0,0,0,0,0,0,0,0,0], label: 'CPU 6' },
    // { data: [0,0,0,0,0,0,0,0,0,0], label: 'CPU 7' },
    // { data: [0,0,0,0,0,0,0,0,0,0], label: 'CPU 8' }
  ];
  public lineChartLabels: Label[] = ['','','','','','','',''];

  public lineChartOptions: (ChartOptions) = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        yAxes: [{
            ticks: {
                fontColor: "#fff",
                fontSize: 10,
                stepSize: 10,
                fontStyle: "bold",
                beginAtZero: true
            },
            gridLines: {
              zeroLineColor: '#2D375C',
              color: "rgba(231, 231, 231, 0.1)"
            },
        }],
        xAxes: [{
            ticks: {
                fontColor: "#fff",
                fontSize: 10,
                stepSize: 1,
                fontStyle: "bold",
                beginAtZero: true
            },
            gridLines: {
              display: false ,
              color: "rgba(231, 231, 231, 0.1)"
            },
        }]
    }
  };
  public lineChartColors: Color[] = [
    {
      backgroundColor: 'rgba(148,159,177,0)',
      borderColor: '#31CEFF',
      pointBackgroundColor: '#31CEFF',
      pointBorderColor: '#fff',
      borderWidth: 2,
      pointRadius: 5,
      pointBorderWidth: 2,
      pointHoverRadius: 5,
      pointHoverBorderWidth: 2,
    }
  ];
  public lineChartLegend = false;
  public lineChartType: ChartType = 'line';

  @ViewChild(BaseChartDirective, { static: true }) chart: BaseChartDirective;

  // events
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    // // console.log(event, active);
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    // // console.log(event, active);
  }


  // Bar Chart
  public barChartOptions: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    // We use these empty structures as placeholders for dynamic theming.
      scales: {
        yAxes: [{
            ticks: {
                fontColor: "#fff",
                fontSize: 10,
                stepSize: 10,
                fontStyle: "bold",
                beginAtZero: true
            },
            gridLines: {
              zeroLineColor: '#2D375C',
              color: "rgba(231, 231, 231, 0.1)"
            },
        }],
        xAxes: [{
            ticks: {
                fontColor: "#fff",
                fontSize: 10,
                stepSize: 1,
                fontStyle: "bold",
                beginAtZero: true
            },
            gridLines: {
              display: false ,
              color: "rgba(231, 231, 231, 0.1)"
            },
        }]
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };
  public barChartColors: Color[] = [
    {
      backgroundColor: 'rgba(148,159,177,0)',
      borderColor: '#31CEFF',
      pointBackgroundColor: '#31CEFF',
      pointBorderColor: '#fff',
      borderWidth: 2,
      pointRadius: 5,
      pointBorderWidth: 2,
      pointHoverRadius: 5,
      pointHoverBorderWidth: 2,
    }
  ];
  public barChartLabels: Label[] = ['', '', '', '', '', '', '', '', '', '', ''];
  public barChartType: ChartType = 'line';
  public barChartLegend = false;

  public barChartData: ChartDataSets[] = [
    { data: [0,0,0,0,0,0,0,0,0,0], label: 'RAM 1' },
    { data: [0,0,0,0,0,0,0,0,0,0], label: 'RAM 2' },
    { data: [0,0,0,0,0,0,0,0,0,0], label: 'RAM 3' },
    { data: [0,0,0,0,0,0,0,0,0,0], label: 'RAM 4' },
  ];

  // Robot Status By Robot ID
  RobotStatusData;
  getRobotStatus(){
    var body = {
      "robotId": this.route.snapshot.params.id,
    }
    this.ottonomyservice.getRobotDataByRobotId(body).then(s => {
      if(s['status'] == 200){
        this.RobotStatusData = s['data'];
        // // console.log(this.RobotStatusData.robotStatus)
        // // console.log(this.RobotStatusData.robotStatus.cpu1Usage)
        this.lineChartData[0].data.unshift(this.RobotStatusData.robotStatus.cpu1Usage);
        this.lineChartData[1].data.unshift(this.RobotStatusData.robotStatus.cpu2Usage);
        this.lineChartData[2].data.unshift(this.RobotStatusData.robotStatus.cpu3Usage);
        this.lineChartData[3].data.unshift(this.RobotStatusData.robotStatus.cpu4Usage);
        // this.lineChartData[4].data.unshift(this.RobotStatusData.robotStatus.cpu5Usage);
        // this.lineChartData[5].data.unshift(this.RobotStatusData.robotStatus.cpu6Usage);
        // this.lineChartData[6].data.unshift(this.RobotStatusData.robotStatus.cpu7Usage);
        // this.lineChartData[7].data.unshift(this.RobotStatusData.robotStatus.cpu8Usage);
        this.lineChartData[0].data.pop();
        this.lineChartData[1].data.pop();
        this.lineChartData[2].data.pop();
        this.lineChartData[3].data.pop();
        // this.lineChartData[4].data.pop();
        // this.lineChartData[5].data.pop();
        // this.lineChartData[6].data.pop();
        // this.lineChartData[7].data.pop();
        this.lineChartData = [...this.lineChartData]

        this.barChartData[0].data.unshift(this.RobotStatusData.robotStatus.ram1Usage);
        this.barChartData[1].data.unshift(this.RobotStatusData.robotStatus.ram2Usage);
        this.barChartData[2].data.unshift(this.RobotStatusData.robotStatus.ram3Usage);
        this.barChartData[3].data.unshift(this.RobotStatusData.robotStatus.ram4Usage);
        this.barChartData[0].data.pop();
        this.barChartData[1].data.pop();
        this.barChartData[2].data.pop();
        this.barChartData[3].data.pop();
        this.barChartData = [...this.barChartData]
      }
    })
  }

}

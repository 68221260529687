<div class="title_box">
    <div *ngFor="let robotData of robotStatusData">
        <div>{{robotData[0].robotId}} : <span [class.red]="robotData[0].robotStatus=='UNAVAILABLE'" [[class.green]="robotData[0].robotStatus=='AVAILABLE'">
            {{robotData[0].robotStatus}}</span></div>
    </div>
</div>
<div class="title_box">
    <h3>Orders
        <!-- <input type="text" name="search" placeholder="Search Orders"> -->
    </h3>
    <button (click)="openModal('addOrder')">Add Order</button>
</div>
<!-- <div class="filter_box">
    <div class="form-group">
        <label for="sdate">Start Date</label>
        <input type="text" name="date" id="sdate" placeholder="DD/MM/YYYY">
    </div>
    <div class="form-group">
        <label for="edate">End Date</label>
        <input type="text" name="date" id="edate" placeholder="DD/MM/YYYY">
    </div>
    <button>Download Excel</button>
</div> -->
<div class="table-responsive">
    <table class="table table-bordered">
        <thead>
            <tr>
                <th>S.No.</th>
                <th>Order ID</th>
                <th>Time Stamp</th>
                <th>Robot Assigned</th>
                <th>Cabin</th>
                <th>Status</th>
                <th>Pickup Location</th>
                <th>Dropoff Location</th>
                <!-- For Testing version Only -->
                <!-- <th>Cancel</th>
                <th>Open Door</th> -->
                <!-- <th>Actions</th> -->
            </tr>
        </thead>
        <tbody id='order_body'>
            <tr *ngFor="let order of OrdersList | paginate: { itemsPerPage: 10, currentPage: p, totalItems: totalOrders };let i = index">
                <td>{{ (10 * (p - 1) + i) + 1 }}</td>
                <td>{{order.orderId}}</td>
                <td>{{order.createdAt | slice:0:10}} - {{order.createdAt | slice:11:16}}</td>
                <td>{{order.robotId}}</td>
                <td>{{order.boxId}}</td>
                <td>{{order.orderStatus}}</td>
                <td>{{order.pickupId}}</td>
                <td>{{order.dropId}}</td>
                <!-- For Testing version Only -->
                <td><button class="cancel_but" (click)="deleteModal('delete',order.orderId)" [disabled]="order.statusCode == 9 || order.statusCode == 10 || order.orderStatus =='order not received'">Cancel</button></td>
                <td><button class="open_but" (click)=openDoorByOrderId(order.orderId) [disabled]="order.statusCode != 4">Open Door</button></td>
                <!-- <td>
                    <div class="icon_box">
                        <img src="../../assets/images/close-circle.svg" alt="Delete" class="img-fluid" (click)="deleteModal('delete',order.orderId)">
                    </div>
                </td> -->
            </tr>
            <div>
                <!-- <pagination-controls *ngIf="OrdersList.length > 0" (pageChange)="p = $event" class="float-right test" directionLinks="true" responsive="true"></pagination-controls>   -->
            </div>            
            
              
        </tbody>
    </table>
    
</div>
<pagination-controls (pageChange)="pageChanged($event)" class="float-right pagination"  directionLinks="true" responsive="true"></pagination-controls>

<div class="custom_modal_container" [class.show]="modalShow == 'addOrder'">
    <div class="backdrop" (click)="closeModal()"></div>
    <div class="custom_modal_box">
        <h3>Add Order</h3>
        <form [formGroup]="addOrderForm" (ngSubmit)="AddOrder()">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Order ID</label>
                        <input type="text" name="" placeholder="Enter Order Id" formControlName="orderId" [ngClass]="{ 'is-invalid' : submitted && f.orderId.errors }">
                        <div class="invalid-feedback" *ngIf="submitted && f.orderId.errors">
                            <div *ngIf="f.orderId.errors.required">Please enter order id.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="role">Pick Up Location</label>
                        <select name="" placeholder="Select Pick Up Location" formControlName="pLocation" [ngClass]="{ 'is-invalid' : submitted && f.pLocation.errors }">
                            <option [value]="pick.locationName" *ngFor="let pick of pickupLocationList">{{pick.locationName}}</option>
                        </select>
                        <div *ngIf="submitted && f.pLocation.errors" class="invalid-feedback">
                            <div *ngIf="f.pLocation.errors.required">Please select pick up location.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="role">Drop Off Location</label>
                        <select name="" placeholder="Select Drop Off Location" formControlName="dLocation" [ngClass]="{ 'is-invalid' : submitted && f.dLocation.errors }">
                            <option [value]="drop.locationName" *ngFor="let drop of dropoffLocationList">{{drop.locationName}}</option>
                        </select>
                        <div *ngIf="submitted && f.dLocation.errors" class="invalid-feedback">
                            <div *ngIf="f.dLocation.errors.required">Please select pick up location.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label>QR Code Pick Up</label>
                        <input type="text" name="" placeholder="Enter QR Code Pick Up" formControlName="qPick" [ngClass]="{ 'is-invalid' : submitted && f.qPick.errors }">
                        <div class="invalid-feedback" *ngIf="submitted && f.qPick.errors">
                            <div *ngIf="f.qPick.errors.required">Please enter qr code pick up.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label>QR Code Drop Off</label>
                        <input type="text" name="" placeholder="Enter QR Code Drop Off" formControlName="qDrop" [ngClass]="{ 'is-invalid' : submitted && f.qDrop.errors }">
                        <div class="invalid-feedback" *ngIf="submitted && f.qDrop.errors">
                            <div *ngIf="f.qDrop.errors.required">Please enter qr code drop off.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Order Status Update</label>
                        <div class="order_status">{{placeOrderErrors}}&nbsp;</div>
                    </div>
                </div>
            </div>

            <div class="btn_container">
                <button class="cancel" type="button" (click)="closeModal()">Cancel</button>
                <button class="add" type="submit" [disabled]="addButton==true">Add</button>
            </div>
        </form>
    </div>
</div>

<div class="custom_modal_container confirmation_modal" [class.show]="modalShow == 'delete'">
    <div class="backdrop" (click)="closeModal()"></div>
    <div class="custom_modal_box">
        <p>Do you want to cancel order {{orderId}}?</p>
        <div class="btn_container">
            <button class="cancel" (click)="closeModal()">Cancel</button>
            <button class="add" (click)="canceloOrder()">Yes</button>
        </div>
    </div>
</div>


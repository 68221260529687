<!-- <app-admin-navbar></app-admin-navbar> -->
<div class="title_box">
    <h3>Media</h3>
    <button class="upload_btn" (click)="openUploadVideoModal('uploadVideo')">Add Videos</button>
</div>

<div class="table-responsive">
    <table class="table table-bordered">
        <thead>
            <tr>
                <th>S.No.</th>
                <th>videoID</th>
                <th>RobotID</th>
                <th>file name</th>
                <!-- <th>URL<th> -->
                <th colspan="3">Actions</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let video of mediaList;let i = index">
                <td>{{i+1}}</td>
                <td>{{video.videoId}}</td>
                <td>{{video.robotId}}</td>
                <td>{{video.videoName}}</td>
                <!-- <td>{{video.URL}}</td> -->
                <td class="img">
                    <img src="../../assets/images/edit.svg" alt="Edit" class="img-fluid" (click)="openAssignVideoModel('assignVideoModel', video.videoId, video)">
                </td>
                <td class="img">
                    <img src="../../assets/images/play.svg" alt="Play Video" class="img-fluid" (click)="openPlayModal('playVideo', video.robotId, video.videoId, video.videoName)">
                </td>
                <td class="img">
                    <img src="../../assets/images/delete.svg" alt="Delete" class="img-fluid" (click)="openDeleteModal('delete',video.videoId, video.adminId)">
                </td>
            </tr>
        </tbody>
    </table>
</div>

<!-- Add Upload Video -->
<div class="custom_modal_container" [class.show]="modalShow == 'uploadVideo'">
    <div class="backdrop"></div>
    <div class="custom_modal_box">
        <form [formGroup]="addVideoForm">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="videoid">Video Id</label>
                        <input type="text" name="" placeholder="Video Id" [formControl]="submitVideoId" [ngClass]="{ 'is-invalid' : submitted && submitVideoId.errors }">
                        <div class="invalid-feedback">
                            <div *ngIf="submitVideoId.invalid">Please enter your video id</div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label for="image">Select Video</label>
                        <div class="upload_box">
                            <button type="button">
                                <input accept="video/mp4,video/x-m4v,video/*" (change)="handleFileInput($event)" type="file" >
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="btn_container">
                <button class="cancel" type="button" (click)="closeModal()">Cancel</button>
                <button class="add" [class.spinner]="loading" [disabled]="loading" (click)="postUploadVideo()">Save</button>
            </div>
        </form>
    </div>
</div>

<!-- Assign Video Model -->
<div class="custom_modal_container" [class.show]="modalShow == 'assignVideoModel'">
    <div class="backdrop" (click)="closeModal()"></div>
    <div class="custom_modal_box">
        <h3>Assign Video</h3>
        <form [formGroup]="addVideoForm" (ngSubmit)="assignVideo()">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="videoid">Video Id</label>
                        <input type="text" name="" placeholder="Video Id" formControlName="videoid" [ngClass]="{ 'is-invalid' : submitted && f.videoid.errors }">
                        <div class="invalid-feedback" *ngIf="submitted && f.videoid.errors">
                            <div *ngIf="f.videoid.errors.required">Please enter your video id</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="robotid">Assigned To</label>
                        <ng-select multiple="true" formControlName="robotid">
                            <ng-option *ngFor="let robot of RobotListData" [value]="robot.robotId">{{robot.robotId}}</ng-option>
                        </ng-select>
                        <div *ngIf="submitted && f.robotid.errors" class="invalid-feedback">
                            <div *ngIf="f.robotid.errors.required">Please select robotId.</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="btn_container">
                <button class="cancel" type="button" (click)="closeModal()">Cancel</button>
                <button class="add" type="submit">Save</button>
            </div>
        </form>
    </div>
</div>

<!-- Play Video -->
<div class="custom_modal_container" [class.show]="modalShow == 'playVideo'">
    <div class="backdrop" (click)="closeModal()"></div>
    <div class="custom_modal_box">
        <button style="border: none;" (click)="closeModal()"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x cancel_btn close_but"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg></button>
        <video *ngIf="mediaVideoUrl" width="100%" height="315" controls>
            <source  [src]="mediaVideoUrl" type="video/mp4">
        </video>
    </div>
</div>

<!-- Delete Video -->
<div class="custom_modal_container confirmation_modal" [class.show]="modalShow == 'delete'">
    <div class="backdrop" (click)="closeModal()"></div>
    <div class="custom_modal_box">
        <p>Are you sure?</p>
        <div class="btn_container">
            <button class="cancel" (click)="closeModal()">Cancel</button>
            <button class="add" (click)="deleteMedia()">Delete</button>
        </div>
    </div>
</div>

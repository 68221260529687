import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute,Router } from '@angular/router';
import { OttonomyService } from '../services/ottonomy.service';
import * as L from 'leaflet';
@Component({
  selector: 'app-robot-map-box',
  templateUrl: './robot-map-box.component.html',
  styleUrls: ['./robot-map-box.component.scss']
})
export class RobotMapBoxComponent implements OnInit, OnDestroy {

  constructor(private router: Router,private route: ActivatedRoute,public ottonomyservice: OttonomyService,) {
    router.events.subscribe(val => {
      this.getRobotStatus();
    });
   }
  map:any;
  bounds:any;
  image:any;
  xValue:any;
  yValue:any;
  robotIcon:any;
  position:any;
  mark:any;
  RobotStatusData:any;
  url;
  overlay;
  robotStatusLength;
  mWidth;
  mHeight;
  layerGroup:any;
  locationList:any;
  fleetIdFromNavbar: string;
  markers = [];
  interval:any;

  ngOnInit(): void {
    setTimeout(() =>{
      this.getRobotStatus();
      this.getMapStatus();
      this.locationListByFleetId();
    },2000)

    this.interval = setInterval(() => {

      this.getRobotStatus();
      this.markers = [];
      if(this.layerGroup == undefined){
        this.mapInit()
      }else{
        this.layerGroup.clearLayers();
        this.mapInit()
      }
    },3000)
  }

  ngOnDestroy(){
    if(this.interval){
      clearInterval(this.interval);
    }
    
  }

  imageValue;
  loadMap(){
    this.map = L.map('map', {
      minZoom: 1,
      maxZoom: 6,
      center: [0, 0],
      zoom: 2,
      crs: L.CRS.Simple,
    });
    var w = this.mWidth,
    h = this.mHeight;
    var southWest = this.map.unproject([0, h], this.map.getMaxZoom());
    var northEast = this.map.unproject([w, 0], this.map.getMaxZoom());
    this.bounds = new L.LatLngBounds(southWest, northEast);
    this.imageValue = L.imageOverlay(this.url, this.bounds);
    this.imageValue.addTo(this.map)
    this.map.setMaxBounds(this.bounds);
    this.robotIcon = L.icon({
        iconUrl: '../../assets/images/Robot-Track.png',
        iconSize:     [40, 40],
        popupAnchor:  [0, -10]
    });
  }

  mapInit(){
    this.xValue = this.RobotStatusData.robotStatus.positionX;
    this.yValue = this.RobotStatusData.robotStatus.positionY;
    let battery = 0.125*this.RobotStatusData.robotStatus.robotBattery;
    let internetHide4,internetHide3,internetHide2,internetHide1;
    if(this.RobotStatusData.robotStatus.activeSimRssi < 75){
      internetHide4 = "hide";
    }
    if(this.RobotStatusData.robotStatus.activeSimRssi < 50){
      internetHide3 = "hide";
    }
    if(this.RobotStatusData.robotStatus.activeSimRssi < 25){
      internetHide2 = "hide";
    }
    if(this.RobotStatusData.robotStatus.activeSimRssi == 0){
      internetHide1 = "hide";
    }
    const detailString = '<h6 class="title">Robot - '+this.RobotStatusData.robotStatus.robotId+'</h6><div class="status_box"><div class="battery_box"> <svg viewBox="0 0 21 11" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect x="1" width="16.9689" height="9.5026" rx="1.9387" stroke="#5DFBA2" stroke-width="1.35" stroke-linecap="square"/> <rect x="3.27771" y="2.33026" width="'+battery+'" height="4.84208" rx=".169689" fill="#5DFBA2" stroke="#5DFBA2" stroke-width="1.35751" stroke-linecap="square"/> <path d="M20.034 3.16766v3.16725" stroke="#5DFBA2" stroke-width="1.35751" stroke-linecap="round"/> </svg> <p>'+this.RobotStatusData.robotStatus.robotBattery+'%</p></div><svg class="cellular" viewBox="0 0 30 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path class="'+internetHide4+'" d="M28.5 22H25.5C24.6716 22 24 21.3284 24 20.5V1.5C24 0.671573 24.6716 0 25.5 0H28.5C29.3284 0 30 0.671573 30 1.5V20.5C30 21.3284 29.3284 22 28.5 22Z" fill="white"/><path class="'+internetHide3+'" d="M20.5 22H17.5C16.6716 22 16 21.3284 16 20.5V6.5C16 5.67157 16.6716 5 17.5 5H20.5C21.3284 5 22 5.67157 22 6.5V20.5C22 21.3284 21.3284 22 20.5 22Z" fill="white"/><path class="'+internetHide2+'" d="M12.5 22H9.5C8.67157 22 8 21.3284 8 20.5V10.5C8 9.67157 8.67157 9 9.5 9H12.5C13.3284 9 14 9.67157 14 10.5V20.5C14 21.3284 13.3284 22 12.5 22Z" fill="white"/><path class="'+internetHide1+'" d="M4.5 22H1.5C0.671573 22 0 21.3284 0 20.5V14.5C0 13.6716 0.671573 13 1.5 13H4.5C5.32843 13 6 13.6716 6 14.5V20.5C6 21.3284 5.32843 22 4.5 22Z" fill="white"/></svg> </div><p>Action ID - '+this.RobotStatusData.robotStatus.actionId+'</p><button><img src="../../assets/images/camera.png">Live Feed</button>'
    let detailPopup = new L.Popup({ autoClose: false, closeOnClick: false, autoPan: false}).setContent(detailString).setLatLng(this.map.unproject([this.xValue, this.yValue]));
    this.layerGroup = L.layerGroup(this.markers).addTo(this.map);
    this.mark = L.marker(this.map.unproject([this.xValue, this.yValue],this.map.getMaxZoom()),{icon: this.robotIcon,}).addTo(this.layerGroup).bindPopup(detailPopup);
    this.mark.setRotationAngle(this.RobotStatusData.robotStatus.positionYaw).setRotationOrigin('center');
    this.markers.push(this.mark);
  }

  public MapsList
  baseMapImageUrl = '';
  mapListLength;
  getMapStatus(){
    var body = {
      "fleetId" : this.RobotStatusData.robotDetailStatus.fleetId
    }
    this.ottonomyservice.getMapsList(body).then(s => {
      this.MapsList = s['data'];
      this.baseMapImageUrl = '';
      if(s['data'] != null){
        this.baseMapImageUrl = this.MapsList.baseMapImageUrl;
        this.url = this.baseMapImageUrl;
        this.mWidth=this.MapsList.mapWidth;
        this.mHeight=this.MapsList.mapHeight;
        this.loadMap();
      }
    })
  }

  locationListByFleetId() {
    if(this.fleetIdFromNavbar != undefined) {
      var body = {
        "fleetId": this.fleetIdFromNavbar,
      }
      this.ottonomyservice.getLocationList(body).then(s => {
        if(s['status'] == 200){
          this.locationList = s['data']
          // Uncomment it to enable location tile
          this.addIcons();
          
          //// console.log('Location :', this.locationList)
          //for(var j = 0; j<this.locationList; j++) { }
          
        }
      })
    }
  }


  addIcons(){
    var locationLength = this.locationList.length;
    for(var i=0; i<locationLength; i++){
      var icon = this.locationList[i].locationType.toUpperCase() == "PICKUP" ? "pickup.png" : (this.locationList[i].locationType.toUpperCase() == "DROPOFF" ? "dropoff.png" : "home.png");
      var activeState = this.locationList[i].status == "ACTIVE" ? "active" : "unactive";
      var iconSrc = "../../assets/images/" + activeState + "/" + icon;
      var mapIcon = L.icon({
        iconUrl: iconSrc,
        iconSize:     [30, 30]
      });
      var layerGroup = L.layerGroup(this.markers).addTo(this.map);
      var corX = this.locationList[i].positionX;
      var corY = this.locationList[i].positionY;
      var name = this.locationList[i].locationName;

      var mark = L.marker(this.map.unproject([corX,corY],this.map.getMaxZoom()),{icon: mapIcon,}).addTo(layerGroup).bindPopup(name);
      this.markers.push(mark);
    }
  }

  // Robot Status By Robot ID
  getRobotStatus(){
    var body = {
      "robotId": this.route.snapshot.params.id,
    }
    this.ottonomyservice.getRobotDataByRobotId(body).then(s => {
      if(s['status'] == 200){
        this.RobotStatusData = s['data'];
        this.fleetIdFromNavbar = this.RobotStatusData.robotDetailStatus.fleetId;
      }
    })
  }
}

<app-admin-navbar></app-admin-navbar>
<div class="title_box">
    <h3>Fleets</h3>
    <button (click)="openModal('addFleet')">Add Fleet</button>
</div>

<div class="table-responsive">
    <table class="table table-bordered">
        <thead>
            <tr>
                <!-- <th>S.No.</th> -->
                <th>Fleet Details</th>
                <th style="text-align: center;">Fleet Status</th>
                <!-- <th>Name</th> -->
                <!-- <th>Email Address</th>
                <th>Contact Number</th>
                <th>Location</th> -->
                <!-- <th>Logo</th> -->
                <th colspan="3" style="text-align: center;">Actions</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let fleetData of FleetStatusData; let i = index;">
                <!-- <td>{{i+1}}</td> -->
                <!-- <td></td> -->
                <td class="fleet-details-text"><h4>{{fleetData.fleetName}}</h4><span><b>{{fleetData.adminName}},</b> {{fleetData.location}}<br />{{fleetData.adminEmail}}<br />{{fleetData.adminContactNumber}}</span></td>
                
                <td class="img"> 
                    <label class="switch" >
                        <input type="checkbox" [attr.checked]="fleetData.orderingStatus == 'ACTIVE' ? true : null"
                            (click)="changeOrderingStatus(i)">
                        <span class="slider round"></span>
                    </label>
                </td>
                <!-- <td></td>
                <td></td>
                <td></td> -->
                <!-- <td>{{fleetData.imageLogoUrl.substring(fleetData.imageLogoUrl.lastIndexOf('/') + 1)}}</td> -->
                <td class="img">
                    <img src="../../assets/images/setting.svg" alt="Setting" class="img-fluid" (click)="fleetDetail(fleetData.fleetId )">
                </td>
                <td class="img">
                    <img src="../../assets/images/edit.svg" alt="Edit" class="img-fluid" (click)="openEditModal('editFleet',fleetData.fleetId,fleetData.fleetName,fleetData.location,fleetData.adminName,fleetData.adminEmail,fleetData.adminContactNumber,fleetData.timezone)">
                </td>
                <td class="img">
                    <img src="../../assets/images/delete.svg" alt="Delete" class="img-fluid" (click)="openDeleteModal('delete',fleetData.fleetId)">
                </td>
            </tr>           
        </tbody>
    </table>
</div>

<!-- Add Fleet Modal -->
<div class="custom_modal_container" [class.show]="modalShow == 'addFleet'">
    <div class="backdrop"></div>
    <div class="custom_modal_box">
        <h3>Add Fleet</h3>
        <form [formGroup]="addFleetForm" (ngSubmit)="AddFleet()">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="fname">Fleet Name</label>
                        <input type="text" name="" formControlName="fname" placeholder="Fleet Name" [ngClass]="{ 'is-invalid': submitted && f.fname.errors }">
                        <div *ngIf="submitted && f.fname.errors" class="invalid-feedback">
                            <div *ngIf="f.fname.errors.required">Please enter fleet name.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="location">Location</label>
                        <input type="text" name="" formControlName="location" placeholder="Location" [ngClass]="{ 'is-invalid': submitted && f.location.errors }">
                        <div *ngIf="submitted && f.location.errors" class="invalid-feedback">
                            <div *ngIf="f.location.errors.required">Please enter location.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="timezone">TimeZone</label>
                        <input type="text" name="timezone" formControlName="timezone" placeholder="{{todayDate | date:'short':'GMT'}}" [ngClass]="{ 'is-invalid': submitted && f.timezone.errors }">
                        <div *ngIf="submitted && f.timezone.errors" class="invalid-feedback">
                            <div *ngIf="f.timezone.errors.required">Please enter Timezone.</div>
                        </div>
                    </div>
                </div>

                <!-- <div class="col-md-6">
                    <div class="form-group">
                        <label for="image">Upload Location</label>
                        <div class="upload_box">
                            <button type="button">
                                <input type="file" name="" placeholder="Upload Location Image" formControlName="logo" [ngClass]="{ 'is-invalid' : submitted && f.logo.errors }" (change)="uploadFleetMedia($event)">
                            </button>
                        </div>
                        <div *ngIf="submitted && f.logo.errors" class="invalid-feedback">
                            <div *ngIf="f.logo.errors.required">Please upload Image.</div>
                        </div>
                    </div>
                </div> -->

            </div>
            <div class="btn_container">
                <button class="cancel" type="button" (click)="closeModal()">Cancel</button>
                <button class="add" type="submit">Add</button>
            </div>
        </form>
    </div>
</div>

<!-- Edit Fleet Modal -->
<div class="custom_modal_container" [class.show]="modalShow == 'editFleet'">
    <div class="backdrop"></div>
    <div class="custom_modal_box">
        <h3>Edit Fleet</h3>
        <form [formGroup]="addFleetForm" (ngSubmit)="EditFleet()">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="fname">Fleet Name</label>
                        <input type="text" name="" formControlName="fname" placeholder="Fleet Name" [ngClass]="{ 'is-invalid': submitted && f.fname.errors }">
                        <div *ngIf="submitted && f.fname.errors" class="invalid-feedback">
                            <div *ngIf="f.fname.errors.required">Please enter fleet name.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="location">Location</label>
                        <input type="text" name="" formControlName="location" placeholder="Location" [ngClass]="{ 'is-invalid': submitted && f.location.errors }">
                        <div *ngIf="submitted && f.location.errors" class="invalid-feedback">
                            <div *ngIf="f.location.errors.required">Please enter location.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="timezone">TimeZone</label>
                        <input type="text" name="timezone" formControlName="timezone" placeholder="{{todayDate | date:'short':'GMT'}}" [ngClass]="{ 'is-invalid': submitted && f.timezone.errors }">
                        <div *ngIf="submitted && f.timezone.errors" class="invalid-feedback">
                            <div *ngIf="f.timezone.errors.required">Please enter Timezone.</div>
                        </div>
                    </div>
                </div>

                <!-- <div class="col-md-6">
                    <div class="form-group">
                        <label for="logo">Upload Logo</label>
                        <input type="text" name="" formControlName="logo" placeholder="Upload Logo" [ngClass]="{ 'is-invalid': submitted && f.logo.errors }">
                        <div *ngIf="submitted && f.logo.errors" class="invalid-feedback">
                            <div *ngIf="f.logo.errors.required">Please enter logo name.</div>
                        </div>
                    </div>
                </div> -->

            </div>
            <div class="btn_container">
                <button class="cancel" type="button" (click)="closeModal()">Cancel</button>
                <button class="add" type="submit">Save</button>
            </div>
        </form>
    </div>
</div>

<!-- Delete Fleet Modal -->
<div class="custom_modal_container confirmation_modal" [class.show]="modalShow == 'delete'">
    <div class="backdrop"></div>
    <div class="custom_modal_box">
        <p>Are you sure?</p>
        <div class="btn_container">
            <button class="cancel" type="button" (click)="closeModal()">Cancel</button>
            <button class="add" type="button" (click)="deleteFleet()">Delete</button>
        </div>
    </div>
</div>
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OttonomyService } from '../services/ottonomy.service';
@Component({
  selector: 'app-robot-malfunction-box',
  templateUrl: './robot-malfunction-box.component.html',
  styleUrls: ['./robot-malfunction-box.component.scss']
})
export class RobotMalfunctionBoxComponent implements OnInit {

  constructor(private route: ActivatedRoute,public ottonomyservice: OttonomyService) { }
  currentTime;
  setTime;
  modalShow;
  robotRunningState: String = 'AUTO';
  manualMode: boolean = false;

  ngOnInit(): void {
    
  }

}

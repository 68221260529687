import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { RobotDetailComponent } from './robot-detail/robot-detail.component';
import { AdminComponent } from './admin/admin.component';
import { AdminFleetComponent } from './admin-fleet/admin-fleet.component';
import { AdminFleetUsersComponent } from './admin-fleet-users/admin-fleet-users.component';
import { AdminRobotComponent } from './admin-robot/admin-robot.component';
//import { AgendaComponent } from './agenda/agenda.component';
import { AuthGuard } from  './services/auth.guard';
import { FleetMediaComponent } from './fleet-media/fleet-media.component';

const routes: Routes = [
  { path: '',   redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent},
  { path: 'dashboard', component: DashboardComponent,canActivate: [AuthGuard] },
  { path: 'robot-detail/:id', component: RobotDetailComponent,canActivate: [AuthGuard] },
  { path: 'admin', component: AdminComponent,canActivate: [AuthGuard] },
  { path: 'users', component: AdminFleetUsersComponent,canActivate: [AuthGuard] },
  { path: 'robot', component: AdminRobotComponent,canActivate: [AuthGuard] },
  { path: 'fleet-detail/:id', component: AdminFleetComponent,canActivate: [AuthGuard] },
  { path: 'media', component: FleetMediaComponent,canActivate: [AuthGuard] },
  //{ path: 'agenda', component: AgendaComponent,canActivate: [AuthGuard] },
  // { path: 'robots', component: AdminFleetComponent,canActivate: [AuthGuard] },
  // { path: 'map', component: AdminFleetComponent,canActivate: [AuthGuard] },
  //{ path: 'media', component: AdminFleetComponent,canActivate: [AuthGuard] },
  // { path: 'orders', component: AdminFleetComponent,canActivate: [AuthGuard] },
  // { path: 'monitoring', component: AdminFleetComponent,canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

<div class="main">
    <div class="title_box">
        <h3>Map</h3>
        <button (click)="openModal('addMap')" *ngIf="MapsList == null">Add Map</button>
        <span>
            <button
                (click)="openEditMap('editMap',MapsList.mapId,MapsList.fleetId,MapsList.mapName,baseMapImageUrl,floorplanImageUrl,annotationImageUrl,noGoZoneImageUrl, MapsList.mapHeight, MapsList.mapWidth, MapsList.mapOrigionX, MapsList.mapOrigionY, MapsList.mapScale)">Edit
                Map</button>
            <button class="delete" (click)="openMapDeleteModal('deleteMap',MapsList.mapId)"
                *ngIf="MapsList != null">Delete
                Map</button>
        </span>
    </div>

    <div class="table-responsive">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th>S.No.</th>
                    <th>Map Type</th>
                    <th>File Name</th>
                    <th colspan="3">Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>1.</td>
                    <td>Base Map Image</td>
                    <td>{{baseMapImageUrl.substring(baseMapImageUrl.lastIndexOf('/') + 1)}}</td>
                    <!-- <td>
                        <label class="switch">
                            <input type="checkbox" [checked]="map.mapStatus">
                            <span class="slider round"></span>
                        </label>
                    </td> -->
                    <td class="img">
                        <img src="../../assets/images/eye.svg" alt="View" class="img-fluid"
                            (click)="viewMapModal(baseMapImageUrl)">
                    </td>
                    <!-- MapsList.mapHeight, MapsList.mapWidth, MapsList.mapOrigionX, MapsList.mapOrigionY, MapsList.mapScale -->
                    <!-- <td class="img">
                        <img src="../../assets/images/edit.svg" alt="Edit" class="img-fluid"
                            (click)="openEditMap('editMap',MapsList.mapId,MapsList.fleetId,MapsList.mapName,baseMapImageUrl,floorplanImageUrl,annotationImageUrl,noGoZoneImageUrl, MapsList.mapHeight, MapsList.mapWidth, MapsList.mapOrigionX, MapsList.mapOrigionY, MapsList.mapScale)">
                    </td> -->
                    <!-- <td class="img">
                        <img src="../../assets/images/delete.svg" alt="Delete" class="img-fluid">
                    </td> -->
                </tr>
                <tr>
                    <td>2.</td>
                    <td>Floor Plan Image</td>
                    <td>{{floorplanImageUrl.substring(floorplanImageUrl.lastIndexOf('/') + 1)}}</td>
                    <!-- <td>
                        <label class="switch">
                            <input type="checkbox" [checked]="map.mapStatus">
                            <span class="slider round"></span>
                        </label>
                    </td> -->
                    <td class="img">
                        <img src="../../assets/images/eye.svg" alt="View" class="img-fluid"
                            (click)="viewMapModal(floorplanImageUrl)">
                    </td>
                    <!-- <td class="img">
                        <img src="../../assets/images/edit.svg" alt="Edit" class="img-fluid">
                    </td> -->
                    <!-- <td class="img">
                        <img src="../../assets/images/delete.svg" alt="Delete" class="img-fluid">
                    </td> -->
                </tr>
                <tr>
                    <td>3.</td>
                    <td>Annotated Image</td>
                    <td>{{annotationImageUrl.substring(annotationImageUrl.lastIndexOf('/') + 1)}}</td>
                    <!-- <td>
                        <label class="switch">
                            <input type="checkbox" [checked]="map.mapStatus">
                            <span class="slider round"></span>
                        </label>
                    </td> -->
                    <td class="img">
                        <img src="../../assets/images/eye.svg" alt="View" class="img-fluid"
                            (click)="viewMapModal(annotationImageUrl)">
                    </td>
                    <!-- <td class="img">
                        <img src="../../assets/images/edit.svg" alt="Edit" class="img-fluid">
                    </td> -->
                    <!-- <td class="img">
                        <img src="../../assets/images/delete.svg" alt="Delete" class="img-fluid" >
                    </td> -->
                </tr>
                <tr>
                    <td>4.</td>
                    <td>No Go Zone Image</td>
                    <td>{{noGoZoneImageUrl.substring(noGoZoneImageUrl.lastIndexOf('/') + 1)}}</td>
                    <!-- <td>
                        <label class="switch">
                            <input type="checkbox" [checked]="map.mapStatus">
                            <span class="slider round"></span>
                        </label>
                    </td> -->
                    <td class="img">
                        <img src="../../assets/images/eye.svg" alt="View" class="img-fluid"
                            (click)="viewMapModal(noGoZoneImageUrl)">
                    </td>
                    <!-- <td class="img">
                        <img src="../../assets/images/edit.svg" alt="Edit" class="img-fluid">
                    </td> -->
                    <!-- <td class="img">
                        <img src="../../assets/images/delete.svg" alt="Delete" class="img-fluid">
                    </td> -->
                </tr>
            </tbody>
        </table>
    </div>

    <div class="main_table_container">
        <div class="left_table">
            <div class="table_title">
                <h4>Pick Up Locations</h4>
                <button (click)="openLocationModal('addLocation','PICKUP')">Add Location</button>
            </div>
            <div class="table-responsive">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th>S.No.</th>
                            <th>Location Name</th>
                            <th>Position</th>
                            <th>Status</th>
                            <th>Edit</th>
                            <th colspan="2">Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let pickUp of pickupLocationList;let i = index">
                            <td>{{i+1}}</td>
                            <td>{{pickUp.locationName}}</td>
                            <td>{{pickUp.metricPositionX}},{{pickUp.metricPositionY}},{{pickUp.metricPositionYaw}}</td>
                            <!-- <td>{{pickUp.imageLocationUrl.substring(pickUp.imageLocationUrl.lastIndexOf('/') + 1)}}</td> -->
                            <td>
                                <label class="switch">
                                    <input type="checkbox" [attr.checked]="pickUp.status == 'ACTIVE' ? true : null"
                                        (click)="setPickupLocationStatus(pickUp.locationId,pickUp.status,pickUp.locationName)">
                                    <span class="slider round"></span>
                                </label>
                            </td>
                            <td class="img">
                                <img src="../../assets/images/edit.svg" alt="Edit" class="img-fluid"
                                    (click)="openEditLocation('editLocation',pickUp.locationId,pickUp.mapId,pickUp.locationName,pickUp.metricPositionX,pickUp.metricPositionY,pickUp.metricPositionYaw,pickUp.status,pickUp.locationType)">
                            </td>
                            <td class="img">
                                <img src="../../assets/images/delete.svg" alt="Delete" class="img-fluid"
                                    (click)="openDeleteModal('delete',pickUp.locationId)">
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="right_table">
            <div class="table_title">
                <h4>Drop Off Locations</h4>
                <button (click)="openLocationModal('addLocation','DROPOFF')">Add Location</button>
            </div>
            <div class="table-responsive">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th>S.No.</th>
                            <th>Location Name</th>
                            <th>Position</th>
                            <th>Status</th>
                            <th>Wander</th>
                            <th>Edit</th>
                            <th colspan="2">Delete</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let dropoff of dropoffLocationList;let i = index">
                            <td>{{i+1}}</td>
                            <td>{{dropoff.locationName}}</td>
                            <td>{{dropoff.metricPositionX}},{{dropoff.metricPositionY}},{{dropoff.metricPositionYaw}}
                            </td>
                            <!-- <td>{{dropoff.imageLocationUrl.substring(dropoff.imageLocationUrl.lastIndexOf('/') + 1)}}</td> -->
                            <td>
                                <label class="switch">
                                    <input type="checkbox" [attr.checked]="dropoff.status == 'ACTIVE' ? true : null"
                                        (click)="setDropoffLocationStatus(dropoff.locationId,dropoff.status, dropoff.locationName)">
                                    <span class="slider round"></span>
                                </label>
                            </td>
                            <td>
                                <label class="switch">
                                    <input type="checkbox" [attr.checked]="dropoff.wanderLoc == 1 || dropoff.wanderLoc == 2 ? true : null"
                                        (click)="setDropoffWanderStatus(dropoff.locationId,dropoff.wanderLoc, dropoff.locationName)">
                                    <span class="slider round"></span>
                                </label>
                            </td>

                            <td class="img">
                                <!-- <img src="../../assets/images/edit.svg" alt="Edit" class="img-fluid" (click)="openEditLocation('editLocation',dropoff.locationId,dropoff.mapId,dropoff.locationName,dropoff.positionX,dropoff.positionY,dropoff.positionYaw,dropoff.status,dropoff.locationType,dropoff.imageLocationUrl)"> -->
                                <img src="../../assets/images/edit.svg" alt="Edit" class="img-fluid"
                                    (click)="openEditLocation('editLocation',dropoff.locationId,dropoff.mapId,dropoff.locationName,dropoff.metricPositionX,dropoff.metricPositionY,dropoff.metricPositionYaw,dropoff.status,dropoff.locationType)">
                            </td>
                            <td class="img">
                                <img src="../../assets/images/delete.svg" alt="Delete" class="img-fluid"
                                    (click)="openDeleteModal('delete',dropoff.locationId)">
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <br>
    <br>
    <br>
    <!-- Start Add Home Location -->
    <div class="title_box" style="margin-top: 20px;">
        <h3>Home Locations</h3>
        <button (click)="openHomeLocationModal('addHomeLocation','HOME')">Add Home Location</button>
    </div>
    <div class="table-responsive">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th>S.No.</th>
                    <th>Location Name</th>
                    <th>Position</th>
                    <th>Status</th>
                    <th>Assign Robot</th>
                    <th colspan="3">Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let pickUp of homeLocationList;let i = index">
                    <td>{{i+1}}</td>
                    <td>{{pickUp.locationName}}</td>
                    <td>{{pickUp.metricPositionX}},{{pickUp.metricPositionY}},{{pickUp.metricPositionYaw}}</td>
                    <td>
                        <label class="switch">
                            <input type="checkbox" [attr.checked]="pickUp.status == 'ACTIVE' ? true : null"
                                (click)="setPickupLocationStatus(pickUp.locationId,pickUp.status,pickUp.locationName)">
                            <span class="slider round"></span>
                        </label>
                    </td>
                    <td>
                        <select [(ngModel)]="selectedValue[i]"
                            (change)="assignHome(pickUp.locationId,selectedValue[i],i)">
                            <ng-container *ngFor="let robot of keyObject(robotIdList)">
                                <option [value]="robot"
                                    *ngIf="robotIdList[robot] == 'UNASSIGNED' || robot == selectedValue[i]">{{robot}}
                                </option>
                            </ng-container>
                        </select>
                        <button class="but_remove" (click)=removeRobot(pickUp.locationId,selectedValue[i],i)>REMOVE
                            ROBOT</button>
                    </td>
                    <td class="img">
                        <img src="../../assets/images/edit.svg" alt="Edit" class="img-fluid"
                            (click)="openEditLocation('editLocation',pickUp.locationId,pickUp.mapId,pickUp.locationName,pickUp.metricPositionX,pickUp.metricPositionY,pickUp.metricPositionYaw,pickUp.status,pickUp.locationType)">
                    </td>
                    <td class="img">
                        <img src="../../assets/images/delete.svg" alt="Delete" class="img-fluid"
                            (click)="openDeleteModal('delete',pickUp.locationId)">
                    </td>
                </tr>
                <td *ngIf="homeLocationList?.length == 0"> <span class="search_no_results">
                        No records found
                    </span>
                </td>

            </tbody>
        </table>
    </div>
</div>

<!-- Start Add Home Location Model -->
<div class="custom_modal_container" [class.show]="modalShow == 'addHomeLocation'">
    <div class="backdrop"></div>
    <div class="custom_modal_box">
        <h3>Add {{locationTypeHomeValue == 'HOME' ? 'HOME' : 'HOME'}} Location</h3>
        <form [formGroup]="addLocationForm" (ngSubmit)="AddHomeLocation()">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="Name">Location Name</label>
                        <input type="text" name="" formControlName="lname" placeholder="Location Name"
                            [ngClass]="{ 'is-invalid': submitted && p.lname.errors }">
                        <div *ngIf="submitted && p.lname.errors" class="invalid-feedback">
                            <div *ngIf="p.lname.errors.required">Please enter location name.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="LocX">Metric Position X</label>
                        <input type="text" name="" formControlName="locX" placeholder="Location X"
                            [ngClass]="{ 'is-invalid': submitted && p.locX.errors }">
                        <div *ngIf="submitted && p.locX.errors" class="invalid-feedback">
                            <div *ngIf="p.locX.errors.required">Please enter position X.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="LocY">Metric Position Y</label>
                        <input type="text" name="" formControlName="locY" placeholder="Location Y"
                            [ngClass]="{ 'is-invalid': submitted && p.locY.errors }">
                        <div *ngIf="submitted && p.locY.errors" class="invalid-feedback">
                            <div *ngIf="p.locY.errors.required">Please enter position Y.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="LocYAW">Metric Position Yaw</label>
                        <input type="text" name="" formControlName="locYaw" placeholder="Location Yaw"
                            [ngClass]="{ 'is-invalid': submitted && p.locYaw.errors }">
                        <div *ngIf="submitted && p.locYaw.errors" class="invalid-feedback">
                            <div *ngIf="p.locYaw.errors.required">Please enter position YAW.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Status</label>
                        <div class="switch_box">
                            <label class="switch">
                                <input type="checkbox" formControlName="statusValue">
                                <span class="slider round"></span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="btn_container">
                <button class="cancel" type="button" (click)="closeModal()">Cancel</button>
                <button class="add" type="submit">Save</button>
            </div>
        </form>
    </div>
</div>
<!-- End Add Home Location -->

<!-- Add Location -->
<div class="custom_modal_container" [class.show]="modalShow == 'addLocation'">
    <div class="backdrop"></div>
    <div class="custom_modal_box">
        <h3>Add {{locationTypeValue == 'PICKUP' ? 'PICKUP' : 'DROPOFF'}} Location</h3>
        <form [formGroup]="addLocationForm" (ngSubmit)="AddLocation()">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="Name">Location Name</label>
                        <input type="text" name="" formControlName="lname" placeholder="Location Name"
                            [ngClass]="{ 'is-invalid': submitted && p.lname.errors }">
                        <div *ngIf="submitted && p.lname.errors" class="invalid-feedback">
                            <div *ngIf="p.lname.errors.required">Please enter location name.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="LocX">Metric Position X</label>
                        <input type="text" name="" formControlName="locX" placeholder="Location X"
                            [ngClass]="{ 'is-invalid': submitted && p.locX.errors }">
                        <div *ngIf="submitted && p.locX.errors" class="invalid-feedback">
                            <div *ngIf="p.locX.errors.required">Please enter position X.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="LocY">Metric Position Y</label>
                        <input type="text" name="" formControlName="locY" placeholder="Location Y"
                            [ngClass]="{ 'is-invalid': submitted && p.locY.errors }">
                        <div *ngIf="submitted && p.locY.errors" class="invalid-feedback">
                            <div *ngIf="p.locY.errors.required">Please enter position Y.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="LocYAW">Metric Position Yaw</label>
                        <input type="text" name="" formControlName="locYaw" placeholder="Location Yaw"
                            [ngClass]="{ 'is-invalid': submitted && p.locYaw.errors }">
                        <div *ngIf="submitted && p.locYaw.errors" class="invalid-feedback">
                            <div *ngIf="p.locYaw.errors.required">Please enter position YAW.</div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-md-6">
                    <div class="form-group">
                        <label for="Logo">Logo</label>
                        <input type="text" name="" formControlName="logo" placeholder="Upload Logo" [ngClass]="{ 'is-invalid': submitted && p.logo.errors }">
                        <div *ngIf="submitted && p.logo.errors" class="invalid-feedback">
                            <div *ngIf="p.logo.errors.required">Please enter logo url.</div>
                        </div>
                    </div>
                </div> -->

                <!-- <div class="col-md-6">
                    <div class="form-group">
                        <label for="image">Logo</label>
                        <div class="upload_box">
                            <button type="button">Upload
                                <input type="file" name="" placeholder="Upload Logo" formControlName="logo" [ngClass]="{ 'is-invalid' : submitted && p.logo.errors }" (change)="addLocationLogo($event)">
                            </button>
                        </div>
                        <div *ngIf="submitted && p.logo.errors" class="invalid-feedback">
                            <div *ngIf="p.logo.errors.required">Please enter logo url.</div>
                        </div>
                    </div>
                </div> -->

                <div class="col-md-6">
                    <div class="form-group">
                        <label>Status</label>
                        <div class="switch_box">
                            <label class="switch">
                                <input type="checkbox" formControlName="statusValue">
                                <span class="slider round"></span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="btn_container">
                <button class="cancel" type="button" (click)="closeModal()">Cancel</button>
                <button class="add" type="submit">Save</button>
            </div>
        </form>
    </div>
</div>

<!-- Edit Location -->
<div class="custom_modal_container" [class.show]="modalShow == 'editLocation'">
    <div class="backdrop"></div>
    <div class="custom_modal_box">
        <h3>Edit {{editLocationType == 'PICKUP' ? 'PICKUP' : 'DROPOFF'}} Location</h3>
        <form [formGroup]="addLocationForm" (ngSubmit)="editLocation()">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="Name">Location Name</label>
                        <input type="text" name="" formControlName="lname" placeholder="Location Name"
                            [ngClass]="{ 'is-invalid': submitted && p.lname.errors }">
                        <div *ngIf="submitted && p.lname.errors" class="invalid-feedback">
                            <div *ngIf="p.lname.errors.required">Please enter location name.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="LocX">Metric Position X</label>
                        <input type="text" name="" formControlName="locX" placeholder="Location X"
                            [ngClass]="{ 'is-invalid': submitted && p.locX.errors }">
                        <div *ngIf="submitted && p.locX.errors" class="invalid-feedback">
                            <div *ngIf="p.locX.errors.required">Please enter location name.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="LocY">Metric Position Y</label>
                        <input type="text" name="" formControlName="locY" placeholder="Location Y"
                            [ngClass]="{ 'is-invalid': submitted && p.locY.errors }">
                        <div *ngIf="submitted && p.locY.errors" class="invalid-feedback">
                            <div *ngIf="p.locY.errors.required">Please enter location name.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="locYaw">Metric Position Yaw</label>
                        <input type="text" name="" formControlName="locYaw" placeholder="Location Yaw"
                            [ngClass]="{ 'is-invalid': submitted && p.locYaw.errors }">
                        <div *ngIf="submitted && p.locYaw.errors" class="invalid-feedback">
                            <div *ngIf="p.locYaw.errors.required">Please enter location name.</div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-md-6">
                    <div class="form-group">
                        <label for="Logo">Logo</label>
                        <input type="text" name="" formControlName="logo" placeholder="Upload Logo" [ngClass]="{ 'is-invalid': submitted && p.logo.errors }">
                        <div *ngIf="submitted && p.logo.errors" class="invalid-feedback">
                            <div *ngIf="p.logo.errors.required">Please enter location name.</div>
                        </div>
                    </div>
                </div> -->
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Status</label>
                        <div class="switch_box">
                            <label class="switch">
                                <input type="checkbox" formControlName="statusValue">
                                <span class="slider round"></span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="btn_container">
                <button class="cancel" type="button" (click)="closeModal()">Cancel</button>
                <button class="add" type="submit">Save</button>
            </div>
        </form>
    </div>
</div>

<!-- Add Map -->
<div class="custom_modal_container" [class.show]="modalShow == 'addMap'">
    <div class="backdrop" (click)="closeModal()"></div>
    <div class="custom_modal_box">
        <h3>Add Map</h3>
        <form [formGroup]="addMapForm" (ngSubmit)="AddMap()">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="Name">Map Name</label>
                        <input type="text" name="" id="Name" placeholder="Map Name" formControlName="name"
                            [ngClass]="{ 'is-invalid' : submitted && f.name.errors }">
                        <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                            <div *ngIf="f.name.errors.required">Please enter map name.</div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label for="Name">Map Height (Pixels)</label>
                        <input type="text" name="mapHeight" id="mapHeight" placeholder="Map Height"
                            formControlName="mapHeight" [ngClass]="{ 'is-invalid' : submitted && f.mapHeight.errors }">
                        <div *ngIf="submitted && f.mapHeight.errors" class="invalid-feedback">
                            <div *ngIf="f.mapHeight.errors.required">Please enter map height.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="image">Base Map Image</label>
                        <div class="upload_box">
                            <span>{{baseFileName}}</span>
                            <button type="button">Upload
                                <input type="file" name="" placeholder="Base Map Image" formControlName="bmap"
                                    [ngClass]="{ 'is-invalid' : submitted && f.bmap.errors }"
                                    (change)="addBaseMap($event)">
                            </button>
                        </div>
                        <div *ngIf="submitted && f.bmap.errors" class="invalid-feedback">
                            <div *ngIf="f.bmap.errors.required">Please enter base map image.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="Name">Map Width (Pixels)</label>
                        <input type="text" name="mapWidth" id="mapWidth" placeholder="Map Width"
                            formControlName="mapWidth" [ngClass]="{ 'is-invalid' : submitted && f.mapWidth.errors }">
                        <div *ngIf="submitted && f.mapWidth.errors" class="invalid-feedback">
                            <div *ngIf="f.mapWidth.errors.required">Please enter map width.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="image">Floor Plan Image</label>
                        <div class="upload_box">
                            <span>{{floorFileName}}</span>
                            <button type="button">Upload
                                <input type="file" name="" placeholder="Floor Plan Image" formControlName="fPlan"
                                    [ngClass]="{ 'is-invalid' : submitted && f.fPlan.errors }"
                                    (change)="addFloorMap($event)">
                            </button>
                        </div>
                        <div *ngIf="submitted && f.fPlan.errors" class="invalid-feedback">
                            <div *ngIf="f.fPlan.errors.required">Please enter floor plan image.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="image">No Go Zone Image</label>
                        <div class="upload_box">
                            <span>{{noGoFileName}}</span>
                            <button type="button">Upload
                                <input type="file" name="" placeholder="No Go Zone Image" formControlName="noGo"
                                    [ngClass]="{ 'is-invalid' : submitted && f.noGo.errors }"
                                    (change)="addNoGoMap($event)">
                            </button>
                        </div>
                        <div *ngIf="submitted && f.noGo.errors" class="invalid-feedback">
                            <div *ngIf="f.noGo.errors.required">Please enter no go zone image.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="image">Annotated Image</label>
                        <div class="upload_box">
                            <span>{{annotatedFileName}}</span>
                            <button type="button">Upload
                                <input type="file" name="" placeholder="Annotated Image" formControlName="annotated"
                                    [ngClass]="{ 'is-invalid' : submitted && f.annotated.errors }"
                                    (change)="addAnnotatedMap($event)">
                            </button>
                        </div>
                        <div *ngIf="submitted && f.annotated.errors" class="invalid-feedback">
                            <div *ngIf="f.annotated.errors.required">Please enter annotated image.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="Name">Map Origin X (Meters)</label>
                        <input type="text" name="mapOrigionX" id="mapOrigionX" placeholder="map Origion X"
                            formControlName="mapOrigionX"
                            [ngClass]="{ 'is-invalid' : submitted && f.mapOrigionX.errors }">
                        <div *ngIf="submitted && f.mapOrigionX.errors" class="invalid-feedback">
                            <div *ngIf="f.mapOrigionX.errors.required">Please enter mapOrigionX.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="Name">Map Origin Y (Meters)</label>
                        <input type="text" name="mapOrigionY" id="mapOrigionY" placeholder="map Origion Y"
                            formControlName="mapOrigionY"
                            [ngClass]="{ 'is-invalid' : submitted && f.mapOrigionY.errors }">
                        <div *ngIf="submitted && f.mapOrigionY.errors" class="invalid-feedback">
                            <div *ngIf="f.mapOrigionY.errors.required">Please enter mapOrigionY.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="Name">Map Scale</label>
                        <input type="text" name="mapScale" id="mapScale" placeholder="Map Scale"
                            formControlName="mapScale" [ngClass]="{ 'is-invalid' : submitted && f.mapScale.errors }">
                        <div *ngIf="submitted && f.mapScale.errors" class="invalid-feedback">
                            <div *ngIf="f.mapScale.errors.required">Please enter mapScale.</div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-md-6">
                    <div class="form-group">
                        <label>Active Map</label>
                        <div class="switch_box">
                            <label class="switch">
                                <input type="checkbox" formControlName="activeMap" [ngClass]="{ 'is-invalid' : submitted && f.activeMap.errors }">
                                <span class="slider round"></span>
                            </label>
                            <p class="active">Active</p>
                            <p class="inactive">Inactive</p>
                        </div>
                        <div *ngIf="submitted && f.activeMap.errors" class="invalid-feedback">
                            <div *ngIf="f.activeMap.errors.required">Please select active map status.</div>
                        </div>
                    </div>
                </div> -->
            </div>
            <div class="btn_container">
                <button class="cancel" type="button" (click)="closeModal()">Cancel</button>
                <button class="add" type="submit">Add</button>
            </div>
        </form>
    </div>
</div>

<!-- Edit Map -->
<div class="custom_modal_container" [class.show]="modalShow == 'editMap'">
    <div class="backdrop" (click)="closeModal()"></div>
    <div class="custom_modal_box">
        <h3>Add Map</h3>
        <form [formGroup]="addMapForm" (ngSubmit)="editMap()">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="Name">Map Name</label>
                        <input type="text" name="" id="Name" placeholder="Map Name" formControlName="name"
                            [ngClass]="{ 'is-invalid' : submitted && f.name.errors }">
                        <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                            <div *ngIf="f.name.errors.required">Please enter map name.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="image">Base Map Image</label>
                        <div class="upload_box">
                            <span>{{baseFileName}}</span>
                            <button type="button">Upload
                                <input type="file" name="" placeholder="Base Map Image" formControlName="bmap"
                                    [ngClass]="{ 'is-invalid' : submitted && f.bmap.errors }"
                                    (change)="addBaseMap($event)">
                            </button>
                        </div>
                        <div *ngIf="submitted && f.bmap.errors" class="invalid-feedback">
                            <div *ngIf="f.bmap.errors.required">Please enter base map image.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="image">Floor Plan Image</label>
                        <div class="upload_box">
                            <span>{{floorFileName}}</span>
                            <button type="button">Upload
                                <input type="file" name="" placeholder="Floor Plan Image" formControlName="fPlan"
                                    [ngClass]="{ 'is-invalid' : submitted && f.fPlan.errors }"
                                    (change)="addFloorMap($event)">
                            </button>
                        </div>
                        <div *ngIf="submitted && f.fPlan.errors" class="invalid-feedback">
                            <div *ngIf="f.fPlan.errors.required">Please enter floor plan image.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="image">No Go Zone Image</label>
                        <div class="upload_box">
                            <span>{{noGoFileName}}</span>
                            <button type="button">Upload
                                <input type="file" name="" placeholder="No Go Zone Image" formControlName="noGo"
                                    [ngClass]="{ 'is-invalid' : submitted && f.noGo.errors }"
                                    (change)="addNoGoMap($event)">
                            </button>
                        </div>
                        <div *ngIf="submitted && f.noGo.errors" class="invalid-feedback">
                            <div *ngIf="f.noGo.errors.required">Please enter no go zone image.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="image">Annotated Image</label>
                        <div class="upload_box">
                            <span>{{annotatedFileName}}</span>
                            <button type="button">Upload
                                <input type="file" name="" placeholder="Annotated Image" formControlName="annotated"
                                    [ngClass]="{ 'is-invalid' : submitted && f.annotated.errors }"
                                    (change)="addAnnotatedMap($event)">
                            </button>
                        </div>
                        <div *ngIf="submitted && f.annotated.errors" class="invalid-feedback">
                            <div *ngIf="f.annotated.errors.required">Please enter annotated image.</div>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <div class="form-group">
                        <label for="Name">Map Height (Pixels)</label>
                        <input type="text" name="mapHeight" id="mapHeight" placeholder="Map Height"
                            formControlName="mapHeight" [ngClass]="{ 'is-invalid' : submitted && f.mapHeight.errors }">
                        <div *ngIf="submitted && f.mapHeight.errors" class="invalid-feedback">
                            <div *ngIf="f.mapHeight.errors.required">Please enter map height.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="Name">Map Width (Pixels)</label>
                        <input type="text" name="mapWidth" id="mapWidth" placeholder="Map Width"
                            formControlName="mapWidth" [ngClass]="{ 'is-invalid' : submitted && f.mapWidth.errors }">
                        <div *ngIf="submitted && f.mapWidth.errors" class="invalid-feedback">
                            <div *ngIf="f.mapWidth.errors.required">Please enter map width.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="Name">Map Origin X (Meters)</label>
                        <input type="text" name="mapOrigionX" id="mapOrigionX" placeholder="map Origion X"
                            formControlName="mapOrigionX"
                            [ngClass]="{ 'is-invalid' : submitted && f.mapOrigionX.errors }">
                        <div *ngIf="submitted && f.mapOrigionX.errors" class="invalid-feedback">
                            <div *ngIf="f.mapOrigionX.errors.required">Please enter mapOrigionX.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="Name">Map Origin Y (Meters)</label>
                        <input type="text" name="mapOrigionY" id="mapOrigionY" placeholder="map Origion Y"
                            formControlName="mapOrigionY"
                            [ngClass]="{ 'is-invalid' : submitted && f.mapOrigionY.errors }">
                        <div *ngIf="submitted && f.mapOrigionY.errors" class="invalid-feedback">
                            <div *ngIf="f.mapOrigionY.errors.required">Please enter mapOrigionY.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="Name">Map Scale</label>
                        <input type="text" name="mapScale" id="mapScale" placeholder="Map Scale"
                            formControlName="mapScale" [ngClass]="{ 'is-invalid' : submitted && f.mapScale.errors }">
                        <div *ngIf="submitted && f.mapScale.errors" class="invalid-feedback">
                            <div *ngIf="f.mapScale.errors.required">Please enter mapScale.</div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-md-6">
                    <div class="form-group">
                        <label>Active Map</label>
                        <div class="switch_box">
                            <label class="switch">
                                <input type="checkbox" formControlName="activeMap" [ngClass]="{ 'is-invalid' : submitted && f.activeMap.errors }">
                                <span class="slider round"></span>
                            </label>
                            <p class="active">Active</p>
                            <p class="inactive">Inactive</p>
                        </div>
                        <div *ngIf="submitted && f.activeMap.errors" class="invalid-feedback">
                            <div *ngIf="f.activeMap.errors.required">Please select active map status.</div>
                        </div>
                    </div>
                </div> -->
            </div>
            <div class="btn_container">
                <button class="cancel" type="button" (click)="closeModal()">Cancel</button>
                <button class="add" type="submit">Save</button>
            </div>
        </form>
    </div>
</div>

<div class="custom_modal_container" [class.show]="modalShow == 'viewMap'">
    <div class="backdrop"></div>
    <div class="custom_modal_box map_height">
        <button class="cancel" (click)="closeModal()"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                class="feather feather-x">
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg></button>
        <img src="{{mapImageUrl}}" class="img-fluid fleet-map">
    </div>
</div>

<div class="custom_modal_container confirmation_modal" [class.show]="modalShow == 'deleteMap'">
    <div class="backdrop" (click)="closeModal()"></div>
    <div class="custom_modal_box">
        <p>Are you sure?</p>
        <div class="btn_container">
            <button class="cancel" (click)="closeModal()">Cancel</button>
            <button class="add" (click)="deleteMap()">Delete</button>
        </div>
    </div>
</div>

<div class="custom_modal_container confirmation_modal" [class.show]="modalShow == 'delete'">
    <div class="backdrop" (click)="closeModal()"></div>
    <div class="custom_modal_box">
        <p>Are you sure?</p>
        <div class="btn_container">
            <button class="cancel" (click)="closeModal()">Cancel</button>
            <button class="add" (click)="deleteLocation()">Delete</button>
        </div>
    </div>
</div>
<div class="bg">

    <!--Login Start-->
    <div class="form_box" *ngIf="login_box">
        <h1 class="logo" (click)="alertService.success('Success!!', options)">Ottonomy.IO</h1>
        <h3>Sign In</h3>
        <form [formGroup]="loginForm" (ngSubmit)="login()">
            <div class="form-group">
                <input type="email" class="form-control" formControlName="emailData" placeholder="Email Id" [ngClass]="{ 'is-invalid': submitted && f.emailData.errors }">
                <div *ngIf="submitted && f.emailData.errors" class="invalid-feedback">
                    <div *ngIf="f.emailData.errors.required">Please enter email id.</div>
                    <div *ngIf="f.emailData.errors.email">Please enter valid email address.</div>
                </div>
            </div>
            <div class="form-group password">

                <input [type]="passwordValue ? 'password' : 'text'" class="form-control" formControlName="passwordData" placeholder="Password" [ngClass]="{ 'is-invalid': submitted && f.passwordData.errors }">

                <svg (click)="passwordValue = !passwordValue" *ngIf="passwordValue" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"/><circle cx="12" cy="12" r="3"/></svg>

                <svg (click)="passwordValue = !passwordValue" *ngIf="!passwordValue" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye-off"><path d="M17.94 17.94A10.07 10.07 0 0112 20c-7 0-11-8-11-8a18.45 18.45 0 015.06-5.94M9.9 4.24A9.12 9.12 0 0112 4c7 0 11 8 11 8a18.5 18.5 0 01-2.16 3.19m-6.72-1.07a3 3 0 11-4.24-4.24M1 1l22 22"/></svg>

                <div *ngIf="submitted && f.passwordData.errors" class="invalid-feedback">
                    <div *ngIf="f.passwordData.errors.required">Please enter password.</div>
                </div>
            </div>
            <p class="invalid-feedback" *ngIf="errorMsg">Invaild login credential. Please try again.</p>
            <p class="forgot_btn"><span (click)="forgotBox()">Forgot Password?</span></p>
            <button>Sign In</button>
        </form>
    </div>
    <!--Login Start-->

    <!--Forgot Password Start-->
    <div class="form_box" *ngIf="forgot_box">
        <h1 class="logo">Ottonomy.IO</h1>
        <h3 class="mb-12">Forgot Password?</h3>
        <form [formGroup]="forgotForm" (ngSubmit)="forgot()">
            <div class="form-group">
                <input type="email" class="form-control" formControlName="forgotEmail" placeholder="Enter Email" [ngClass]="{ 'is-invalid': submitted && fr.forgotEmail.errors }">
                <div *ngIf="submitted && fr.forgotEmail.errors" class="invalid-feedback">
                    <div *ngIf="fr.forgotEmail.errors.required">Please enter Email Id.</div>
                    <div *ngIf="fr.forgotEmail.errors.email">Please enter valid email address.</div>
                </div>
            </div>
            <div class="btn_box">
                <button class="text-capitalize">Send</button>
                <button class="text-capitalize cancel" (click)="backForgot()">Back</button>
            </div>
        </form>
    </div>
    <!--Forgot Password End-->

    <!--OTP Start-->
    <!-- <div class="form_box" *ngIf="auth_box">
        <h1 class="logo">Ottonomy.IO</h1>
        <h3 class="mb-12" *ngIf="forgotTitle">2-Step Authentication</h3>
        <p class="text">Enter 6 digit number you have received on your email address.</p>
        <form [formGroup]="otpForm" (ngSubmit)="otp()">
            <div class="form-group">
                <input type="number" class="form-control" formControlName="OTP" placeholder="OTP" [ngClass]="{ 'is-invalid': submitted && o.OTP.errors }">
                <div *ngIf="submitted && o.OTP.errors" class="invalid-feedback">
                    <div *ngIf="o.OTP.errors.required">Please enter OTP.</div>
                    <div *ngIf="o.OTP.errors.minlength">Please enter valid OTP.</div>
                </div>
            </div>
            <button class="text-capitalize auth_btn">Continue</button>
        </form>
    </div> -->
    <!--OTP End-->
</div>
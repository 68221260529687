import { Component, OnInit , OnDestroy  } from '@angular/core';
import { OttonomyService } from '../services/ottonomy.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '../../app/services/alert.service';
import { $ } from 'protractor';
@Component({
  selector: 'app-admin-fleet-orders',
  templateUrl: './admin-fleet-orders.component.html',
  styleUrls: ['./admin-fleet-orders.component.scss']
})
export class AdminFleetOrdersComponent implements OnInit, OnDestroy  {
  // initializing page index for pagination
  p: number = 0;
  totalOrders: number= 0;
  addButton:boolean = false;
  placeOrderErrors:string;
  interval;
  robotStatusData;

  constructor(public ottonomyservice: OttonomyService,private formBuilder: FormBuilder,private route: ActivatedRoute,private router: Router, private alertService: AlertService) {
    router.events.subscribe(val => {
      this.getOrdersStatus();
    });
   }

  ngOnInit(): void {
    // Calling get orders api for page 1
    this.pageChanged(1);
    this.getLocationListStatus();
    this.getRobotStatus();
    this.addOrderForm = this.formBuilder.group({
      orderId: ['', Validators.required],
      pLocation: ['', Validators.required],
      dLocation: ['', Validators.required],
      qPick: ['', [Validators.required]],
      qDrop: ['', Validators.required]
    }) 

    // To peridically refreseh the orders list
    let that = this;   
    this.interval = setInterval(function() {
      that.pageChanged(that.p);
      that.getRobotStatus();
    }, 5000);

  }

  ngOnDestroy(){
    clearInterval(this.interval)
  }


  modalShow;
  openModal(value) {
    this.modalShow = value;
  }

  closeModal(){
    this.modalShow = '';
    this.addButton = false;
    this.placeOrderErrors = "";
  }

  //Get Location List
  public locationList
  public pickupLocationList = []
  public dropoffLocationList = []
  getLocationListStatus(){
    var body = {
      "fleetId" : this.route.snapshot.params.id
    }
    this.ottonomyservice.getLocationList(body).then(s => {
      this.locationList = s['data']
      this.pickupLocationList = [];
      this.dropoffLocationList = [];
      this.locationList.map((value) => {
       // // console.log(value.locationType)
        if(value.locationType == "PICKUP"){
          this.pickupLocationList.push(value);
        }
        if(value.locationType == "DROPOFF"){
          this.dropoffLocationList.push(value);
        }
      })

    })
  }

  //Get Orders List
  public OrdersList
  getOrdersStatus(){
    var body = {
      "fleetId" : this.route.snapshot.params.id
    }
    this.ottonomyservice.getOrdersListByFleetId(body).then(s => {
      this.OrdersList = s['data']
      //console.log(this.OrdersList)
    })
  }

  //Add Order
  addOrderForm: FormGroup;
  submitted = false;
  get f() { return this.addOrderForm.controls }

  AddOrder(){
    this.submitted = true;
    if(this.addOrderForm.invalid){
      return;
    }
    var body = {
      orderId: this.addOrderForm.get('orderId').value.trim(),
      fleetId: this.route.snapshot.params.id,
      pickupId: this.addOrderForm.get('pLocation').value,
      customerEmail: "prateek.gautam-oric@ottonomy.io",
      dropId: this.addOrderForm.get('dLocation').value,
      qrcodePickup: this.addOrderForm.get('qPick').value,
      qrcodeDrop: this.addOrderForm.get('qDrop').value,
      timeout: "30",
      timeout_beh: "0"
    }
    this.addButton = true;
    this.ottonomyservice.AddOrder(body).then(s => {
      this.placeOrderErrors = s["message"];
      if(s['status'] == 200){
        this.submitted = false;
        this.addOrderForm.reset();
        this.closeModal();
        this.pageChanged(this.p);
      }
      this.alertService.error(s['message']);
    })
  }



  orderId;
  deleteModal(value,id){
    this.modalShow = value;
    this.orderId = id;
  }
  canceloOrder(){

    var body = {
      "orderId" : this.orderId,
      "fleetId" : this.route.snapshot.params.id
    }
    //console.log(body)
    this.ottonomyservice.cancelOrder(body).then(s => {
      this.modalShow = '';
      this.pageChanged(this.p);
      setTimeout(() => {
        this.alertService.error(s['message']);
      }, 1500);
    })


  }



  openDoorByOrderId(id){
    var body = {
      "orderId" : id,
      "fleetId" : this.route.snapshot.params.id
    }
    this.ottonomyservice.openDoorByOrderId(body).then(s => {
      setTimeout(() => {
        this.alertService.error(s['message']);
      }, 1500);
      //console.log(s);
    })
  }

  pageChanged(event) {
    var body = {
      "fleetId":this.route.snapshot.params.id,
      "pageNo": event,
      "size": "10",
    }
    this.p = event;
    this.ottonomyservice.getOrdersListByFleetIdPagination(body).then(s => {
        this.OrdersList = s['data'].order;            
        this.totalOrders = s['data'].count;
        //console.log(this.OrdersList);
    })
  }

  getRobotStatus(){
      var body = {
        "fleetId": this.route.snapshot.params.id
      }
      this.ottonomyservice.getRobotData(body).then(s => {
        if(s['status'] == 200){
          this.robotStatusData = s['data'];
          console.log(this.robotStatusData);
        }
      })
    
  }
}



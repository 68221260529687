

<div class="malfunction_box">
    <div class="alert_box">
        <div class="hori_center">
            <img src="../../assets/images/warning.png" alt="Alert" width="30" height="20">
            <div class="alert-text">Robot Malfunction</div>
        </div>
        
    </div>       
    <!-- <div class="robot_malfunction_alert"> 
        
        
        <br>

        <div class="alert-text">           
           <span>
            Robot Malfunction <br>
            Switch to Manual Control ! 
           </span>            
        </div>
    </div> -->
</div>
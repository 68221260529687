import { Component, OnInit } from '@angular/core';
import { OttonomyService } from '../services/ottonomy.service';
@Component({
  selector: 'app-admin-robot',
  templateUrl: './admin-robot.component.html',
  styleUrls: ['./admin-robot.component.scss']
})
export class AdminRobotComponent implements OnInit {
  constructor(public ottonomyservice: OttonomyService) { }
  userDetail: any;
  userRole:any;
  roleValue;
  ngOnInit(): void {
    this.userDetail = JSON.parse(sessionStorage.getItem('user'));
    //// console.log(this.userDetail);
    this.getRobotStatusByAdmin();
    this.getFleetStatus();
  }
  //Get Robots List
  public RobotsList
  getRobotStatusByAdmin(){
    var body = {
      "adminEmail" : this.userDetail.emailId
    }
    this.ottonomyservice.getRobotsListByAdminEmail(body).then(s => {
      this.RobotsList = s['data']
      for(let i = 0; i < this.RobotsList.length; i++) {
        this.selectedValue[i] = this.RobotsList[i].fleetId
      }
    })
  }

  //Get Fleet Data
  FleetStatusData = [];
  getFleetStatus(){
    var body = {
      "emailId": this.userDetail.emailId
    }
    this.ottonomyservice.getFleetListByAdminEmailData(body).then(s => {
      this.FleetStatusData = s['fleet']
      //// console.log(this.FleetStatusData)
    })
  }
  selectedValue:any = [];
  assignFleet(robot,fleet){
    //// console.log(robot,fleet)
    var body = {
      robotId: robot,
      fleetId: fleet
    }
    this.ottonomyservice.assignRobotToFleet(body).then(s => {
      //// console.log(s['message'])
    })
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { OttonomyService } from '../services/ottonomy.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '../../app/services/alert.service';
import { of } from 'rxjs';
@Component({
  selector: 'app-admin-fleet-map',
  templateUrl: './admin-fleet-map.component.html',
  styleUrls: ['./admin-fleet-map.component.scss']
})
export class AdminFleetMapComponent implements OnInit {
  @Input() fleetIdFromNavbar: any[];
  constructor(public ottonomyservice: OttonomyService, private formBuilder: FormBuilder, private route: ActivatedRoute, private router: Router, private alertService: AlertService) {
    router.events.subscribe(val => {
      this.getMapStatus();
      this.getRobotList();
    });
  }

  robotList = [];
  robotIdList = {};
  selectedValue = [];

  ngOnInit(): void {
    this.getMapStatus();
    this.getRobotList();
    console.log(this.robotIdList);
    this.addMapForm = this.formBuilder.group({
      name: ['', Validators.required],
      bmap: ['', Validators.required],
      fPlan: ['', [Validators.required,]],
      noGo: ['', Validators.required],
      annotated: ['', Validators.required],
      mapHeight: ['', Validators.required],
      mapWidth: ['', Validators.required],
      mapOrigionX: ['', Validators.required],
      mapOrigionY: ['', Validators.required],
      mapScale: ['', Validators.required],
      //activeMap: ['', Validators.required],
    });

    this.addLocationForm = this.formBuilder.group({
      lname: ['', Validators.required],
      locX: ['', Validators.required],
      locY: ['', [Validators.required,]],
      locYaw: ['', Validators.required],
      logo: [''],
      statusValue: [false],
    });
  }


  modalShow;
  openModal(value) {
    this.modalShow = value;
    this.baseFileName = '';
    this.floorFileName = '';
    this.noGoFileName = '';
    this.annotatedFileName = '';
  }
  closeModal() {
    this.modalShow = '';
    this.addMapForm.reset();
    this.addLocationForm.reset();
  }
  mapImageUrl = '';
  viewMapModal(url) {
    this.modalShow = 'viewMap';
    this.mapImageUrl = url;
  }
  //Get Maps List
  public MapsList
  baseMapImageUrl = '';
  floorplanImageUrl = '';
  annotationImageUrl = '';
  noGoZoneImageUrl = '';
  mapName = '';
  mapHeight = '';
  mapWidth = '';
  mapOrigionX = '';
  mapOrigionY = '';
  mapScale = '';
  mapIdValue = '';
  mapListLength;
  getMapStatus() {
    var body = {
      "fleetId": this.route.snapshot.params.id
    }
    this.MapsList = [];
    this.ottonomyservice.getMapsList(body).then(s => {
      this.MapsList = s['data'];
      this.baseMapImageUrl = '';
      this.floorplanImageUrl = '';
      this.annotationImageUrl = '';
      this.noGoZoneImageUrl = '';
      this.mapHeight = '';
      this.mapWidth = '';
      this.mapOrigionX = '';
      this.mapOrigionY = '';
      this.mapScale = '';

      if (s['data'] != null) {
        this.mapIdValue = this.MapsList.mapId;
        this.baseMapImageUrl = this.MapsList.baseMapImageUrl;
        this.floorplanImageUrl = this.MapsList.floorplanImageUrl;
        this.annotationImageUrl = this.MapsList.annotationImage;
        this.noGoZoneImageUrl = this.MapsList.noGoZoneImageUrl;
        this.mapName = this.MapsList.mapName;
        this.mapHeight = this.MapsList.mapHeight;
        this.mapWidth = this.MapsList.mapWidth;
        this.mapOrigionX = this.MapsList.mapOrigionX;
        this.mapOrigionY = this.MapsList.mapOrigionY;
        this.mapScale = this.MapsList.mapScale;
      }
    })
  }

  //Get Location List
  public locationList
  public pickupLocationList = []
  public dropoffLocationList = []
  public homeLocationList = []
  getLocationListStatus() {

    var body = {
      "fleetId": this.route.snapshot.params.id
    }
    this.ottonomyservice.getLocationList(body).then(s => {
      this.locationList = s['data']
      this.pickupLocationList = [];
      this.dropoffLocationList = [];
      this.homeLocationList = [];
      this.locationList.map((value) => {
        if (value.locationType == "PICKUP") {
          this.pickupLocationList.push(value);
        }
        if (value.locationType == "DROPOFF") {
          this.dropoffLocationList.push(value);
          console.log(this.dropoffLocationList);
        }
        if (value.locationType == "HOME") {
          this.homeLocationList.push(value);
          this.robotIdList[value.homeRobot] = "ASSIGNED";
          this.selectedValue.push(value.homeRobot);
        }
      })
    })
  }

  statusValue = '';
  setPickupLocationStatus(id, value, locationName) {
    if (value == "ACTIVE") {
      // this.statusValue = "deactivate"
      this.statusValue = "INACTIVE"
    } else {
      this.statusValue = "ACTIVE"
    }
    var body = {
      "locationId": id,
      "locationName": locationName,
      "status": this.statusValue,
      "fleetId": this.route.snapshot.params.id
    }
    this.ottonomyservice.setLocationStatus(body).then((s) => {
      this.getLocationListStatus();
    })
  }

  setDropoffLocationStatus(id, value, locationName) {
    if (value == "ACTIVE") {
      //this.statusValue = "deactivate"
      this.statusValue = "INACTIVE"
    } else {
      this.statusValue = "ACTIVE"
    }
    var body = {
      "locationId": id,
      "locationName": locationName,
      "status": this.statusValue,
      "fleetId": this.route.snapshot.params.id
    }
    this.ottonomyservice.setLocationStatus(body).then((s) => {
      this.getLocationListStatus();
    })
  }

  setDropoffWanderStatus(id, value, locationName) {
    if(value != 2){
      let wanderStatus;
      if (value == 1) {
        wanderStatus = 0
      } else {
        wanderStatus = 1
      }
      var body = {
        "locationId": id,
        "locationName": locationName,
        "status": wanderStatus,
        "fleetId": this.route.snapshot.params.id
      }
      this.ottonomyservice.setWanderStatus(body).then((s) => {
        this.getLocationListStatus();
      })
    } else{
      this.alertService.error("The location Wander status can't be changed");
      this.getLocationListStatus();
    }
    
  }

  locationTypeValue = '';
  openLocationModal(value, type) {
    this.modalShow = value;
    this.locationTypeValue = type;
  }

  locationTypeHomeValue = '';
  openHomeLocationModal(value, type) {
    this.modalShow = value;
    this.locationTypeHomeValue = type;
  }

  /* Upload Location Logo */
  location;
  locationFileName = '';
  locationUploadUrl = '';
  addLocationLogo(event) {
    if (event.target.files.length > 0) {
      this.location = event.target.files[0];
      this.locationFileName = this.location.name;
    }
    const formData = new FormData();
    formData.append("imageLocationUrl", this.location);
    this.ottonomyservice.uploadLocationLogo(formData).then(s => {
      if (s['status'] == 200) {
        this.locationUploadUrl = s['data'];
      } else {
        return;
      }
    })
  }

  //Add Pick Up Location
  addLocationForm: FormGroup;
  get p() { return this.addLocationForm.controls }

  AddLocation() {
    this.submitted = true;
    if (this.addLocationForm.invalid) {
      return;
    }
    let statusValue = 'INACTIVE';
    let value = this.addLocationForm.get('statusValue').value;
    if (value == false) {
      this.statusValue = "INACTIVE"
    } else if (value == null) {
      this.statusValue = "INACTIVE"
    } else {
      this.statusValue = "ACTIVE"
    }
    var body = {
      mapId: this.mapIdValue,
      locationName: this.addLocationForm.get('lname').value,
      metricPositionX: this.addLocationForm.get('locX').value,
      metricPositionY: this.addLocationForm.get('locY').value,
      metricPositionYaw: this.addLocationForm.get('locYaw').value,
      status: this.statusValue,
      locationType: this.locationTypeValue,
      imageLocationUrl: null
    }
    this.ottonomyservice.addLocation(body).then(s => {
      if (s['status'] == 200) {
        this.submitted = false;
        this.addLocationForm.reset();
        this.closeModal();
        this.getLocationListStatus();
      }
      this.alertService.error(s['message']);
    })
  }

  //Add Home Location
  AddHomeLocation() {
    this.submitted = true;
    if (this.addLocationForm.invalid) {
      return;
    }
    let statusValue = 'INACTIVE';
    let value = this.addLocationForm.get('statusValue').value;
    if (value == false) {
      this.statusValue = "INACTIVE"
    } else if (value == null) {
      this.statusValue = "INACTIVE"
    } else {
      this.statusValue = "ACTIVE"
    }
    var body = {
      mapId: this.mapIdValue,
      locationName: this.addLocationForm.get('lname').value,
      metricPositionX: this.addLocationForm.get('locX').value,
      metricPositionY: this.addLocationForm.get('locY').value,
      metricPositionYaw: this.addLocationForm.get('locYaw').value,
      status: this.statusValue,
      locationType: this.locationTypeHomeValue,
      imageLocationUrl: null
    }
    this.ottonomyservice.addLocation(body).then(s => {
      if (s['status'] = 200) {
        this.alertService.success(s['message']);
        this.submitted = false;
        this.addLocationForm.reset();
        this.closeModal();
        this.getLocationListStatus();
      } else {
        this.alertService.error(s['message']);
        return;
      }
    })
  }

  editLocationType = '';
  editLocationId = '';
  editLocationMapId = '';
  editStatusValue: any;
  openEditLocation(value, locationId, mapId, locationName, locationX, locationY, locationYaw, locationStatus, locationType) {
    this.modalShow = value;
    this.editLocationMapId = mapId;
    this.editLocationId = locationId;
    this.editLocationType = locationType;
    if (locationStatus == 'ACTIVE') {
      this.editStatusValue = true
    } else {
      this.editStatusValue = false
    }
    this.addLocationForm = this.formBuilder.group({
      lname: [locationName, Validators.required],
      locX: [locationX, Validators.required],
      locY: [locationY, [Validators.required,]],
      locYaw: [locationYaw, Validators.required],
      // logo: [locationLogo, Validators.required],
      statusValue: [this.editStatusValue],
    });
  }

  editLocation() {
    this.submitted = true;
    if (this.addLocationForm.invalid) {
      return;
    }
    let statusValue = 'INACTIVE';
    let value = this.addLocationForm.get('statusValue').value;
    if (value == false) {
      this.statusValue = "INACTIVE"
    } else if (value == null) {
      this.statusValue = "INACTIVE"
    } else {
      this.statusValue = "ACTIVE"
    }
    var body = {
      locationId: this.editLocationId,
      mapId: this.editLocationMapId,
      locationName: this.addLocationForm.get('lname').value,
      metricPositionX: this.addLocationForm.get('locX').value,
      metricPositionY: this.addLocationForm.get('locY').value,
      metricPositionYaw: this.addLocationForm.get('locYaw').value,
      status: this.statusValue,
      locationType: this.editLocationType,
      imageLocationUrl: null,
      // imageLocationUrl: this.addLocationForm.get('logo').value,
    }
    this.ottonomyservice.editLocation(body).then(s => {
      if (s['status'] == 200) {
        this.submitted = false;
        this.addLocationForm.reset();
        this.closeModal();
        this.getLocationListStatus();
      }
      this.alertService.error(s['message']);
    })
  }

  deleteLocationId = ' ';
  openDeleteModal(value, id) {
    this.modalShow = value;
    this.deleteLocationId = id;
  }

  // Delete Location
  deleteLocation() {
    var body = {
      locationId: this.deleteLocationId,
    };
    this.ottonomyservice.deleteLocation(body).then(s => {
      if (s['status'] == 200) {
        this.closeModal()
        this.getLocationListStatus();
      }
      this.alertService.error(s['message']);
    })
  }

  //Add Map
  addMapForm: FormGroup;
  submitted = false;
  get f() { return this.addMapForm.controls }

  AddMap() {
    this.submitted = true;
    if (this.addMapForm.invalid) {
      return;
    }
    var body = {
      fleetId: this.route.snapshot.params.id,
      mapName: this.addMapForm.get('name').value,
      baseMapImageUrl: this.baseFileUploadUrl,
      floorplanImageUrl: this.floorFileUploadUrl,
      noGoZoneImageUrl: this.noGoFileUploadUrl,
      annotationImage: this.annotatedFileUploadUrl,
      mapHeight: this.addMapForm.get('mapHeight').value,
      mapWidth: this.addMapForm.get('mapWidth').value,
      mapOrigionX: this.addMapForm.get('mapOrigionX').value,
      mapOrigionY: this.addMapForm.get('mapOrigionY').value,
      mapScale: this.addMapForm.get('mapScale').value,
      mapStatus: true,
    }
    this.ottonomyservice.AddMap(body).then(s => {
      // console.log('-> AddMap :', s)
      if (s['status'] == 200) {
        this.submitted = false;
        this.addMapForm.reset();
        this.closeModal();
        this.getMapStatus();
      }
      this.alertService.error(s['message']);
    })
  }

  // Upload Base File
  basefile;
  baseFileName = '';
  baseFileUploadUrl = '';
  addBaseMap(event) {
    if (event.target.files.length > 0) {
      this.basefile = event.target.files[0];
      this.baseFileName = this.basefile.name;
    }
    const formData = new FormData();
    formData.append("baseMapImageUrl", this.basefile);
    this.ottonomyservice.uploadBaseMap(formData).then(s => {
      if (s['status'] == 200) {
        this.baseFileUploadUrl = s['url'];
      }
      this.alertService.error(s['message']);
    })
  }

  // Upload Floor File
  floorfile;
  floorFileName = '';
  floorFileUploadUrl = '';
  addFloorMap(event) {
    if (event.target.files.length > 0) {
      this.floorfile = event.target.files[0];
      this.floorFileName = this.floorfile.name;
    }
    const formData = new FormData();
    formData.append("floorplanImageUrl", this.floorfile);
    this.ottonomyservice.uploadfloorMap(formData).then(s => {
      if (s['status'] == 200) {
        this.floorFileUploadUrl = s['url'];
      }
      this.alertService.error(s['message']);
    })
  }

  // Upload No GO File
  noGofile;
  noGoFileName = '';
  noGoFileUploadUrl = '';
  addNoGoMap(event) {
    if (event.target.files.length > 0) {
      this.noGofile = event.target.files[0];
      this.noGoFileName = this.noGofile.name;
    }
    const formData = new FormData();
    formData.append("noGoZoneImageUrl", this.noGofile);
    this.ottonomyservice.uploadNoGoMap(formData).then(s => {
      if (s['status'] == 200) {
        this.noGoFileUploadUrl = s['url'];
      }
      this.alertService.error(s['message']);
    })
  }

  //Upload Annotation File
  annotatedfile;
  annotatedFileName = '';
  annotatedFileUploadUrl = '';
  addAnnotatedMap(event) {
    if (event.target.files.length > 0) {
      this.annotatedfile = event.target.files[0];
      this.annotatedFileName = this.annotatedfile.name;
    }
    const formData = new FormData();
    formData.append("annotationImage", this.annotatedfile);
    this.ottonomyservice.uploadAnnotatedMap(formData).then(s => {
      if (s['status'] == 200) {
        this.annotatedFileUploadUrl = s['url'];
      }
      this.alertService.error(s['message']);
    })
  }

  editMapId = '';
  editFleetId = '';
  openEditMap(value, mapId, fleetID, name, burl, fUrl, aUrl, nUrl, mapHeight, mapWidth, mapOrigionX, mapOrigionY, mapScale) {
    this.modalShow = value;
    this.editMapId = mapId;
    this.editFleetId = fleetID;
    this.addMapForm = this.formBuilder.group({
      name: [name, Validators.required],
      bmap: ['', Validators.required],
      fPlan: ['', [Validators.required,]],
      annotated: ['', Validators.required],
      noGo: ['', Validators.required],
      mapHeight: [mapHeight, Validators.required],
      mapWidth: [mapWidth, Validators.required],
      mapOrigionX: [mapOrigionX, Validators.required],
      mapOrigionY: [mapOrigionY, Validators.required],
      mapScale: [mapScale, Validators.required],
      // activeMap: [this.editStatusValue, Validators.required],
    });
    this.baseFileName = burl.substring(burl.lastIndexOf('/') + 1);
    this.floorFileName = fUrl.substring(fUrl.lastIndexOf('/') + 1);
    this.noGoFileName = nUrl.substring(nUrl.lastIndexOf('/') + 1);
    this.annotatedFileName = aUrl.substring(aUrl.lastIndexOf('/') + 1);
    this.baseFileUploadUrl = burl;
    this.floorFileUploadUrl = fUrl;
    this.noGoFileUploadUrl = nUrl;
    this.annotatedFileUploadUrl = aUrl;
  }

  editMap() {
    this.submitted = true;
    // if(this.addMapForm.invalid){
    //   return;
    // }
    var body = {
      mapId: this.editMapId,
      fleetId: this.editFleetId,
      mapName: this.addMapForm.get('name').value,
      baseMapImageUrl: this.baseFileUploadUrl,
      floorplanImageUrl: this.floorFileUploadUrl,
      noGoZoneImageUrl: this.noGoFileUploadUrl,
      annotationImage: this.annotatedFileUploadUrl,
      mapHeight: this.addMapForm.get('mapHeight').value,
      mapWidth: this.addMapForm.get('mapWidth').value,
      mapOrigionX: this.addMapForm.get('mapOrigionX').value,
      mapOrigionY: this.addMapForm.get('mapOrigionY').value,
      mapScale: this.addMapForm.get('mapScale').value,

      mapStatus: true,
    }
    this.ottonomyservice.editMap(body).then(s => {
      if (s['status'] == 200) {
        this.submitted = false;
        this.addMapForm.reset();
        this.closeModal();
        this.getMapStatus();
      }
      this.alertService.error(s['message']);
    })
  }

  deleteMapId = '';
  openMapDeleteModal(value, id) {
    this.modalShow = value;
    this.deleteMapId = id;
  }

  // Delete Map
  deleteMap() {
    var body = {
      mapId: this.deleteMapId,
    };
    this.ottonomyservice.deleteMap(body).then(s => {
      if (s['status'] == 200) {
        this.closeModal()
        this.getMapStatus();
      }
      this.alertService.error(s['message']);
    })
  }


  // Robot List
  getRobotList() {
    let body = {
      "fleetId": this.fleetIdFromNavbar
    }
    this.ottonomyservice.getRobotsListByFleetId(body).then(s => {
      // console.log(s);
      this.robotList = s['data'];
      this.robotList.map((value) => {
        this.robotIdList[value.robotId] = "UNASSIGNED";
      })
      this.getLocationListStatus();
    })
  }

  // Assigining a home to a robot
  assignHome(home, robotId, index) {
    let body = {
      robotId: robotId,
      locationId: home
    }
    this.ottonomyservice.assignRobotToHome(body).then(s => {
      this.getRobotList();

    })

  }

  removeRobot(home, robotId, index) {
    let body = {
      robotId: robotId,
      locationId: home
    }
    this.ottonomyservice.removeRobotFromHome(body).then(s => {
      this.getRobotList();
      this.selectedValue[index] = "";
    })

  }

  keyObject(obj) {
    return Object.keys(obj);
  }


}

import { Component, OnInit } from '@angular/core';
import { OttonomyService } from '../services/ottonomy.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertService } from '../../app/services/alert.service';
@Component({
  selector: 'app-admin-fleet-users',
  templateUrl: './admin-fleet-users.component.html',
  styleUrls: ['./admin-fleet-users.component.scss']
})
export class AdminFleetUsersComponent implements OnInit {
  
  passwordValue = true;
  constructor(public ottonomyservice: OttonomyService,private formBuilder: FormBuilder, private alertService: AlertService) { }
  userDetail:any;
  userRole:any;
  roleValue;

  ngOnInit(): void {
    this.userDetail = JSON.parse(sessionStorage.getItem('user'));


    this.getFleetStatus();
    this.getUserStatus();
    this.addUserForm = this.formBuilder.group({
      uname: ['', Validators.required],
      fname: ['', Validators.required],
      lname: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      pwd: ['', Validators.required],
      role: ['', Validators.required],
      number: ['', Validators.required],
      fleet: ['', Validators.required],
      pin: ['', Validators.required],
      logo: ['']
    })
    setTimeout(() => {
        this.generateFleet();
      },2000
    )

  }
  modalShow;
  openModal(value) {
    this.modalShow = value;
  }
  closeModal(){
    this.modalShow = '';
    this.addUserForm.reset();
  }

  //Get Users List
  public UsersList
  getUserStatus(){
    this.ottonomyservice.getUsersList().then(s => {
      this.UsersList = s['data']
    })
  }

    // Upload Profile Image
    user
    userFileName = '';
    userFileUploadUrl = '';
    uploadProfileImage(event) {
      if (event.target.files.length > 0) {
        this.user = event.target.files[0];
        this.userFileName = this.user.name;
      }
      const formData = new FormData();
      formData.append("imageUrl", this.user);
      this.ottonomyservice.uploadProfileImage(formData).then(s => {
        if(s['status'] == 200){
          this.userFileUploadUrl = s['data'];
        }else{
          return;
        }
      })
    }

  //Add User
  addUserForm: FormGroup;
  submitted = false;
  get f() { return this.addUserForm.controls }

  AddUser(){
    this.submitted = true;
    if(this.addUserForm.invalid){
      return;
    }
    var body = {
      adminId: this.userDetail.adminId,
      imageUrl: this.userFileUploadUrl,
      userName: this.addUserForm.get('uname').value,
      firstName: this.addUserForm.get('fname').value,
      lastName: this.addUserForm.get('lname').value,
      emailId: this.addUserForm.get('email').value,
      password: this.addUserForm.get('pwd').value,
      role: this.addUserForm.get('role').value,
      fleetId: this.addUserForm.get('fleet').value,
      contactNumber: this.addUserForm.get('number').value,
      otp: "222222",
      pin: this.addUserForm.get('pin').value,
      //imageUrl: this.addUserForm.get('logo').value,
    }

    this.ottonomyservice.AddUser(body).then(s => {
      // console.log('s :', s);
      if(s['status'] == 200){
        this.submitted = false;
        this.addUserForm.reset();
        this.closeModal();
        this.getUserStatus();
      }
      this.alertService.error(s['message']);
    })
  }

  //Edit User
  get e() { return this.addUserForm.controls }
  editUserId:any = '';
  openEditModel(value,userId,userName,userFirstName,userLastName,userEmail,userPassword,userNumber,userFleet,userOtp,userRole,userImage,pin) {
    this.modalShow = value;
    this.editUserId = userId;
    this.addUserForm = this.formBuilder.group({
      uname: [userName, Validators.required],
      fname: [userFirstName, Validators.required],
      lname: [userLastName, Validators.required],
      email: [userEmail, [Validators.required, Validators.email]],
      pwd: [userPassword, Validators.required],
      number: [userNumber, Validators.required],
      fleet: [userFleet, Validators.required],
      otp: [userOtp, Validators.required],
      role: [userRole, Validators.required],
      pin: [pin, Validators.required],
      logo: [userImage]
    });
  }

  EditUser(){
    this.submitted = true;
    if(this.addUserForm.invalid){
      return;
    }
    var body = {
      userId: this.editUserId,
      userName: this.addUserForm.get('uname').value,
      firstName: this.addUserForm.get('fname').value,
      lastName: this.addUserForm.get('lname').value,
      emailId: this.addUserForm.get('email').value,
      // password: this.addUserForm.get('pin').value,
      fleetId: this.addUserForm.get('fleet').value,
      role: this.addUserForm.get('role').value,
      contactNumber: this.addUserForm.get('number').value,
      otp: "222222",
      pin: this.addUserForm.get('pin').value,
      imageUrl: this.addUserForm.get('logo').value ? this.addUserForm.get('logo').value : '',
    }

    this.ottonomyservice.EditUser(body).then(s => {
      if(s['status'] == 200){
        this.submitted = false;
        this.addUserForm.reset();
        this.closeModal();
        this.getUserStatus();
      }
      this.alertService.error(s['message']);
    })
  }

  // Delete User
  userId:any = '';
  openDeleteModal(value,id) {
    this.modalShow = value;
    this.userId = id;
  }
  deleteUser(){
    var body = {
      userId: this.userId,
    };
    this.ottonomyservice.DeleteUser(body).then(s => {
      if(s['status'] == 200){
        this.closeModal()
        this.getUserStatus();
      }
      this.alertService.error(s['message']);
    })
  }

   //Get Fleet Data
   FleetStatusData = [];
   getFleetStatus(){
     var body = {
       "emailId": this.userDetail.emailId
     }
     this.ottonomyservice.getFleetListByAdminEmailData(body).then(s => {
       this.FleetStatusData = s['fleet']
     })
   }


  selectedFleet: number;
  obj:any;
  fleets = [];
  generateFleet(){
    for(let i = 0; i < this.FleetStatusData.length; i++){
      this.obj = {};
      this.obj[i] = {id: this.FleetStatusData[i]._id, name: this.FleetStatusData[i].fleetName};
      this.fleets.push(this.obj);
    }
  }

  fleetIdList = [];
  selectFleet(val){
    this.fleetIdList = val;
  }
}

import { Component, OnInit,Input, OnDestroy } from '@angular/core';
import { OttonomyService } from '../services/ottonomy.service';
import { Router } from '@angular/router';
import { AlertService } from '../../app/services/alert.service';
@Component({
  selector: 'app-fleet',
  templateUrl: './fleet.component.html',
  styleUrls: ['./fleet.component.scss']
})
export class FleetComponent implements OnInit, OnDestroy {
  @Input() fleetIdFromNavbar: any [];
  public RobotStatusData:any = [];
  testingMode: any = [];
  interval:any;
  constructor(private router: Router,public ottonomyservice: OttonomyService, private alertService: AlertService) { }

  ngOnInit(): void {
    const userDetail = JSON.parse(sessionStorage.getItem('user'));

    

    this.interval = setInterval(() => {

      // Periodically calls checkavailablity function so that 
      // it can update the status of the fleet in the database
      this.checkAvailability();
      this.getRobotStatus();
    },2000)
  }

  ngOnDestroy(){
    clearInterval(this.interval);
  }


  playPuase:any;
  play:any = '';
  pause:any = '';
  robotPlay(id){
    var body = {
      'robotId': id
    }
    this.ottonomyservice.playRobot(body).then(s => {
      if(s['status'] == 200) {
        this.pause = id;
        this.play = id;
      }
    })
  }

  // Function to check if the fleet in which robot is assigned is available or not
  checkAvailability(){
    var body = {
      'fleetId': this.fleetIdFromNavbar
    }
    this.ottonomyservice.checkFleetAvailability(body).then(s => {
    })
  }

  

  robotPause(id){
    var body = {
      'robotId': id
    }
    this.ottonomyservice.pauseRobot(body).then(s => {
      if(s['status'] == 200){
        this.pause = ' ';
        this.play = ' ';
      }
    })
  }

  setPlayPause(RobotStatusData){
    if(RobotStatusData.statusMessage == "RUN"){
      this.pause == RobotStatusData.robotId;
    }else{
      this.pause == RobotStatusData.robotId;
    }
  }
  showRobotList = true;
  availableRobotList = [];
  chargingRobotList = [];
  obstacleCounter:any = [0];
  obstacleFlag:any = [];

  getRobotStatus(){
    if(this.fleetIdFromNavbar != undefined){
      var body = {
        "fleetId": this.fleetIdFromNavbar,
      }
      this.ottonomyservice.getRobotData(body).then(s => {
        if(s['status'] == 200){
          this.showRobotList = true;
          this.RobotStatusData = s['data'];
          for(var i=0; i<this.RobotStatusData.length; i++){
            this.obstacleCounter.push(0);
          }
          for(var i=0; i<this.RobotStatusData.length; i++){
            this.testingMode[i] = this.RobotStatusData[i][0]["testingMode"];
            console.log(this.testingMode[i]);
            if(this.RobotStatusData[i][0]["robotRunningState"] == "AUTO_OBSTACLE"){
              this.obstacleCounter[i] += 1;
              if(this.obstacleCounter[i] >= 5){
                this.obstacleFlag[i] = true;
              }
            } 
            else if(this.obstacleCounter[i] >= 5 && this.RobotStatusData[i][0]["robotRunningState"] != "AUTO_OBSTACLE"){
              this.obstacleFlag[i] = false;
              this.obstacleCounter[i] = 0;
            }
            else{
              this.obstacleCounter[i] = 0;
            }
          }
        }
      })
    }
  }
  robotDetail(id) {
    this.router.navigate(['/robot-detail',id]);
  }
}

<app-admin-navbar></app-admin-navbar>
<div class="title_box">
    <h3>Robots</h3>
</div>

<div class="table-responsive">
    <table class="table table-bordered">
        <thead>
            <tr>
                <th>S.No.</th>
                <th>Robot ID</th>
                <th>Robot Endpoint</th>
                <th style="text-align: right;">Assign Robot</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let robot of RobotsList;let i = index">
                <td>{{i+1}}</td>
                <td>{{robot.robotId}}</td>
                <td>{{robot.robotEndpoint}}</td>
                <td style="text-align: right;">
                    <select [(ngModel)]="selectedValue[i]" (change)="assignFleet(robot.robotId,selectedValue[i])">
                        <option *ngFor="let fleet of FleetStatusData" [value]="fleet.fleetId">{{fleet.fleetName}}</option>
                    </select>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<div class="nav_container desktop">
    <div class="left_box">
        <h3 class="logo" (click)="navigation('dashboard')">Ottonomy.IO</h3>
        <div class="admin_nav" *ngIf="routerUrl == 'admin' || routerUrl == 'users' || routerUrl == 'robot'">
            <div class="link_box">
                <p (click)="navigation('admin')" [class.active]="routerUrl == 'admin'">Fleets</p>
                <p (click)="navigation('users')" [class.active]="routerUrl == 'users'" *ngIf="roleValue">Users</p>
                <p (click)="navigation('robot')" [class.active]="routerUrl == 'robot'" *ngIf="roleValue">Robots</p>
            </div>
        </div>
        <div class="fleet_nav" *ngIf="routerUrl == 'fleet-detail'">
            <div class="fleet_dropdown">
                <img src="../../assets/images/map.svg" alt="map-icon">
                <h6 *ngIf="fleetList.length == 1">{{fleetList[0].fleetName}}</h6>
                <select *ngIf="fleetList.length > 1" (change)="selectFleet($event)" [(ngModel)]="fleetIdValue">
                    <option *ngFor="let fleet of fleetList" [value]="fleet.fleetId">{{fleet.fleetName}}</option>
                </select>
            </div>  
            <div class="link_box">
                <p (click)="show('robots')" [class.active]="pageValue == 'robots'">Robots</p>
                <p (click)="show('map')" [class.active]="pageValue == 'map'">Map</p>
                <p (click)="show('orders')" [class.active]="pageValue == 'orders'">Orders</p>
                <p (click)="openMedia()">Media Manager</p>
            </div>
        </div>
    </div>
     <div class="right_box">
         <img src="../../assets/images/terminal.svg" alt="Terminal-icon" *ngIf="routerUrl == '/admin'" class="terminal">
         <div class="profile_box">

            <div class="name_dropdown" (click)="drop = !drop" [class.active]="drop">
                <p>{{userName}}</p>
                <img src="../../assets/images/down-arrow.svg" alt="down-arrow">
            </div>
            <ul class="dropdown" *ngIf="drop">
                <li (click)="logout()">Logout</li>
            </ul>
         </div>
     </div>
</div>


<div class="nav_container mobile">
    <div class="left_box">
        <h3 class="logo" (click)="navigation('dashboard')"><img src="../../assets/images/logo-icon.png" /></h3>
        <div class="admin_nav" *ngIf="routerUrl == 'admin' || routerUrl == 'users' || routerUrl == 'robot'">
            <div class="link_box">
                <p (click)="navigation('admin')" [class.active]="routerUrl == 'admin'">Fleets</p>
                <p (click)="navigation('users')" [class.active]="routerUrl == 'users'" *ngIf="roleValue">Users</p>
                <p (click)="navigation('robot')" [class.active]="routerUrl == 'robot'" *ngIf="roleValue">Robots</p>
            </div>
        </div>
        <div class="fleet_nav" *ngIf="routerUrl == 'fleet-detail'">
            <div class="fleet_dropdown">
                <img src="../../assets/images/map.svg" alt="map-icon">
                <h6 *ngIf="fleetList.length == 1">{{fleetList[0].fleetName}}</h6>
                <select *ngIf="fleetList.length > 1" (change)="selectFleet($event)" [(ngModel)]="fleetIdValue">
                    <option *ngFor="let fleet of fleetList" [value]="fleet.fleetId">{{fleet.fleetName}}</option>
                </select>
            </div>  
            <div class="link_box">
                <p (click)="show('robots')" [class.active]="pageValue == 'robots'">Robots</p>
                <p (click)="show('map')" [class.active]="pageValue == 'map'">MAP</p>
                <p (click)="show('orders')" [class.active]="pageValue == 'orders'">orders</p>
                <p (click)="show('media')" [class.active]="pageValue == 'media'">Media Manager</p>
            </div>
        </div>
    </div>
     <div class="right_box">
         <img src="../../assets/images/terminal.svg" alt="Terminal-icon" *ngIf="routerUrl == '/admin'" class="terminal">
         <div class="profile_box">

            <div class="name_dropdown" (click)="drop = !drop" [class.active]="drop">
                <p>{{userName}}</p>
                <img src="../../assets/images/down-arrow.svg" alt="down-arrow">
            </div>
            <ul class="dropdown" *ngIf="drop">
                <li (click)="logout()">Logout</li>
            </ul>
         </div>
     </div>
</div>
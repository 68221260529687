<div class="title_box">
    <h3>Robots</h3>
</div>

<div class="table-responsive">
    <table class="table table-bordered">
        <thead>
            <tr>
                <th>S.No.</th>
                <th>Robot ID</th>
                <th style="text-align: left;">Robot Endpoint</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let robot of RobotsList;let i = index">
                <td>{{i+1}}</td>
                <td>{{robot.robotId}}</td>
                <td>{{robot.robotEndpoint}}</td>
            </tr>
        </tbody>
    </table>
</div>

<!-- Add Robot -->
<!-- <div class="custom_modal_container" [class.show]="modalShow == 'addRobot'">
    <div class="backdrop" (click)="closeModal()"></div>
    <div class="custom_modal_box">
        <h3>Add Robot</h3>
        <form [formGroup]="addRobotForm" (ngSubmit)="AddRobot()">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="ID">Robot ID</label>
                        <input type="tel" name="" id="ID" placeholder="Robot ID" formControlName="id" [ngClass]="{ 'is-invalid' : submitted && f.id.errors }">
                        <div *ngIf="submitted && f.id.errors" class="invalid-feedback">
                            <div *ngIf="f.id.errors.required">Please enter robot id.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="Endpoint">Robot Endpoint</label>
                        <input type="text" name="" id="Endpoint" placeholder="Robot Endpoint" formControlName="endpoint" [ngClass]="{ 'is-invalid' : submitted && f.endpoint.errors }">
                        <div *ngIf="submitted && f.endpoint.errors" class="invalid-feedback">
                            <div *ngIf="f.endpoint.errors.required">Please enter robot endpoint.</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="btn_container">
                <button class="cancel" (click)="closeModal()">Cancel</button>
                <button class="add">Add</button>
            </div>
        </form>
    </div>
</div> -->

<!-- Edit Robot -->
<!-- <div class="custom_modal_container" [class.show]="modalShow == 'editRobot'">
    <div class="backdrop" (click)="closeModal()"></div>
    <div class="custom_modal_box">
        <h3>Edit Robot</h3>
        <form [formGroup]="addRobotForm" (ngSubmit)="EditRobot()">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="ID">Robot ID</label>
                        <input type="tel" name="" id="ID" placeholder="Robot ID" formControlName="id" [ngClass]="{ 'is-invalid' : submitted && f.id.errors }">
                        <div *ngIf="submitted && f.id.errors" class="invalid-feedback">
                            <div *ngIf="f.id.errors.required">Please enter robot id.</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="Endpoint">Robot Endpoint</label>
                        <input type="text" name="" id="Endpoint" placeholder="Robot Endpoint" formControlName="endpoint" [ngClass]="{ 'is-invalid' : submitted && f.endpoint.errors }">
                        <div *ngIf="submitted && f.endpoint.errors" class="invalid-feedback">
                            <div *ngIf="f.endpoint.errors.required">Please enter robot endpoint.</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="btn_container">
                <button class="cancel" (click)="closeModal()">Cancel</button>
                <button class="add">Add</button>
            </div>
        </form>
    </div>
</div> -->

<!-- Delete Robot -->
<!-- <div class="custom_modal_container confirmation_modal" [class.show]="modalShow == 'delete'">
    <div class="backdrop" (click)="closeModal()"></div>
    <div class="custom_modal_box">
        <p>Are you sure?</p>
        <div class="btn_container">
            <button class="cancel" (click)="closeModal()">Cancel</button>
            <button class="add" (click)="deleteRobot()">Delete</button>
        </div>
    </div>
</div> -->
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-fleet-media',
  templateUrl: './admin-fleet-media.component.html',
  styleUrls: ['./admin-fleet-media.component.scss']
})
export class AdminFleetMediaComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  modalShow;
  openModal(value) {
    this.modalShow = value;
  }

  closeModal(){
    this.modalShow = '';
  }
}

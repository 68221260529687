import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { LoginComponent } from './login/login.component';
import { MapComponent } from './map/map.component';
import { GraphComponent } from './graph/graph.component';
import { FleetComponent } from './fleet/fleet.component';
import { OrderDetailComponent } from './order-detail/order-detail.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ChartsModule } from 'ng2-charts';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { HttpClientModule  } from '@angular/common/http';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { RobotDetailComponent } from './robot-detail/robot-detail.component';
import { RobotMapBoxComponent } from './robot-map-box/robot-map-box.component';
import { RobotChartBoxComponent } from './robot-chart-box/robot-chart-box.component';
import { NgxJoystickModule } from 'ngx-joystick';
import { AdminComponent } from './admin/admin.component';
import { AdminFleetUsersComponent } from './admin-fleet-users/admin-fleet-users.component';
import { AdminFleetRobotComponent } from './admin-fleet-robot/admin-fleet-robot.component';
import { AdminFleetOrdersComponent } from './admin-fleet-orders/admin-fleet-orders.component';
import { AdminNavbarComponent } from './admin-navbar/admin-navbar.component';
import { AdminFleetComponent } from './admin-fleet/admin-fleet.component';
import { AdminFleetMediaComponent } from './admin-fleet-media/admin-fleet-media.component';
import { AdminFleetMapComponent } from './admin-fleet-map/admin-fleet-map.component';
// import { AdminFleetMoniteringComponent } from './admin-fleet-monitering/admin-fleet-monitering.component';
//import { AgendaComponent } from './agenda/agenda.component';
import { AlertComponent } from './alert/alert.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { HttpCancelService } from './services/httpcancel.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ManageHttpInterceptor } from './services/managehttp.interceptor';
import { AdminRobotComponent } from './admin-robot/admin-robot.component';
import { HashLocationStrategy, LocationStrategy  } from '@angular/common';
import { NgxGamepadModule } from 'ngx-gamepad';
import { FleetMediaComponent } from './fleet-media/fleet-media.component';
//import { MomentTimezonePickerModule } from 'moment-timezone-picker';
import { RobotMalfunctionBoxComponent } from './robot-malfunction-box/robot-malfunction-box.component';
import { RobotActionBoxComponent } from './robot-action-box/robot-action-box.component';
import {NgxPaginationModule} from 'ngx-pagination';
import { DatepickerModule } from 'ng2-datepicker';

import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import {MatFormFieldModule} from '@angular/material/form-field';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    LoginComponent,
    MapComponent,
    GraphComponent,
    FleetComponent,
    OrderDetailComponent,
    DashboardComponent,
    RobotDetailComponent,
    RobotMapBoxComponent,
    RobotChartBoxComponent,
    AdminComponent,
    AdminFleetUsersComponent,
    AdminFleetRobotComponent,
    AdminFleetOrdersComponent,
    AdminNavbarComponent,
    AdminFleetComponent,
    AdminFleetMediaComponent,
    AdminFleetMapComponent,
    // AdminFleetMoniteringComponent,
    AlertComponent,
    AdminRobotComponent,
    FleetMediaComponent,
    //AgendaComponent,
    RobotMalfunctionBoxComponent,
    RobotActionBoxComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ChartsModule,
    LeafletModule,
    HttpClientModule,
    FormsModule,
    NgxJoystickModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgxGamepadModule,
    NgxPaginationModule,
    DatepickerModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatInputModule
  ],
  providers: [
    HttpCancelService,
    { provide : LocationStrategy , useClass: HashLocationStrategy},
    { provide: HTTP_INTERCEPTORS, useClass: ManageHttpInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

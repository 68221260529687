<div class="fleet_list_container">
    <h4 class="title">Robots ({{RobotStatusData.length}})</h4>
    <div class="list_box">
        <ul *ngIf="showRobotList">
            <li *ngFor="let robotData of RobotStatusData; let i = index;">
                <div class="text_box">
                    <h6 (click)="robotDetail(robotData[0].robotId)" [class.red]="robotData[0]['malfunction']" [class.orange]="obstacleFlag[i]" [class.grey]="testingMode[i]=='true'">{{robotData[0].robotId}}</h6>
                    <p class="active">{{robotData[0].robotStatus}}</p>
                </div>
                <div class="status_box">
                    <svg class="box_status" viewBox="0 0 28 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M25.6559 6.73924L13.8996 13.6565 2.14061 6.7392l9.85829-5.72867c.0001-.00006.0002-.00012.0003-.00019 1.1739-.680269 2.6221-.680292 3.796-.00006l9.8607 5.72896zm0 0l-9.8603-5.72871 9.8603 5.72871zM14.0175 13.7258l-.0002-.0001c.0001.0001.0003.0002.0004.0003l-.0002-.0002z" fill="#575757" stroke="#656873"/>
                        <path [class.active]="robotData[0].box1Status != 'EMPTY' && robotData[0].box1Status != 'NULL'" d="M.500006 9.42351L12.3285 16.3233V29.258L1.85 22.9752l.00002-.0001-.00548-.0032C1.01368 22.488.501895 21.5996.500006 20.6381V9.42351z" fill="#00FF00" stroke="#EB9FAC"/>
                        <path [class.active]="robotData[0].box2Status != 'EMPTY' && robotData[0].box2Status != 'NULL'" d="M25.95 22.9686l-.0001-.0001-.0055.0033-10.4778 6.2829V16.3703l11.8285-6.94283V20.6377c-.0031.9605-.515 1.8476-1.3451 2.3309zm-10.597-6.5317c-.0009.0006-.0019.0011-.0028.0017h0l.0028-.0017z" fill="#00FF00" stroke="#EB9FAC"/>
                    </svg>
                    <div class="battery_box">
                        <svg viewBox="0 0 21 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="1" width="16.9689" height="9.5026" rx="1.9387" stroke="#5DFBA2" stroke-width="1.35" stroke-linecap="square"/>
                            <rect x="3.27771" y="2.33026" [attr.width]="0.12*robotData[0].robotBattery" height="4.84208" rx=".169689" fill="#5DFBA2" stroke="#5DFBA2" stroke-width="1.35751" stroke-linecap="square"/>
                            <path d="M20.034 3.16766v3.16725" stroke="#5DFBA2" stroke-width="1.35751" stroke-linecap="round"/>
                        </svg>
                        <p>{{robotData[0].robotBattery | number}}%</p>
                    </div>
                    <svg class="cellular" viewBox="0 0 30 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path [class.hide]="robotData[0].activeSimRssi < 75" d="M28.5 22H25.5C24.6716 22 24 21.3284 24 20.5V1.5C24 0.671573 24.6716 0 25.5 0H28.5C29.3284 0 30 0.671573 30 1.5V20.5C30 21.3284 29.3284 22 28.5 22Z" fill="white"/>
                        <path [class.hide]="robotData[0].activeSimRssi < 50" d="M20.5 22H17.5C16.6716 22 16 21.3284 16 20.5V6.5C16 5.67157 16.6716 5 17.5 5H20.5C21.3284 5 22 5.67157 22 6.5V20.5C22 21.3284 21.3284 22 20.5 22Z" fill="white"/>
                        <path [class.hide]="robotData[0].activeSimRssi < 25" d="M12.5 22H9.5C8.67157 22 8 21.3284 8 20.5V10.5C8 9.67157 8.67157 9 9.5 9H12.5C13.3284 9 14 9.67157 14 10.5V20.5C14 21.3284 13.3284 22 12.5 22Z" fill="white"/>
                        <path [class.hide]="robotData[0].activeSimRssi == 0" d="M4.5 22H1.5C0.671573 22 0 21.3284 0 20.5V14.5C0 13.6716 0.671573 13 1.5 13H4.5C5.32843 13 6 13.6716 6 14.5V20.5C6 21.3284 5.32843 22 4.5 22Z" fill="white"/>
                    </svg>
                    <div class="play_pause_button">
                        <img src="../../assets/images/play-circle.svg" alt="Play Icon" class="play" [class.active]="robotData[0].robotRunningState == 'UI_PAUSE'" (click)="robotPlay(robotData[0].robotId)">
                        <img src="../../assets/images/pause.svg" alt="Pause Icon" class="pause"  [class.active]="robotData[0].robotRunningState != 'UI_PAUSE'" (click)="robotPause(robotData[0].robotId)">  
                    </div>

                    <!-- <div class="play_pause_button">
                        <img src="../../assets/images/play-circle.svg" alt="Play Icon" class="play" [class.active]="play == robotData[0].robotId" (click)="robotPlay(robotData[0].robotId)">
                        <img src="../../assets/images/pause.svg" alt="Pause Icon" class="pause"  [class.active]="pause == robotData[0].robotId" (click)="robotPause(robotData[0].robotId)">  
                    </div> -->
                </div>
            </li>
        </ul>
    </div>
</div>

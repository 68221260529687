import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root'
})
export class OttonomyService {

  constructor(private http: HttpClient, private alertService: AlertService) { }

  message: string;

  // For sending a message to the alert box at the top
  sendMessage(msg) {
    this.message = msg;
  }

  // For displaying that message at the alert box
  receiveMessage() {
    return this.message;
  }

  public isLoggedIn(): boolean {
    const token = sessionStorage.getItem('token');
    if (token) {
      return true;
    } else {
      return false;
    }
  }


  login(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'login/v1/login', body, options)
        .subscribe((success: any) => {
          console.log('login success :', success)
          this.alertService.success('Login Successfully', options);
          return resolve(success);
        }, error => {
          this.alertService.error('Invalid login credentials. Please try again.', options);
          return reject(error);
        });
    });
  }
  otpVerify(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'verifyOtp', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  }
  forgotPassword(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'login/v1/forgetPassword', body, options)
        .subscribe((success: any) => {
          this.alertService.success(success.message, options);
          return resolve(success);
        }, error => {
          this.alertService.error('Invalid email id.', options);
          return reject(error);
        });
    });
  }

  public token = "Bearer eyJhbGciOiJIUzI1NiJ9.eyJfaWQiOiI2MDNiNzBmNzc3MjA2MTQ1MWNmNzdhMzIiLCJkYXRlT2ZSZWdpc3RlcmF0aW9uIjoiMjAyMS0wMi0yOFQxMDozMToxOS4xMTNaIiwidXNlck5hbWUiOiJ0ZXN0LnVzZXIiLCJlbWFpbElkIjoidGVzdHVzZXJAdGVzdC5jb20iLCJmaXJzdE5hbWUiOiJ0ZXN0IiwibGFzdE5hbWUiOiJ1c2VyIiwibWlkZGxlTmFtZSI6IiIsInBhc3N3b3JkIjoidGVzdEAxMjMiLCJyb2xlIjoidGVzdCIsImltYWdlVXJsIjoidGVzdCIsImNyZWF0ZWRfYXQiOiIyMDIxLTAyLTI4VDEwOjMxOjE5LjExNFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTAyLTI4VDEwOjMxOjE5LjExNFoiLCJfX3YiOjB9.N1fVw8a2_YA5LeomrbrM1GPDRc__qtMkOeHFWiCABmg"

  // Get Fleet
  getFleetListByAdminEmailData(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'fleet/v1/getFleetListByAdminEmail', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  }
  // upload Fleet Media Images
  uploadFleetMediaImages(body) {
    // console.log('--> body', body)
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'fleet/v1/uploadFleetMedia', body, options)
        .subscribe((success: any) => {
          // console.log('=> success :', success);
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  }

  // Add Fleet
  AddFleet(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'fleet/v1/createFleet', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  }

  // Edit Fleet
  editFleet(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'fleet/v1/updateFleet', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  }

  // Delete Fleet
  DeleteFleet(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'fleet/v1/deleteFleet', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  }

  // Get Users List
  getUsersList() {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.get(environment.apiUrl + 'user/v1/getUsersList', options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  }

  // upload Profile Image
  uploadProfileImage(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'user/v1/uploadUserMedia', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  }

  // Add User
  AddUser(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'user/v1/createUser', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          this.alertService.error(error.message, options);
          return reject(error);
        });
    });
  }

  // Edit User
  EditUser(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'user/v1/editUser', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  }

  // Delete User
  DeleteUser(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'user/v1/deleteUser', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  }

  // Get Robots List
  getRobotsList() {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.get(environment.apiUrl + 'getRobotList', options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  }

  // Add Robot
  AddRobot(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'addRobotDetails', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  }

  // Edit User
  EditRobot(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'editRobotDetails', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  }

  // Delete User
  DeleteRobot(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'deleteRobotDetails', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  }

  // Get Maps List
  getMapsList(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'map/v1/getMapDetailByfleetId', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  }

  // Add Map
  AddMap(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'map/v1/addMap', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  }

  // Upload Base Map
  uploadBaseMap(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'map/v1/uploadBaseMap', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  }

  // Upload Floor Map
  uploadfloorMap(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'map/v1/uploadFloorPlanMap', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  }

  // Upload No Go Map
  uploadNoGoMap(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'map/v1/uploadNoGoZoneMap', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  }
  // Upload Annotated Map
  uploadAnnotatedMap(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'map/v1/uploadAnnotationMap', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  }

  // Edit Map
  editMap(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'map/v1/editMap', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  }

  // Delete Map
  deleteMap(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'map/v1/deleteMap', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  }

  // Get Robot list by fleet id
  saveWebIdentifier(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'user/v1/oneSignalAppIdentifier', body, options)
        .subscribe((success: any) => {
          resolve(success);
        }, (error: any) => {
          resolve(error);
        });
    });
  }


  // Get Robot list by fleet id
  getRobotData(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'robot/v1/getRobotStatusByFleetId', body, options)
        .subscribe((success: any) => {
          resolve(success);
        }, (error: any) => {
          resolve(error);
        });
    });
  }

  // Complete action according to the status
  completeAction(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'order/v1/completeAction', body, options)
        .subscribe((success: any) => {
          resolve(success);
        }, (error: any) => {
          resolve(error);
        });
    });
  }


  getOrderData(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      })

      this.http.post(environment.apiUrl + 'order/v1/getOrderListByFleetId', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  }


  //Fleet List
  getFleetListEmail(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      })
      this.http.post(environment.apiUrl + 'fleet/v1/getFleetListByUserEmail', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, (error: any) => {
          return reject(error)
        });
    });
  }

  //Fleet List By Admin Email
  getFleetListAdminEmail(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      })
      this.http.post(environment.apiUrl + 'fleet/v1/getFleetListByAdminEmail', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, (error: any) => {
          return reject(error)
        });
    });
  }

  //Robot Detail By Robot ID
  getRobotDataByRobotId(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      })

      this.http.post(environment.apiUrl + 'robot/v1/getRobotStatus', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, (error: any) => {
          return reject(error)
        });
    });
  }

  // get robot actions by robot id
  getCommandListRobot(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      })

      this.http.post(environment.apiUrl + 'robot/v1/getRobotCommandListByRobotId', body, options)
        .subscribe((success: any) => {
          //console.log('output', success);
          return resolve(success);
        }, (error: any) => {
          return reject(error)
        });
    });
  }

  //Send Home
  sendHome(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      })
      this.http.post(environment.apiUrl + 'robot/v1/sendRobotHome', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, (error: any) => {
          return resolve(error)
        });
    })
  }

  //To check for availability of fleet
  // Api checks for every robot that if they are available or not and accordingly 
  // updates their status as well as fleet status
  checkFleetAvailability(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      })

      this.http.post(environment.apiUrl + 'fleet/v1/checkFleetAvailability', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, (error: any) => {
          return reject(error)
        });
    });
  }

  // To get the no of available robots in the last seven days
  getFleetAvailabilityOverTime(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      })

      this.http.post(environment.apiUrl + 'robot/v1/getFleetAvailabilityOverTime', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, (error: any) => {
          return reject(error)
        });
    });
  }

  // Play Robot
  playRobot(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      })
      this.http.post(environment.apiUrl + 'interrupt/v1/sendRunCommand', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, (error: any) => {
          return resolve(error)
        });
    })
  }

  //Set SSH Session 
  setSshSessionByRobotId(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      })
      this.http.post(environment.apiUrl + 'interrupt/v1/setSshSessionbyRobotId', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, (error: any) => {
          return resolve(error)
        });
    })
  }

  // Play Robot
  pauseRobot(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      })
      this.http.post(environment.apiUrl + 'interrupt/v1/sendPauseCommand', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, (error: any) => {
          return resolve(error)
        });
    })
  }


  // Stop Fleet
  stopFleetStatus(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      })
      this.http.post(environment.apiUrl + 'interrupt/v1/emergencyStopByFleetId', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, (error: any) => {
          return resolve(error)
        });
    })
  }

  // Release Fleet
  releaseFleetStatus(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      })
      this.http.post(environment.apiUrl + 'interrupt/v1/releaseEmergencyStopByFleetId', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, (error: any) => {
          return resolve(error)
        });
    })
  }

  // Get Robots List
  getRobotsListByFleetId(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'robot/v1/robotListByFleetId', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  }

  // Get Robots List By Admin Email
  getRobotsListByAdminEmail(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'robot/v1/robotListByAdmin', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  }

  // Assign Robot To Fleet
  assignRobotToFleet(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'robot/v1/assignRobotToFleet', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  }

  // Assign Robot To a Home Location
  assignRobotToHome(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'location/v1/assignRobotToHomeLocation', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  }

  // Remove Assigned robot from a home location
  removeRobotFromHome(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'location/v1/removeRobotFromHomeLocation', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  }

  /* Get Location List */
  getLocationList(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'location/v1/locationListByFleetId', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  }

  /* Set Location Status */
  setLocationStatus(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'location/v1/setLocationStatus', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  }

  /* Set Wander Status for drop Location */
  setWanderStatus(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'location/v1/setWanderStatus', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  }

  // Get Location Status
  getLocationStatus(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'location/v1/getLocationStatus', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  }
  //upload Location Media
  uploadLocationLogo(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'location/v1/uploadLocationMedia', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  };

  // add location details
  addLocation(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'location/v1/addLocation', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  }

  // Edit Location
  editLocation(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'location/v1/editLocation', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  }

  // Delete Location
  deleteLocation(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'location/v1/deleteLocation', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  }


  // Open Door By Robot Id
  openDoorByRobotId(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'interrupt/v1/openDoorByRobotId', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  }

  //Open door by Order Id
  //Properties:
  //fleetId:string;
  //orderId:string;
  openDoorByOrderId(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'interrupt/v1/openDoorByOrderId', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  }

  // Close Door By Robot Id
  closeDoorByRobotId(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'interrupt/v1/closeDoorByRobotId', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  }

  // Send command to reset cabin
  resetCabinError(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'interrupt/v1/resetCabinError', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  }

  // Orders List By Fleet Id
  getOrdersListByFleetId(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'order/v1/getOrderListByFleetId', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  }

  // Orders List By Fleet Id with Pagination
  getOrdersListByFleetIdPagination(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'order/v1/orderListByFleetIdPagination', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  }

  // Add Order
  AddOrder(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'order/v1/placeOrder', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  }
  // Cancel Order
  cancelOrder(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'order/v1/cancelOrder', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  }

  // Total Orders Completed
  totalOrders(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'order/v1/totalOrdersCompleted', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  }

  // Robot Mode
  robotMode(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'interrupt/v1/setRobotModeById', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  }

  // Robot Mode
  clearRobotMalfunction(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'robot/v1/clearRobotMalfunction', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  }

  credentialDetailsByRobotId(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'robot/v1/getRobotDetailById', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  }

  // Start Video Stream
  startVideoStream(body) {
    return new Promise((resolve, reject) => {
      // defining headers
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'interrupt/v1/startVideoStream', body, options)
        .subscribe((success: any) => {
          // console.log('success :', success);
          return resolve(success);
        }, error => {
          // console.log('error :', error);
          return reject(error);
        });
    });
  }

  // Stop Video Stream
  stopVideoStream(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'interrupt/v1/stopVideoStream', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  }

  // Orders Completed Today
  ordersCompletedToday(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'order/v1/ordersCompletedToday', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  }

  // Orders Completed Today
  currentOrderByRobotId(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'order/v1/getCurrentOrderByRobotId', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  }

  // Distance Covered Today
  distanceCoveredToday(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'robot/v1/totalRobotDistanceTravelled', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  }

  // Total Distance Covered
  totalDistanceTravelled(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'robot/v1/totalDistanceTravelled', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  }

  // Upload Video 
  uploadVideo(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'video/v1/uploadVideo', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  }



  /* Get Video List By FleetId */
  videoListByfleetIdId(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'video/v1/videoListByFleetId', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  }

  /* Get Video List By AdminId */
  videoListByAdminId(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'video/v1/videoListByAdminId', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  }

  /* getVideoListByRobotId */
  videoURLByVideoName(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'video/v1/videoURLByVideoName', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  }

  /* Delete Video Media */
  DeleteMedia(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'video/v1/removeVideo', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  }
  /* Robot List By Admin Email */
  robotListByFleetId(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'robot/v1/robotListByFleetId', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  }
  /* Assign Video To Robot */
  assignVideoToRobot(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'video/v1/assignVideoToRobot', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  }

  /* Edit Video */
  EditVideo(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'user/v1/editUser', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  }
  /* orderCountPerHourData */
  orderCountPerHourData(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      })

      this.http.post(environment.apiUrl + 'order/v1/getOrderCountPerHourDate', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  }

  changeTestingStatus(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'robot/v1/robotTestingMode', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  }

  startWanderMode(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'robot/v1/sendRobotWander', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  }

  cancelWanderCommand(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'robot/v1/cancelWander', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  }

  changeWanderStatus(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'robot/v1/changeRobotWanderStatus', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  }


  setOrderingStatus(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'fleet/v1/setOrderingStatus', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  }

  //Robot Availablity by Day
  getRobotAvail(body) {
    return new Promise((resolve, reject) => {
      let header = new HttpHeaders();
      header = header.set('Authorization', this.token);
      const options = ({
        headers: header
      });
      this.http.post(environment.apiUrl + 'robot/v1/getFleetAvailabilityOverTime', body, options)
        .subscribe((success: any) => {
          return resolve(success);
        }, error => {
          return reject(error);
        });
    });
  }




}

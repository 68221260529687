import { Component, OnInit,Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OttonomyService } from '../services/ottonomy.service';
@Component({
  selector: 'app-admin-navbar',
  templateUrl: './admin-navbar.component.html',
  styleUrls: ['./admin-navbar.component.scss']
})
export class AdminNavbarComponent implements OnInit {
  @Output() passFleetId = new EventEmitter<any>();
  @Output() passPageValue = new EventEmitter<any>();
  userRole: any;
  userDetail: any;
  constructor(private router: Router,private route: ActivatedRoute, private ottonomyservice:OttonomyService) { 
    this.routerUrl = router.url;
  }
  
  routerUrl: string;
  drop = false;
  userName = "";
  roleValue = false;
  fleetList:any = [];
  fleetIdValue:any = '';
  profileImage: any;
  routestring;
  parts;
  ngOnInit(): void {
    this.userDetail = JSON.parse(sessionStorage.getItem('user'));
    this.userRole = this.userDetail.role[0];
    this.userName  = this.userDetail['display_name'];
    this.profileImage  = this.userDetail['imageUrl'];
    if(this.userRole == "user"){
      this.roleValue = false;
    }
    if(this.userRole == "admin"){
      this.roleValue = true;
    }
    this.getFleetList();
    this.routestring = this.router.url.substring(this.router.url.indexOf('/'));
    this.parts  =this.routestring.split('/');
    this.routerUrl = this.parts[1];
    this.fleetIdValue = this.route.snapshot.params.id;
    this.passFleetId.emit(this.fleetIdValue);
  }

  
  // Fleet List
  getFleetList(){
    let body = {
      "emailId" : this.userDetail.emailId
    }
    if(this.userRole == "user"){
      this.ottonomyservice.getFleetListEmail(body).then(s => {
        this.fleetList = s['fleet'];
      })
    }
    if(this.userRole == "admin"){
      this.ottonomyservice.getFleetListAdminEmail(body).then(s => {
        this.fleetList = s['fleet'];
      })
    }
    
  }

  selectFleet(event){
    this.fleetIdValue = event.target.value;
    this.passFleetId.emit(this.fleetIdValue);
    this.router.navigate(['/fleet-detail',event.target.value]);
  }

  pageShow = 'users';
  navigation(value){
    this.router.navigateByUrl('/'+value);
  }
  logout(){
    sessionStorage.clear();
    this.router.navigateByUrl('/login');
  }

  pageValue = 'robots';
  show(value){
    this.pageValue = value;
    this.passPageValue.emit(this.pageValue);
  }

  openMedia(){
    location.replace("https://mediamanagement.ottonomy.io/");
  }
}
